import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { AuthStateProvider } from './contexts/AuthContext';
import { FactsStateProvider } from './contexts/FactsContext';
import { ServerErrorStateProvider } from './contexts/ServerErrorContext';
import { SupplierRequestStateProvider } from './contexts/SupplierRequestContext';
import registerServiceWorker from './registerServiceWorker';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <AuthStateProvider>
      <ServerErrorStateProvider>
        <SupplierRequestStateProvider>
          <FactsStateProvider>
            <App />
          </FactsStateProvider>
        </SupplierRequestStateProvider>
      </ServerErrorStateProvider>
    </AuthStateProvider>
  </BrowserRouter>,
  rootElement);

registerServiceWorker();

