import { Tab } from "bootstrap";
import React from "react";
import { Col, Label, Row, Table } from "reactstrap";
import { useFactsState } from "../../contexts/FactsContext";
import { InboundDeliveryEnum, InboundDeliveryEnumLabel, QuotationApplicableRegionEnumLabel } from "../../helpers/enums";
import { displayMoney, displayYesNo } from "../../helpers/formatting";
import BatteryRequirementsList from "./BatteryRequirementsList";
import BatteryRequirementView from "./BatteryRequirementView";
import CartonList from "./CartonList";
import CartonView from "./CartonView";
import "./product.css";
import ProductImageList from "./ProductImageList";
import ProductMandatoryStandardList from "./ProductMandatoryStandardList";
import ProductTestDateView from "./ProductTestDateView";
import ProductVideoList from "./ProductVideoList";

export default function ProductView({product, isInternational}) {
    let [factsState, dispatchFacts] = useFactsState();

    function getCountry(countryId) {
        var country = factsState.countries.find(x => x.countryId == countryId);
        return country ? country.name.toUpperCase() : undefined;
    }

    return (
        <div>
        {product && 
        <div className="productContainer">
            <div className="formGroup">
                <div className="groupTitle">Product Properties</div>
                <div>
                    <Label>Item Description</Label>
                    <span>{product.productProperties.itemDescription}</span>
                </div>
                <div>
                    <Label>Hamper Features</Label>
                    <span>{product.productProperties.hamperFeatures}</span>
                </div>
                <div>
                    <Label>Brand</Label>
                    <span>{product.productProperties.brand}</span>
                </div>
                <div>
                    <Label>Warranty Period (Days)</Label>
                    <span>{product.productProperties.warrantyPeriod}</span>
                </div>
                <div>
                    <Label>Age Grade</Label>
                    <span>{product.productProperties.ageGrade}</span>
                </div>
                <div>
                    <Label>Item Contains Wood</Label>
                    <span>{product.productProperties ? displayYesNo(product.productProperties.itemContainsWood) : ''}</span>
                </div>
                <div>
                    <Label>Is Weight Rated?</Label>
                    <span>{product.productProperties ? displayYesNo(product.productProperties.isWeightRated) : ''}</span>
                </div>
                <div>
                    <Label>Weight Limit</Label>
                    <span>{product.productProperties ? product.productProperties.weightLimit : ''}</span>
                </div>
                <div>
                    <Label>Is Tipping Hazard?</Label>
                    <span>{product.productProperties ? displayYesNo(product.productProperties.isTippingHazard) : ''}</span>
                </div>
            <div>
            </div>
            <div>
                <Label>Unit Cost</Label>
                <span>${product.currencyCode} {displayMoney(product.forecastOverride)}</span>
            </div>
            <div>
                <Label>RRP</Label>
                <span>${product.supplierRRP}</span>
            </div>
            <div>
                <Label>Quotation Applicable Region</Label>
                <span>{product.quotationApplicableRegion && product.quotationApplicableRegion > 0 ? QuotationApplicableRegionEnumLabel(product.quotationApplicableRegion) : ''}</span>
            </div>
            {isInternational &&
            <>
                <div>
                    <Label>Delivery Term</Label>
                    <span>{product.inboundDeliveryId && product.inboundDeliveryId > 0 ? InboundDeliveryEnumLabel(product.inboundDeliveryId) : ''}</span>
                </div>
                <div>
                    <Label>Delivery Port</Label>
                    <span>{product.deliveryProperties ? product.deliveryProperties.deliveryPort : ''}</span>
                </div>
            </>
            }
            <div>
                <Label>Production &amp; Delivery Lead Time (days)</Label>
                <span>{product.deliveryProperties ? product.deliveryProperties.leadTime : ''}</span>
            </div>
            <div>
                <Label>Item Country of Origin </Label>
                <span>{product.country && product.country > 0 ? getCountry(product.country) : ''}</span>
            </div>
            <div>
                <Label>Minimum Order Quantity</Label>
                <span>{product.orderingLimits ? product.orderingLimits.minimumOrderQuantity : ''}</span>
            </div>
            <div>
                <Label>Maximum Available Quantity</Label>
                <span>{product.orderingLimits ? product.orderingLimits.maximumQuantity : ''}</span>
            </div>
           
                <Label>Mandatory Standards</Label>
                {product.supplierProductMandatoryStandards && product.supplierProductMandatoryStandards.length > 0 ?<ProductMandatoryStandardList supplierProductMandatoryStandards={product.supplierProductMandatoryStandards} editing={false} /> : <span>No Mandatory Standards</span>}
            </div>
            <div className="formGroup">
                <div className="groupTitle">Media</div>
                <div>
                    <Label>Product Sample Images</Label>
                    {product.supplierProductImages && product.supplierProductImages.length > 0 ? <ProductImageList supplierProductImages={product.supplierProductImages} supplierId={product.supplierId} editing={false} /> : <span>No Sample Images</span>}
                </div>
                <div>
                    <Label>Product Sample Videos</Label>
                    {product.supplierProductVideos && product.supplierProductVideos.length > 0 ? <ProductVideoList supplierProductVideos={product.supplierProductVideos} supplierId={product.supplierId} editing={false} /> : <span>No Sample Videos</span> }
                </div>
            </div>
            <div className="formGroup">
                <div className="groupTitle">Battery Details</div>
                <div>
                    <Label>Batteries Required?</Label>
                    <span>{product.productProperties ? displayYesNo(product.productProperties.areBatteriesRequired) : ''}</span>
                </div>
                {product.productProperties.areBatteriesRequired &&
                <>
                <div>
                    <Label>Has Button Batteries?</Label>
                    <span>{product.productProperties ? displayYesNo(product.productProperties.hasButtonBatteries) : ''}</span>
                </div>
                <div>
                    <Label>Battery Requirements</Label>
                    {(product.batteryRequirements && product.batteryRequirements.length > 0) ?
                    <Table striped borderless className="table-secondary">
                    <thead>
                        <tr>
                            <th>Type</th>
                            <th>Quantity</th>
                            <th>Included?</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {product.batteryRequirements.map((batteryRequirement, index) => {
                            return <BatteryRequirementView batteryRequirement={batteryRequirement} key={index} />
                        })}
                    </tbody>
                    </Table>
                    : <span>No Battery Requirements Set</span>
                    }
                </div>
                </>
                }
            </div>
            <div className="formGroup">
                <div className="groupTitle">Product Test Dates</div>
                {
                    (product.supplierProductTestDates && product.supplierProductTestDates.length > 0) ?
                        <Table striped borderless className="table-secondary">
                        <thead>
                            <tr>
                                <th>Product Test Type</th>
                                <th>Test Date</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                        {product.supplierProductTestDates.map((supplierProudctTestDate, index) => {
                            return <ProductTestDateView productTestDate={supplierProudctTestDate} key={index} />
                        })}
                        </tbody>
                        </Table> :
                        <span>No Product Test Dates</span>
                }
            </div>
            <div className="formGroup">
                <div className="groupTitle">Cartons</div>
                {(product.cartons && product.cartons.length > 0) ? 
                    product.cartons.map((carton, index) => {
                        return <CartonView carton={carton} key={index} cartonIndex={++index} cartonTotal={product.cartons.length} />
                    }) : 
                    <span>No Cartons</span>
                }
            </div>
            {product.productProperties.notes &&
            <div className="formGroup">
                <div className="groupTitle">Notes</div>
                <div>
                    <pre>{product.productProperties.notes}</pre>
                </div>
            </div>}
        </div>
        }
        </div>
    )
}