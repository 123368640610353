import { isUndefined, isString} from "lodash";
import { SizeMeasurementUnitEnum, WeightMeasurementUnitEnum } from "./enums";

export function isEmpty(value) {
    return isUndefined(value) || value === null || (isString(value) && value.trim() === '') || value === false || (Array.isArray(value) && value.length === 0 );
}

export function emailIsValid (email) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
  }

export function stripCommasAndQuotes(value) {

    if (!value)
        return null;

    var minusCommas = value.replace(/,/g,'');

    var minusSingleQuotes = minusCommas.replace(/'/g,'');

    var minusDoubleQuotes = minusSingleQuotes.replace(/"/g,'');

    return minusDoubleQuotes;
}

export function limitToXDecimalPlaces(value, numDecPlaces) {
    if (value) {
        if (value.toString().match(/\./g)) {
            const [, decimal] = value.toString().split('.');
            if (decimal?.length > numDecPlaces)
                return Number(value).toFixed(numDecPlaces);
            else
                return value;
        }
        else {
            return value;
        }
    }
    else {
        return value;
    }
}

export function isImperialWeightMeasurement(value) {
    if (value == WeightMeasurementUnitEnum.LB)
        return true;
    
    return false;
}

export function isImperialSizeMeasurement(value) {
    if (value == SizeMeasurementUnitEnum.Inch || value == SizeMeasurementUnitEnum.Meter) //while metre is metric, removing so they have to use mm/cm
        return true;
    
    return false;
}