import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { Button, Col, Row, Table } from "reactstrap";
import ProductTestDateEdit from "./ProductTestDateEdit";
import ProductTestDateView from "./ProductTestDateView";
import "./product.css";

export default function ProductTestDateList({productTestDates, updatedProductTestDates, editing}) {
    let [isEditing, setIsEditing] = useState(false);
    let [allProductTestDates, setAllProductTestDates] = useState();
    let [productTestBeingEdited, setProductTestBeingEdited] = useState();
    
    useEffect(() => {
        setAllProductTestDates(productTestDates);
        setIsEditing(false);
    }, [productTestDates])

    function addProductTestDate() {
        setIsEditing(true);
        setProductTestBeingEdited(null);
    }

    function cancelEdit() {
        setIsEditing(false);
        setProductTestBeingEdited(null);
    }

    function productTestDateAddedOrUpdated(productTestDate) {
        var index = allProductTestDates.findIndex(x => x.supplierProductTestDateId === productTestDate.supplierProductTestDateId);
        if (index > -1) {
            allProductTestDates[index] = productTestDate;
            updatedProductTestDates(allProductTestDates);
        } else {
            var dates = allProductTestDates.concat(productTestDate);
            setAllProductTestDates(dates);
            updatedProductTestDates(dates);
        }

        setProductTestBeingEdited(null);
        setIsEditing(false);
    }

    function productTestDateEdit(productTestDateId) {
        var productTest = allProductTestDates.find(x => x.supplierProductTestDateId === productTestDateId);

        if (productTest) {
            setProductTestBeingEdited(productTest);
            setIsEditing(true);
        }
    }

    function productTestDateRemove(productTestDateId) {
        var index = allProductTestDates.findIndex(x => x.supplierProductTestDateId === productTestDateId);
        if (index > -1) {
            allProductTestDates.splice(index, 1);
            setAllProductTestDates(allProductTestDates);
            updatedProductTestDates(allProductTestDates);
        }
    }

    return (
        isEditing ?
            <ProductTestDateEdit productTestDate={productTestBeingEdited} cancelUpdate={cancelEdit} productTestDateUpdated={productTestDateAddedOrUpdated} /> :

        <div>
            <div className="sectionHeader">Product Test Dates</div>
            {editing && <div><Button color="primary" size="sm" onClick={addProductTestDate}><FaPlus />Add Product Test Date</Button></div>}
            <div className="productTestDateContainer">
                {allProductTestDates && allProductTestDates.length > 0 && <Table striped borderless>
                        <thead>
                            <tr>
                                <th>Product Test Type</th>
                                <th>Test Date</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                {allProductTestDates && allProductTestDates.map((productTestDate, index) => {
                    return <ProductTestDateView key={index} productTestDate={productTestDate} editing={editing} productTestEdit={productTestDateEdit} productTestRemove={productTestDateRemove} />
                })}
                </tbody>
                </Table>
                }
            </div>
        </div>
    )
}