import React, { useEffect, useState } from "react"
import { FaPlus } from "react-icons/fa";
import { Button, Col, Row, Table } from "reactstrap";
import BatteryRequirementEdit from "./BatteryRequirementEdit"
import BatteryRequirementView from "./BatteryRequirementView";
import "./product.css";

export default function BatteryRequirementsList({batteryRequirementsList, updatedBatteryRequirements, editing}) {
    let [isEditing, setIsEditing] = useState(false);
    let [allBatteries, setAllBatteries] = useState();
    let [batteryRequirementBeingEdited, setBatteryRequirementBeingEdited] = useState();

    useEffect(() => {
        setAllBatteries(batteryRequirementsList);
    }, [batteryRequirementsList])

    function addBatteryRequirement() {
        setIsEditing(true);
        setBatteryRequirementBeingEdited(null);
    }

    function batteryRequirementEdit(batteryRequirementId) {
        var batteryRequirement = allBatteries.find(x => x.batteryRequirementId === batteryRequirementId);

        if (batteryRequirement) {
            setBatteryRequirementBeingEdited(batteryRequirement);
            setIsEditing(true);
        }
    }

    function batteryRequirementRemove(batteryRequirementId) {
        var index = allBatteries.findIndex(x => x.batteryRequirementId === batteryRequirementId);
        if (index > -1) {
            allBatteries.splice(index, 1);
            setAllBatteries(allBatteries);
            updatedBatteryRequirements(allBatteries);
        }
    }

    function batteryRequirementAddedOrUpdated(batteryRequirement) {
        var index = allBatteries.findIndex(x => x.batteryRequirementId === batteryRequirement.batteryRequirementId);
        if (index > -1) {
            allBatteries[index] = batteryRequirement;
            updatedBatteryRequirements(allBatteries);
        } else {
            var reqs = allBatteries.concat(batteryRequirement);
            setAllBatteries(reqs);
            updatedBatteryRequirements(reqs);
        }

        setBatteryRequirementBeingEdited(null);
        setIsEditing(false);
    }

    function cancelEdit() {
        setIsEditing(false);
        setBatteryRequirementBeingEdited(null);
    }

    return (
        isEditing ? <BatteryRequirementEdit batteryRequirement={batteryRequirementBeingEdited} batteryRequirementUpdated={batteryRequirementAddedOrUpdated} cancelUpdate={cancelEdit} /> :
        <div>
            Battery Requirements
            {editing && <div><Button color="primary" size="sm" onClick={addBatteryRequirement}><FaPlus />Add Battery Requirement</Button></div>}
            <div className="batteryRequirementContainer">
                {allBatteries && allBatteries.length > 0 && <Table striped borderless>
                    <thead>
                        <tr>
                            <th>Type</th>
                            <th>Quantity</th>
                            <th>Included?</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                    {allBatteries && allBatteries.map((batteryRequirement, index) => {
                        return <BatteryRequirementView key={index} batteryRequirement={batteryRequirement} editing={editing} batteryRequirementEdit={batteryRequirementEdit} batteryRequirementRemove={batteryRequirementRemove} />
                })}
                </tbody>
                </Table>}
            </div>
        </div>
    )
}