import { post } from "jquery";
import React, { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { BsDot } from "react-icons/bs";
import { MdEdit } from "react-icons/md";
import { Alert, Button, Col, Container, Form, FormFeedback, FormGroup, FormText, Input, InputGroup, InputGroupAddon, Label } from "reactstrap";
import { BASEAPI } from "../../../constants";
import { useAuthState } from "../../../contexts/AuthContext";
import { useServerErrorState } from "../../../contexts/ServerErrorContext";
import { ContactTypeEnum } from "../../../helpers/enums";
import { GetJsonWithToken } from "../../../helpers/fetch_helpers";
import { isEmpty } from "../../../helpers/utils";
import { isDomesticFinanceValid } from "../../../helpers/validators";
import AddressEdit, { AddressTypeEnum } from "../../Address/AddressEdit";
import AddressView from "../../Address/AddressView";
import ContactList from "../../Contact/ContactList";
import "./finance.css";

export default function DomesticFinanceDetails({financeModel, financeUpdated})
{
    let [authState, dispatchAuth] = useAuthState();
    let [serverErrorState, dispatchServerError] = useServerErrorState();

    let [supplierFinanceId, setSupplierFinanceId] = useState(financeModel ? financeModel.financeDetails.supplierFinanceId : null);
    let [supplierId, setSupplierId] = useState(financeModel ? financeModel.financeDetails.supplierId : null);

    const { register, handleSubmit, isDirty, isSubmitting, touchedFields, submitCount, watch, control, formState: { errors }} = useForm({
        mode: "all",
        defaultValues: {
            businessName: financeModel ? financeModel.financeDetails.businessDetails.legalBusinessName : '',
            tradingName: financeModel ? financeModel.financeDetails.businessDetails.tradingName : '',
            abn: financeModel ? financeModel.financeDetails.businessDetails.abn : '',
            phone1: financeModel ? financeModel.financeDetails.contactDetails.phone1 : '',
            phone1AreaCode: financeModel ? financeModel.financeDetails.contactDetails.phone1AreaCode : '',
            phone2: financeModel ? financeModel.financeDetails.contactDetails.phone2 : '',
            phone2AreaCode: financeModel ? financeModel.financeDetails.contactDetails.phone2AreaCode : '',
            fax: financeModel ? financeModel.financeDetails.contactDetails.fax : '',
            faxAreaCode: financeModel ? financeModel.financeDetails.contactDetails.faxAreaCode : '',
            bankAccountName:financeModel ? financeModel.financeDetails.bankDetails.bankAccountName : '',
            bankName: financeModel ? financeModel.financeDetails.bankDetails.bankName : '',
            bsbNumber: financeModel ? financeModel.financeDetails.bankDetails.bankBSBNumber : '',
            bankAccountNumber: financeModel ? financeModel.financeDetails.bankDetails.bankAccountNumber : '',
            earlyPaymentDiscount: financeModel ? financeModel.financeDetails.accountDetails.earlyPaymentDiscount : null,
            discountRate: financeModel ? financeModel.financeDetails.accountDetails.discountRate : '',
            paymentTermWithoutDiscount: financeModel ? financeModel.financeDetails.accountDetails.paymentTermWithoutDiscount : '',
            isPrepaymentRequired: financeModel ? financeModel.financeDetails.accountDetails.isPrepaymentRequired : null,
            paymentLeadTimePriorToDelivery: financeModel ? financeModel.financeDetails.accountDetails.paymentLeadTimePriorToDelivery :  '',
            creditLimit: financeModel ? financeModel.financeDetails.accountDetails.creditLimit : ''
        }
    });
    const earlyPaymentDiscountWatch = watch("earlyPaymentDiscount")
    const isPrepaymentRequiredWatch = watch("isPrepaymentRequired")

    let [businessAddress, setBusinessAddress] = useState(financeModel ? financeModel.businessAddress : null);
    let [postalAddress, setPostalAddress] = useState(financeModel ? financeModel.postalAddress : null);
    let [contacts, setContacts] = useState(financeModel ? financeModel.contacts : null);

    let [editAddress, setEditAddress] = useState(false);
    let [editPostalAddress, setEditPostalAddress] = useState(false);
    let [contactBeingEdited, setContactBeingEdited] = useState(false);
    let [supplier, setSupplier] = useState();

    useEffect(() => {
        if (supplierId) {
            getSupplier();
        }

    }, [supplierId])

    async function getSupplier() {
        var uri = BASEAPI + "/api/supplier/" + supplierId;
    
        var result = await GetJsonWithToken(uri, null, authState.token)
        .catch(function(error) {
            console.log(error);
            dispatchServerError({type: "SET_ERROR", errorTitle: "Error getting supplier", errorMessage: error.message});
        })
        
        setSupplier(result);
      }

    function updateAddress(address) {
        setBusinessAddress(address);
        setEditAddress(false);
    }

    function cancelEditAddress() {
        setEditAddress(false);
    }

    function updatePostalAddress(address) {
        setPostalAddress(address);
        setEditPostalAddress(false);
    }

    function cancelEditPostalAddress() {
        setEditPostalAddress(false);
    }

    function onContactBeingEdited() {
        setContactBeingEdited(true);
    }

    function onContactEditingComplete() {
        setContactBeingEdited(false);
    }

    function contactsUpdated(contacts) {
        setContacts(contacts);
    }

    function buildFinanceModel(formData) {
        var financeModel = {
            businessAddress: businessAddress,
            postalAddress: postalAddress,
            contacts: contacts,            
            financeDetails: {
                supplierFinanceId: supplierFinanceId,
                supplierId: supplierId,
                isInternational: false,
                businessDetails: {
                    legalBusinessName: formData.businessName,
                    tradingName: formData.tradingName,
                    abn: formData.abn
                },
                contactDetails: {
                    phone1: formData.phone1,
                    phone1AreaCode: formData.phone1AreaCode,
                    phone2: formData.phone2,
                    phone2AreaCode: formData.phone2AreaCode,
                    fax: formData.fax,
                    faxAreaCode: formData.faxAreaCode
                }, 
                bankDetails: {
                    bankAccountName: formData.bankAccountName,
                    bankName: formData.bankName,
                    bankBSBNumber: formData.bsbNumber,
                    bankAccountNumber: formData.bankAccountNumber
                },
                accountDetails: {
                    earlyPaymentDiscount: formData.earlyPaymentDiscount === "true",
                    discountRate: formData.discountRate,
                    paymentTermWithoutDiscount: formData.paymentTermWithoutDiscount,
                    isPrepaymentRequired: formData.isPrepaymentRequired === "true",
                    paymentLeadTimePriorToDelivery: formData.paymentLeadTimePriorToDelivery,
                    creditLimit: formData.creditLimit
                }
            }
        }

        return financeModel;
    }

    function submitFinance(formData) {
        //console.log(formData)
        var financeModel = buildFinanceModel(formData);
        financeUpdated(financeModel);
    }

    return (
        <div className="bodyWithFooter">
            <h2>{supplier ? supplier.supplierName : 'Supplier'} - New Supplier Information (Domestic)</h2>

                <Form>
            <div className="formGroup financeForm">
                <div className="groupTitle">Business Details</div>
                <Alert color="light"><small>Required Fields <BsDot color="red" /></small></Alert>

                <Controller id="businessName" name="businessName" 
                        control={control}
                        rules={{required: true}}
                        render={({field: {ref, ...fieldProps}}) => (
                            <FormGroup row>
                                <Label column="true" sm="4" for="businessName">Legal Business Name<BsDot color="red" /></Label>
                                <Col sm={10}>
                                    <Input 
                                        id="businessName"
                                        type="text"
                                        innerRef={ref} 
                                        {...fieldProps } 
                                        className={`textInput form-control ${errors.businessName ? 'is-invalid' : ''}`}/>
                                    {errors.businessName && <div class="invalid-feedback">{errors.businessName.type === 'required' && 'Please enter a Business Name'}</div>}
                                </Col>
                            </FormGroup>
                        )}
                />
                <Controller id="tradingName" name="tradingName"
                    control={control}
                    render={({field: {ref, ...fieldProps}}) => (
                        <FormGroup inline row>
                            <Label column="true" sm="4" for="tradingName">Trading Name</Label>
                            <Col sm={10}>
                                <Input 
                                id="tradingName" 
                                type="text"
                                innerRef={ref} 
                                {...fieldProps } 
                                className="textInput" />
                            </Col>
                        </FormGroup>
                )} />
                <Controller id="abn" name="abn"
                    control={control}
                    rules={{required: true, minLength: 10}}
                    render={({field: {ref, ...fieldProps}}) => (
                <FormGroup inline row>
                    <Label column sm="4" for="abn">Australian Business Number (ABN)<BsDot color="red" /></Label>
                    <Col sm={10}>
                        <Input 
                            id="abn" 
                            maxLength="18"
                            type="text"
                            innerRef={ref} 
                            {...fieldProps } 
                            className={`textInput form-control ${errors.abn ? 'is-invalid' : ''}`}/>
                        {errors.abn && <div class="invalid-feedback">
                            {errors.abn.type === 'required' && 'Please enter an ABN'}
                            {errors.abn.type === 'minLength' && 'Please enter an ABN of atleast 10 characters'}
                            </div>}
                    </Col>
                </FormGroup>
                )} />
            </div>
            <div className="formGroup">
                    <div className="groupTitle">Contact Details</div>
                    
                <FormGroup inline row>
                        <Label column sm="2" for="phone1">Phone 1<BsDot color="red" /></Label>
                        <Col sm="10">
                            <InputGroup className="inputGrp">
                                <InputGroupAddon addonType="prepend">
                                <Controller id="phone1AreaCode" name="phone1AreaCode"
                                    control={control}
                                    render={({field: {ref, ...fieldProps}}) => (
                                        <>
                                        (<Input type="text" 
                                            id="phone1AreaCode" 
                                            className="areaCodeInput" 
                                            innerRef={ref} 
                                            {...fieldProps } 
                                             />)
                                        </>
                                    )} />
                                </InputGroupAddon>
                                <Controller id="phone1" name="phone1"
                                    control={control}
                                    rules={{required: true}}
                                    render={({field: {ref, ...fieldProps}}) => (
                                        <>
                                            <Input 
                                                type="text" 
                                                id="phone1" 
                                                innerRef={ref} 
                                                {...fieldProps } 
                                                className={`phoneNumber form-control ${errors.phone1 ? 'is-invalid' : ''}`}
                                                 />
                                            {errors.phone1 && <div class="invalid-feedback">{errors.phone1.type === 'required' && 'Please Enter a Phone Number'}</div>}
                                </> )} />
                            </InputGroup>
                            
                        </Col>
                </FormGroup>
                <FormGroup inline row>
                    <Label column sm="2" for="phone2">Phone 2</Label>
                    <Col sm="10">
                        <InputGroup className="inputGrp">
                            <InputGroupAddon addonType="prepend">
                            <Controller id="phone2AreaCode" name="phone2AreaCode"
                                    control={control}
                                    render={({field: {ref, ...fieldProps}}) => (
                                        <>
                                        (<Input type="text" 
                                            id="phone2AreaCode" 
                                            className="areaCodeInput" 
                                            innerRef={ref} 
                                            {...fieldProps } 
                                             />)
                                        </>
                                    )} />
                            </InputGroupAddon>
                            <Controller id="phone2" name="phone2"
                                    control={control}
                                    render={({field: {ref, ...fieldProps}}) => (
                                        <>
                                            <Input 
                                                type="text" 
                                                id="phone2" 
                                                innerRef={ref} 
                                                {...fieldProps } 
                                                className="phoneNumber"
                                                 />
                                </> )} />
                        </InputGroup>
                    </Col>
                </FormGroup>
                <FormGroup inline row>
                        <Label column sm="2" for="fax">Fax<BsDot color="red" /></Label>
                        <Col sm="10">
                            <InputGroup className="inputGrp">
                                <InputGroupAddon addonType="prepend">
                                <Controller id="faxAreaCode" name="faxAreaCode"
                                    control={control}
                                    render={({field: {ref, ...fieldProps}}) => (
                                        <>
                                        (<Input type="text" 
                                            id="faxAreaCode" 
                                            className="areaCodeInput" 
                                            innerRef={ref} 
                                            {...fieldProps } 
                                             />)
                                        </>
                                    )} />
                                </InputGroupAddon>
                                <Controller id="fax" name="fax"
                                    control={control}
                                    rules={{required: true}}
                                    render={({field: {ref, ...fieldProps}}) => (
                                        <>
                                            <Input 
                                                type="text" 
                                                id="fax" 
                                                innerRef={ref} 
                                                {...fieldProps } 
                                                className={`phoneNumber form-control ${errors.fax ? 'is-invalid' : ''}`}
                                                 />
                                            {errors.fax && <div class="invalid-feedback">{errors.fax.type === 'required' && 'Please Enter a Fax Number'}</div>}
                                </> )} />
                            </InputGroup>
                        </Col>
                    </FormGroup>
                
                    <div className="formGroup">
                        <div className="groupTitle">Business Address</div>
                            {editAddress ? <AddressEdit address={businessAddress} addressType={AddressTypeEnum.Business} updateAddress={updateAddress} cancelEdit={cancelEditAddress} /> 
                            : 
                            <div>
                                <div className="topButtons"><Button size="sm" onClick={() => setEditAddress(true)}>Edit<MdEdit /></Button></div>
                                <AddressView address={businessAddress} />  
                            </div>}
                    </div>
                    <div className="formGroup">
                        <div className="groupTitle">Postal Address</div>
                            <FormText>If different to Business Address</FormText>
                            {editPostalAddress ? <AddressEdit address={postalAddress} addressType={AddressTypeEnum.Postal} updateAddress={updatePostalAddress} cancelEdit={cancelEditPostalAddress} /> 
                            : 
                            <div>
                                <div className="topButtons"><Button size="sm" onClick={() => setEditPostalAddress(true)}>Edit<MdEdit /></Button></div>
                                <AddressView address={postalAddress} />  
                            </div>}
                    </div>
                    <div className="formGroup">
                        <div className="groupTitle">Finance Contact(s)</div>

                        <ContactList contacts={contacts} contactType={ContactTypeEnum.Finance} updatedContacts={contactsUpdated} onContactBeingEdited={onContactBeingEdited} onContactEditingComplete={onContactEditingComplete} />
                    </div>
                </div>
                <div className="formGroup financeForm">
                    <div className="groupTitle">Banking Details (Local Australian Dollar Account Only)</div>
                    <Alert color="secondary"><small>for payment of invoices only</small></Alert>

                    <Controller id="bankAccountName" name="bankAccountName" 
                        control={control}
                        rules={{required: true}}
                        render={({field: {ref, ...fieldProps}}) => (
                            <FormGroup row>
                                <Label column="true" sm="3" for="bankAccountName">Bank Account Name<BsDot color="red" /></Label>
                                <Col sm={10}>
                                    <Input 
                                        id="bankAccountName"
                                        type="text"
                                        innerRef={ref} 
                                        {...fieldProps } 
                                        className={`textInput form-control ${errors.bankAccountName ? 'is-invalid' : ''}`}/>
                                    {errors.bankAccountName && <div class="invalid-feedback">{errors.bankAccountName.type === 'required' && 'Please enter bank account name'}</div>}
                                </Col>
                            </FormGroup>
                        )}
                />
                <Controller id="bankName" name="bankName" 
                        control={control}
                        rules={{required: true}}
                        render={({field: {ref, ...fieldProps}}) => (
                            <FormGroup row>
                                <Label column="true" sm="3" for="bankName">Bank Name<BsDot color="red" /></Label>
                                <Col sm={10}>
                                    <Input 
                                        id="bankName"
                                        type="text"
                                        innerRef={ref} 
                                        {...fieldProps } 
                                        className={`textInput form-control ${errors.bankName ? 'is-invalid' : ''}`}/>
                                    {errors.bankName && <div class="invalid-feedback">{errors.bankName.type === 'required' && 'Please enter Bank Name'}</div>}
                                </Col>
                            </FormGroup>
                        )}
                />
                <Controller id="bsbNumber" name="bsbNumber" 
                        control={control}
                        rules={{required: true}}
                        render={({field: {ref, ...fieldProps}}) => (
                            <FormGroup row>
                                <Label column="true" sm="3" for="bsbNumber">BSB Number<BsDot color="red" /></Label>
                                <Col sm={10}>
                                    <Input 
                                        id="bsbNumber"
                                        type="text"
                                        innerRef={ref} 
                                        {...fieldProps } 
                                        className={`textInput form-control ${errors.bsbNumber ? 'is-invalid' : ''}`}/>
                                    {errors.bsbNumber && <div class="invalid-feedback">{errors.bsbNumber.type === 'required' && 'Please enter BSB Number'}</div>}
                                </Col>
                            </FormGroup>
                        )}
                />
                <Controller id="bankAccountNumber" name="bankAccountNumber" 
                        control={control}
                        rules={{required: true}}
                        render={({field: {ref, ...fieldProps}}) => (
                            <FormGroup row>
                                <Label column="true" sm="3" for="bankAccountNumber">Account Number<BsDot color="red" /></Label>
                                <Col sm={10}>
                                    <Input 
                                        id="bankAccountNumber"
                                        type="text"
                                        innerRef={ref} 
                                        {...fieldProps } 
                                        className={`textInput form-control ${errors.bankAccountNumber ? 'is-invalid' : ''}`}/>
                                    {errors.bankAccountNumber && <div class="invalid-feedback">{errors.bankAccountNumber.type === 'required' && 'Please enter Bank Account Number'}</div>}
                                </Col>
                            </FormGroup>
                        )}
                />
                </div>
                <div className="formGroup financeForm">
                    <div className="groupTitle">Payment Terms</div>


                    <FormGroup row>
                                <Label column sm="4" for="earlyPaymentDiscount">Is there an Early Payment Discount?<BsDot color="red" /></Label>
                                <Col sm={10}>
                                    Yes{' '}<input 
                                        type="radio"
                                        value={true}
                                        className={`${errors.earlyPaymentDiscount ? 'is-invalid' : ''}`}
                                        {...register("earlyPaymentDiscount", {
                                            required: true
                                          })} />
                                    {' '}No{' '}<input type="radio" 
                                    id="earlyPaymentDiscount" 
                                    value={false}
                                    className={`${errors.earlyPaymentDiscount ? 'is-invalid' : ''}`}
                                    {...register("earlyPaymentDiscount", {
                                        required: true
                                      })}
                                    />
                                    {errors.earlyPaymentDiscount && <div class="invalid-feedback">{errors.earlyPaymentDiscount.type === 'required' && 'Please enter if there is an early payment discount available'}</div>}
                                        
                                </Col>
                            </FormGroup>                  
                    {earlyPaymentDiscountWatch && earlyPaymentDiscountWatch === "true" &&
                    <>
                        <Controller id="discountRate" name="discountRate" 
                            control={control}
                            rules={{required: true}}
                            render={({field: {ref, ...fieldProps}}) => (
                                <FormGroup row>
                                    <Label column="true" sm="4" for="discountRate">Early Payment Discount Rate<BsDot color="red" /></Label>
                                    <Col sm={10}>
                                    <InputGroup>
                                        <Input 
                                            id="discountRate"
                                            type="text"
                                            innerRef={ref} 
                                            {...fieldProps } 
                                            className={`textInput form-control ${errors.discountRate ? 'is-invalid' : ''}`}/>
                                            <InputGroupAddon addonType="append">%</InputGroupAddon>
                                            </InputGroup>
                                        {errors.discountRate && <div class="invalid-feedback">{errors.discountRate.type === 'required' && 'Please enter early payment discount rate'}</div>}
                                    </Col>
                                </FormGroup>
                            )}
                    />
                    </>
                    }
                    <Controller id="paymentTermWithoutDiscount" name="paymentTermWithoutDiscount" 
                            control={control}
                            rules={{required: true}}
                            render={({field: {ref, ...fieldProps}}) => (
                                <FormGroup row>
                                    <Label column="true" sm="4" for="paymentTermWithoutDiscount">Payment Term without Discount<BsDot color="red" /></Label>
                                    <Col sm={10}>
                                    <InputGroup>
                                        <Input 
                                            id="paymentTermWithoutDiscount"
                                            type="text"
                                            innerRef={ref} 
                                            {...fieldProps } 
                                            className={`textInput form-control ${errors.paymentTermWithoutDiscount ? 'is-invalid' : ''}`}/>
                                            <InputGroupAddon addonType="append">days</InputGroupAddon>
                                            </InputGroup>
                                        {errors.paymentTermWithoutDiscount && <div class="invalid-feedback">{errors.paymentTermWithoutDiscount.type === 'required' && 'Please enter payment term without discount'}</div>}
                                    </Col>
                                </FormGroup>
                            )}
                    />
                    <FormGroup row>
                        <Label column sm="4" for="isPrepaymentRequired">Is Prepayment required?<BsDot color="red" /></Label>
                        <Col sm={10}>
                        Yes{' '}<input 
                                        type="radio"
                                        value={true}
                                        className={`${errors.isPrepaymentRequired ? 'is-invalid' : ''}`}
                                        {...register("isPrepaymentRequired", {
                                            required: true
                                          })} />
                            {' '}No{' '}<input 
                                        type="radio"
                                        value={false}
                                        className={`${errors.isPrepaymentRequired ? 'is-invalid' : ''}`}
                                        {...register("isPrepaymentRequired", {
                                            required: true
                                          })} />
                            {errors.isPrepaymentRequired && <div class="invalid-feedback">{errors.isPrepaymentRequired.type === 'required' && 'Please enter if there prepayment is required'}</div>}
                        </Col>
                    </FormGroup>
                    {isPrepaymentRequiredWatch && isPrepaymentRequiredWatch === "true" &&
                    <Controller id="paymentLeadTimePriorToDelivery" name="paymentLeadTimePriorToDelivery" 
                    control={control}
                    rules={{required: true}}
                    render={({field: {ref, ...fieldProps}}) => (
                        <FormGroup row>
                            <Label column="true" sm="4" for="paymentLeadTimePriorToDelivery">Payment Lead Time Prior to Delivery<BsDot color="red" /></Label>
                            <Col sm={10}>
                            <InputGroup>
                                <Input 
                                    id="paymentLeadTimePriorToDelivery"
                                    type="text"
                                    innerRef={ref} 
                                    {...fieldProps } 
                                    className={`textInput form-control ${errors.paymentLeadTimePriorToDelivery ? 'is-invalid' : ''}`}/>
                                    <InputGroupAddon addonType="append">days</InputGroupAddon>
                                    {errors.paymentLeadTimePriorToDelivery && <div class="invalid-feedback">
                                    {errors.paymentLeadTimePriorToDelivery.type === 'required' && 'Please enter the number of days prior to delivery payment is required'}
                                    </div>}
                            </InputGroup>
                                
                            </Col>
                        </FormGroup>
                    )}
                />
                    // <FormGroup row>
                    //     <Label column sm="4" for="paymentLeadTimePriorToDelivery">Payment Lead Time Prior to Delivery<BsDot color="red" /></Label>
                    //     <Col sm={10}>
                    //         <InputGroup>
                    //             <Input type="text" name="paymentLeadTimePriorToDelivery" id="paymentLeadTimePriorToDelivery" className="textInput" value={paymentLeadTimePriorToDelivery} onChange={e => setPaymentLeadTimePriorToDelivery(e.target.value) } invalid={isEmpty(paymentLeadTimePriorToDelivery)} />
                    //             <InputGroupAddon addonType="append">days</InputGroupAddon>
                    //             <FormFeedback valid={!isEmpty(paymentLeadTimePriorToDelivery)}>Please enter the number of days prior to delivery payment is required.</FormFeedback>    
                    //         </InputGroup>
                    //     </Col>
                    // </FormGroup>
                    }
                </div>
                <div className="formGroup financeForm">
                    <div className="groupTitle">Credit Limit</div>

                    <Controller id="creditLimit" name="creditLimit" 
                        control={control}
                        rules={{required: true, min: 0}}
                        render={({field: {ref, ...fieldProps}}) => (
                            <FormGroup row>
                                <Label column="true" sm="3" for="creditLimit">Credit Limit<BsDot color="red" /></Label>
                                <Col sm={10}>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                                    <Input 
                                        id="creditLimit"
                                        type="number"
                                        innerRef={ref} 
                                        {...fieldProps } 
                                        className={`textInput form-control ${errors.creditLimit ? 'is-invalid' : ''}`}/>
                                        {errors.creditLimit && <div class="invalid-feedback">
                                        {errors.creditLimit.type === 'required' && 'Please enter Credit Limit'}
                                        {errors.creditLimit.type === 'min' && 'Credit Limit must be greater than 0'}
                                        </div>}
                                </InputGroup>
                                    
                                </Col>
                            </FormGroup>
                        )}
                    />
                </div>
                <div className="footer box-shadow">
                    <Button color="primary" disabled={contactBeingEdited} onClick={handleSubmit(submitFinance)}>Submit</Button>
                </div>
                </Form>
        </div>
    )    
}