import React, { Component, useEffect, useState } from 'react';
import { Route, useLocation } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { FetchData } from './components/FetchData';
import { Counter } from './components/Counter';

import './custom.css'
import Login from './components/Account/Login';
import { useAuthState } from './contexts/AuthContext';
import ErrorToast from './components/Error/ErrorToast';
import { useServerErrorState } from './contexts/ServerErrorContext';
import { ErrorBoundary } from 'react-error-boundary';
import AppLayout from './AppLayout';
import { useFactsState } from './contexts/FactsContext';
import { BASEAPI } from './constants';
import { GetJson } from './helpers/fetch_helpers';
import ForgotPassword from './components/Account/ForgotPassword';
import ResetPassword from './components/Account/ResetPassword';
import SelectSupplier from './components/Account/SelectSupplier';
import BoxContents from './components/BoxContents/BoxContents';
import HamperContents from './components/BoxContents/HamperContents';

function ErrorFallback({error, resetErrorBoundary}) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  )
}

export default function App() {
  //static displayName = App.name;
  var [authState, dispatchAuth] = useAuthState();
  var [serverErrorState, serverErrorDispatch] = useServerErrorState();
  let [factsState, dispatchFacts] = useFactsState();
  let location = useLocation();
  var isBoxContents = location.pathname.toLowerCase().startsWith("/boxcontents");
  var isHamperContents = location.pathname.toLowerCase().startsWith("/hampercontents");
  var isForgotPassword = location.pathname === "/forgotPwd";
  var isResetPassword = location.pathname === "/ResetPassword";

  function getToken() {
    const tokenString = sessionStorage.getItem('token');
    
    if (tokenString)
    {
      dispatchAuth({type: "SET_TOKEN", token: tokenString});
    }
  }

  function getUser() {
    const userString = sessionStorage.getItem('user');

    if (userString) {
      var user = JSON.parse(userString);
      dispatchAuth({type: "SET_USER", user: user});
    }
  }

  function getSupplier() {
    const supplierId = sessionStorage.getItem('supplierId');

    if (supplierId) {
      dispatchAuth({type: "SET_SUPPLIER", supplierId: supplierId});
    }
  }

  async function getStates() {
    const uri = BASEAPI + "/api/address/getStates";
    var result = await GetJson(uri, null)
    .catch(function(error){
        console.log(error);
        serverErrorDispatch({type: "SET_ERROR",errorTitle:"Error retrieving states", errorMessage: error.message});
    })

    dispatchFacts({type: "INIT_STATES", states: result});
}

  async function getCountries() {
    const uri = BASEAPI + "/api/address/getCountries";
    var result = await GetJson(uri, null)
    .catch(function(error) {
      console.log(error);
      serverErrorDispatch({type: "SET_ERROR",errorTitle:"Error retrieving countries", errorMessage: error.message});
    })

    dispatchFacts({type: "INIT_COUNTRIES", countries: result});
  }

  useEffect(() => {
    getToken();
    getUser();
    getSupplier();
    getStates();
    getCountries();
  }, [])

  if (isBoxContents) {
    return <BoxContents />
  } else if (isHamperContents) {
    return <HamperContents />
  }

  if (!authState.token) {
    return (
    <div>
      <ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => {

      }} onError={(error) => console.log("ErrorBoundary:" + error)}
      >
        {serverErrorState.errorMessage && 
          <ErrorToast />
        }
        {isForgotPassword ? 
        <ForgotPassword /> :
        (isResetPassword ?
          <ResetPassword /> :
          <Login />
        )
        }
      </ErrorBoundary>
    </div>)
  }
    
  if (authState.supplierId == null && authState.user && authState.user.isStaff) {
    return (
      <div>
        <ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => {

        }} onError={(error) => console.log("ErrorBoundary:" + error)}
        >
        {serverErrorState.errorMessage && 
          <ErrorToast />
        }
        <SelectSupplier />
        </ErrorBoundary>
      </div>
    )
  }
  return (
      <>
        <div>
        <ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => {

        }} onError={(error) => console.log("ErrorBoundary:" + error)}
        >
            {serverErrorState.errorMessage && 
              <ErrorToast />
            }
            <AppLayout />
        </ErrorBoundary>
        </div>
     
    </>
    );
}
