import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, FormGroup, Input, Label, Table } from "reactstrap";
import { BASEAPI, DEFAULT_SELECT } from "../../constants";
import { useAuthState } from "../../contexts/AuthContext";
import { useServerErrorState } from "../../contexts/ServerErrorContext";
import { SkuReviewStatusLabel } from "../../helpers/enums";
import { GetJsonWithToken } from "../../helpers/fetch_helpers";

export default function SkuReviews() {
    let [allReviews, setAllReviews] = useState();
    let [terms, setTerms] = useState();
    let [termId, setTermId] = useState();
    let [loading, setLoading] = useState(false);    

    let [authState, dispatchAuth] = useAuthState();
    let [serverError, dispatchServerError] = useServerErrorState();
    let history = useHistory();

    async function getTerms() {
        setLoading(true);

        var uri = BASEAPI + "/api/term/";
        var result = await GetJsonWithToken(uri, null, authState.token)
        .catch(function(error){
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error getting terms", errorMessage: error.message});
        })
        .finally(() => setLoading(false));

        setTerms(result);
    }

    async function getReviews() {
        setLoading(true);
        
        var uri = BASEAPI + "/api/skuReview/term/" + termId;
        var result = await GetJsonWithToken(uri, null, authState.token)
        .catch(function(error){
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error getting reviews for term", errorMessage: error.message});
        })
        .finally(() => setLoading(false));

        setAllReviews(result);
    }

    function buildTermOptions() {
        var options = [];
        options.push(<option key={-1} value="-1">{DEFAULT_SELECT}</option>)

        terms && terms.filter(x => !x.legacyTermId || x.legacyTermId > 633933).map((term, index) => {
            var opt = <option key={index} value={term.termId}>{term.termName}</option>
            options.push(opt);
        })

        return options;
    }

    useEffect(() => {
        getTerms();
    }, [])

    useEffect(() => {
        if (termId)
            getReviews();
    }, [termId])

    function viewReview(reviewId) {
        history.push("/skuReview/" + reviewId);
    }

    return (
        <div>
            {terms && 
                <FormGroup>
                    <Label for="term">Select Term:</Label>
                    <Input type="select" name="term" id="term" placeholder="Choose a Term" value={termId} onChange={e => setTermId(e.target.value)}>
                    {buildTermOptions()}
                    </Input>
                </FormGroup>
            }
            {allReviews && allReviews.length > 0 &&
            <div>
                <h3>Sku Reviews for Term</h3>
                <Table>
                    <thead>
                        <tr>
                            <th>Sku Name</th>
                            <th>Submitted By</th>
                            <th>Submitted At</th>
                            <th>Review Status</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                    {allReviews.map((review, index) => {
                        return <tr index={index}>
                            <td>{review.skuName}</td>
                            <td>{review.submitterName}</td>
                            <td>{format(new Date(review.submittedAt), 'dd/MM/yyyy hh:mm:ss')}</td>
                            <td>{SkuReviewStatusLabel(review.reviewStatus)}</td>
                            <td><Button color="link" onClick={() => viewReview(review.skuReviewId)}>View</Button></td>
                        </tr>
                    })}
                    </tbody>
                </Table>
            </div>
            }
            {allReviews && allReviews.length === 0 &&
            <div>
                No Reviews for Term
            </div>}
        </div>
    )
}