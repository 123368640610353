import React, { useState } from "react";
import { Col, Form, FormGroup, Label, Button, Input, FormText, Modal, ModalHeader, ModalBody, ModalFooter, Alert } from "reactstrap";
import { MdEdit } from "react-icons/md";
import "./GeneralInfo.css";
import AddressEdit, { AddressTypeEnum } from "../../Address/AddressEdit";
import AddressView from "../../Address/AddressView";
import { PostFormWithToken, PostJsonWithToken } from "../../../helpers/fetch_helpers";
import { BASEAPI } from "../../../constants";
import { useAuthState } from "../../../contexts/AuthContext";
import { useServerErrorState } from "../../../contexts/ServerErrorContext";
import { convertAddress } from "../../../helpers/conversions";
import { uuidv4 } from "../../Utility/guid";
import { DocumentTypeEnum } from "../../../helpers/enums";
import { isNoSubstitutionTemplateLiteral } from "typescript";
import { format, isValid, set } from "date-fns";
import { BsDot } from "react-icons/bs";

export default function GeneralInfo({requestId, generalInfo, nextStep, generalInfoUpdated}) {
    let [editAddress, setEditAddress] = useState(false);
    let [authState, dispatchAuth] = useAuthState();
    let [serverErrorState, dispatchServerError] = useServerErrorState();
    let [hasModernSlavery, setHasModernSlavery] = useState(generalInfo ? generalInfo.modernSlaveryStatement : null);
    let [modernSlaveryDate, setModernSlaveryDate] = useState(generalInfo ? format(new Date(generalInfo.modernSlaveryStatementDate), 'yyyy-MM-dd') : null);
    let [modernSlaveryDocumentId, setModernSlaveryDocumentId] = useState(generalInfo ? generalInfo.modernSlaveryDocumentId : null);
    let [file, setFile] = useState(null);
    let [website, setWebsite] = useState(generalInfo ? generalInfo.website : null);
    let [isAddressCurrent, setIsAddressCurrent] = useState(generalInfo ? generalInfo.isAddressCurrent : null);

    let [showUploadModernSlaveryModal, setShowUploadModernSlaveryModal] = useState(false);
    let [showViewModernSlaveryModal, setShowViewModernSlaveryModal] = useState(false);

    let documentPath = modernSlaveryDocumentId ? BASEAPI + "/api/document/" + modernSlaveryDocumentId + "/?supplierId=" + generalInfo.supplierId  : '';

    function convertGeneralInfo(generalInfo) {
        var convertedGeneralInfo = {
            supplierName: generalInfo.supplierName,
            supplierId: generalInfo.supplierId,
            modernSlaveryStatement: hasModernSlavery,
            modernSlaveryStatementDate: modernSlaveryDate,
            modernSlaveryDocumentId: modernSlaveryDocumentId,
            address: convertAddress(generalInfo.address),
            website: website,
            isAddressCurrent: isAddressCurrent
        }

        return convertedGeneralInfo;
    }

    async function saveAndContinue() {
        var uri = BASEAPI + "/api/submission/SaveGeneralInfo";
        var data = {
            supplierRequestId: requestId,
            generalInformation: convertGeneralInfo(generalInfo)
        }
        var result = await PostJsonWithToken(uri, data, authState.token)
        .catch(function(error){
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error saving general information", errorMessage: error.message});
        })

        if (result) {
            generalInfoUpdated(convertGeneralInfo(generalInfo))
            nextStep();
        }
    }

    function updateAddress(address) {
        generalInfo.address = address;
        setEditAddress(false);
    }

    function cancelEditAddress() {
        setEditAddress(false);
    }

    function cancelUpload() {
        setShowUploadModernSlaveryModal(false);
    }

    async function uploadStatement() {
        var fileInput = document.querySelector('#modernSlaveryStatementFile');

        var documentId = uuidv4();

        const data = new FormData();
        data.append('uploadedFile', fileInput.files[0]);
        data.append('documentId', documentId);
        data.append('supplierId', generalInfo.supplierId);
        data.append('documentType', DocumentTypeEnum.ModernSlaveryStatement);

        var uri = BASEAPI + "/api/document/ImportDocument";

        var result = await PostFormWithToken(uri, data, authState.token)
        .catch(function(error) {
            console.log(error);
            dispatchServerError({type: "SET_ERROR", errorTitle:"Error Uploading Modern Slavery Statement", errorMessage: error.message});
        })

        if (result) {
            generalInfo.modernSlaveryDocumentId = documentId;
            setModernSlaveryDocumentId(documentId);
            setShowUploadModernSlaveryModal(false);
        }
    }

    function modernSlaveryFileChanged() {
        var fileInput = document.querySelector('#modernSlaveryStatementFile');
        setFile(fileInput.files[0]);
    }

    function closeStatementView() {
        setShowViewModernSlaveryModal(false);
    }

    function updateModernSlaveryStatementDate(e) {
        var date = new Date(e.target.value);
        var formattedDate = format(date, 'yyyy-MM-dd');
        setModernSlaveryDate(formattedDate);
        generalInfo.modernSlaveryStatementDate = formattedDate;
    }

    function isValid() {
        if (editAddress)
            return false;

        if (hasModernSlavery == null)
            return false;
        
        if (isAddressCurrent == null)
            return false;

        return true;
    }

    return (
        <div className="submissionContainer">
            <h2>General</h2>
            <Form className="formGroup generalFormGroup">
                <div className="groupTitle">General Information</div>
                <Alert color="light">Required Fields <BsDot color="red" /></Alert>

                <FormGroup row>
                    <Label for="supplierName">Name</Label>
                    <Col sm={10}>
                        <Input type="text" name="supplierName" id="supplierName" className="textInput" value={generalInfo.supplierName} readOnly />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="website">Website</Label>
                    <Col sm={10}>
                        <Input type="text" name="website" id="website" className="textInput" value={generalInfo.website} onChange={(e) => setWebsite(e.target.value)} />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="hasModernSlavery"><BsDot color="red" /> Do you have a Modern Slavery Statement?</Label>
                    <Col sm={10}>
                        <FormText color="muted">Current Modern Slavery Statement required if Supplier revenue exceeds $100 Million.</FormText>
                        <FormGroup check>
                                <Label check>
                                    <Input type="radio" name="hasModernSlavery" value={true} checked={hasModernSlavery} onChange={e => setHasModernSlavery(true)} />Yes
                                </Label>
                        </FormGroup>
                        <FormGroup check>
                                <Label check>
                                    <Input type="radio" name="hasModernSlavery" value={false} checked={hasModernSlavery != null && hasModernSlavery == false} onChange={e => setHasModernSlavery(false)} />No
                                </Label>
                        </FormGroup>
                    </Col>
                </FormGroup>
                {hasModernSlavery && 
                <>
                        <FormGroup row>
                            <Label for="modernSlaveryStatementDate">Modern Slavery Statement Date</Label>
                            <Col sm={10}>
                                <Input type="date" className="dateInput" name="modernSlaveryDate" value={modernSlaveryDate} onChange={e => updateModernSlaveryStatementDate(e)} />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label>Modern Slavery Statement</Label>
                            <Col sm={10}>
                            <div>
                                {modernSlaveryDocumentId && <Button onClick={e => setShowViewModernSlaveryModal(true)} style={{marginRight: '10px'}}>View Statement</Button> }
                                <Button onClick={e => setShowUploadModernSlaveryModal(true)}>Upload Statement</Button>
                            </div>
                            </Col>
                        </FormGroup>
                </>
                }
            </Form>
            <Form className="formGroup generalFormGroup">
                <div className="groupTitle">Business Address</div>
                {editAddress ? <AddressEdit address={generalInfo.address} addressType={AddressTypeEnum.Business} updateAddress={updateAddress} cancelEdit={cancelEditAddress} /> : 
                <div className="addressBox">
                    <div className="topButtons"><Button size="sm" onClick={() => setEditAddress(true)}>Edit<MdEdit /></Button></div>
                    <AddressView address={generalInfo.address} />  
                </div>}
                <FormGroup row>
                    <Label for="isAddressCurrent"><BsDot color="red" /> Is Address Current?</Label>
                    <Col sm={10}>
                        <FormGroup check>
                                <Label check>
                                    <Input type="radio" name="isAddressCurrent" value={true} checked={isAddressCurrent} onChange={e => setIsAddressCurrent(true)} />Yes
                                </Label>
                        </FormGroup>
                        <FormGroup check>
                                <Label check>
                                    <Input type="radio" name="isAddressCurrent" value={false} checked={isAddressCurrent != null && isAddressCurrent == false} onChange={e => setIsAddressCurrent(false)} />No
                                </Label>
                        </FormGroup>
                    </Col>
                </FormGroup>
            </Form>
            <div className="footer box-shadow">
                <Button color="primary" disabled={!isValid()} onClick={() => saveAndContinue()}>Save and Continue</Button>
            </div>
            <Modal isOpen={showUploadModernSlaveryModal} fade={true}>
                <ModalHeader>Upload Modern Slavery Statement</ModalHeader>
                <ModalBody>
                    <Input type="file" id="modernSlaveryStatementFile" onChange={modernSlaveryFileChanged} />
                </ModalBody>
                <ModalFooter>
                    <Button onClick={uploadStatement}>Upload</Button>
                    <Button onClick={cancelUpload}>Cancel</Button>
                </ModalFooter>
            </Modal>
            <Modal isOpen={showViewModernSlaveryModal} fade={true} size="lg">
                <ModalHeader>Current Modern Slavery Statement</ModalHeader>
                <ModalBody style={{height: '80vh'}}>
                    <iframe width="100%" height="100%" src={documentPath} />
                </ModalBody>
                <ModalFooter>
                    <Button onClick={closeStatementView}>Close</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}