import React, { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { BsDot } from "react-icons/bs";
import { MdEdit } from "react-icons/md";
import { Alert, Button, Col, Container, Form, FormFeedback, FormGroup, FormText, Input, Label, Row } from "reactstrap";
import { BASEAPI } from "../../../constants";
import { useAuthState } from "../../../contexts/AuthContext";
import { useServerErrorState } from "../../../contexts/ServerErrorContext";
import { ContactTypeEnum } from "../../../helpers/enums";
import { GetJsonWithToken } from "../../../helpers/fetch_helpers";
import { emailIsValid, isEmpty } from "../../../helpers/utils";
import AddressEdit, { AddressTypeEnum } from "../../Address/AddressEdit";
import AddressView from "../../Address/AddressView";
import ContactList from "../../Contact/ContactList";
import "./finance.css";

export default function InternationalFinanceDetails({financeModel, financeUpdated}) {

    let [authState, dispatchAuth] = useAuthState();
    let [serverErrorState, dispatchServerError] = useServerErrorState();

    let [supplierFinanceId, setSupplierFinanceId] = useState(financeModel ? financeModel.financeDetails.supplierFinanceId : null);
    let [supplierId, setSupplierId] = useState(financeModel ? financeModel.financeDetails.supplierId : null);

    const { register, handleSubmit, isDirty, isSubmitting, touchedFields, submitCount, watch, control, formState: { errors }} = useForm({
        mode: "all",
        defaultValues: {
            companyName: financeModel ? financeModel.financeDetails.businessDetails.companyName : '',
            tradingName: financeModel ? financeModel.financeDetails.businessDetails.tradingName : '',
            businessRegistrationNumber: financeModel ? financeModel.financeDetails.businessRegistrationNumber : '',
            phone: financeModel ? financeModel.financeDetails.contactDetails.phone : '',
            fax: financeModel ? financeModel.financeDetails.contactDetails.fax : '',
            beneficiaryBankName: financeModel ? financeModel.financeDetails.bankDetails.beneficiaryBankName : '',
            beneficiaryBankAddress: financeModel ? financeModel.financeDetails.bankDetails.beneficiaryBankAddress : '',
            beneficiaryBankAccountName: financeModel ? financeModel.financeDetails.bankDetails.beneficiaryBankAccountName : '',
            beneficiaryBankAccountNumber: financeModel ? financeModel.financeDetails.bankDetails.beneficiaryBankAccountNumber : '',
            swiftCodeIbanOrSortCode: financeModel ? financeModel.financeDetails.bankDetails.swiftCodeIbanOrSortCode  : '',
            emailRemittanceAdvice: financeModel ? financeModel.financeDetails.accountDetails.emailRemittanceAdvice : '',
            paymentTerms: financeModel ? financeModel.financeDetails.accountDetails.paymentTerms : '',
        }
    });

   
    let [businessAddress, setBusinessAddress] = useState(financeModel ? financeModel.businessAddress : null);
    let [postalAddress, setPostalAddress] = useState(financeModel ? financeModel.postalAddress : null);
    let [contacts, setContacts] = useState(financeModel ? financeModel.contacts : null);

    let [editAddress, setEditAddress] = useState(false);
    let [editPostalAddress, setEditPostalAddress] = useState(false);
    let [contactBeingEdited, setContactBeingEdited] = useState(false);

    let [supplier, setSupplier] = useState();

    useEffect(() => {
        if (supplierId) {
            getSupplier();
        }

    }, [supplierId])

    async function getSupplier() {
        var uri = BASEAPI + "/api/supplier/" + supplierId;
    
        var result = await GetJsonWithToken(uri, null, authState.token)
        .catch(function(error) {
            console.log(error);
            dispatchServerError({type: "SET_ERROR", errorTitle: "Error getting supplier", errorMessage: error.message});
        })
        
        setSupplier(result);
      }

    function updateAddress(address) {
        setBusinessAddress(address);
        setEditAddress(false);
    }

    function cancelEditAddress() {
        setEditAddress(false);
    }

    function updatePostalAddress(address) {
        setPostalAddress(address);
        setEditPostalAddress(false);
    }

    function cancelEditPostalAddress() {
        setEditPostalAddress(false);
    }

    function onContactBeingEdited() {
        setContactBeingEdited(true);
    }

    function onContactEditingComplete() {
        setContactBeingEdited(false);
    }

    function contactsUpdated(contacts) {
        setContacts(contacts);
    }

    function submitFinance(formData) {
        var financeModel = buildFinanceModel(formData);
        financeUpdated(financeModel);
    }

    function buildFinanceModel(formData) {
        var financeModel = {
            businessAddress: businessAddress,
            postalAddress: postalAddress,
            contacts: contacts,            
            financeDetails: {
                supplierFinanceId: supplierFinanceId,
                supplierId: supplierId,
                isInternational: true,
                businessDetails: {
                    companyName: formData.companyName,
                    tradingName: formData.tradingName,
                    businessRegistrationNumber: formData.businessRegistrationNumber
                },
                bankDetails: {
                    beneficiaryBankName: formData.beneficiaryBankName,
                    beneficiaryBankAccountName: formData.beneficiaryBankAccountName,
                    beneficiaryBankAccountNumber: formData.beneficiaryBankAccountNumber,
                    beneficiaryBankAddress: formData.beneficiaryBankAddress,
                    swiftCodeIbanOrSortCode: formData.swiftCodeIbanOrSortCode
                },
                contactDetails: {
                    phone: formData.phone,
                    fax: formData.fax
                },
                accountDetails: {
                    emailRemittanceAdvice: formData.emailRemittanceAdvice,
                    paymentTerms: formData.paymentTerms
                }
            }
        };

        return financeModel;
    }

    return (
        <div className="bodyWithFooter">
            <h2>{supplier ? supplier.supplierName : 'Supplier'} - New Supplier Information (International)</h2>
        
        <Form>
            <div className="formGroup financeForm">
                <div className="groupTitle">Business Details</div>
                <Alert color="light">Required Fields <BsDot color="red" /></Alert>

                <Controller id="companyName" name="companyName" 
                        control={control}
                        rules={{required: true}}
                        render={({field: {ref, ...fieldProps}}) => (
                            <FormGroup row>
                                <Label column="true" sm="4" for="companyName">Company Name<BsDot color="red" /></Label>
                                <Col sm={10}>
                                    <Input 
                                        id="companyName"
                                        type="text"
                                        innerRef={ref} 
                                        {...fieldProps } 
                                        className={`textInput form-control ${errors.companyName ? 'is-invalid' : ''}`}/>
                                    {errors.companyName && <div class="invalid-feedback">{errors.companyName.type === 'required' && 'Please enter a Company Name'}</div>}
                                </Col>
                            </FormGroup>
                        )}
                />
                
                <Controller id="tradingName" name="tradingName"
                    control={control}
                    render={({field: {ref, ...fieldProps}}) => (
                        <FormGroup inline row>
                            <Label column="true" sm="4" for="tradingName">Trading Name</Label>
                            <Col sm={10}>
                                <Input 
                                id="tradingName" 
                                type="text"
                                innerRef={ref} 
                                {...fieldProps } 
                                className="textInput" />
                            </Col>
                        </FormGroup>
                )} />
               
                <Controller id="businessRegistrationNumber" name="businessRegistrationNumber" 
                        control={control}
                        rules={{required: true}}
                        render={({field: {ref, ...fieldProps}}) => (
                            <FormGroup row>
                                <Label column="true" sm="4" for="businessRegistrationNumber">Business Registration Number<BsDot color="red" /></Label>
                                <Col sm={10}>
                                    <Input 
                                        id="businessRegistrationNumber"
                                        type="text"
                                        innerRef={ref} 
                                        {...fieldProps } 
                                        className={`textInput form-control ${errors.businessRegistrationNumber ? 'is-invalid' : ''}`}/>
                                    {errors.businessRegistrationNumber && <div class="invalid-feedback">{errors.businessRegistrationNumber.type === 'required' && 'Please enter business registration number'}</div>}
                                </Col>
                            </FormGroup>
                        )}
                />               
            </div>

            <div className="formGroup financeForm">
                <div className="groupTitle">Contact Details</div>

                <Controller id="phone" name="phone" 
                        control={control}
                        rules={{required: true}}
                        render={({field: {ref, ...fieldProps}}) => (
                            <FormGroup row>
                                <Label column="true" sm="4" for="phone">Phone Number<BsDot color="red" /></Label>
                                <Col sm={10}>
                                    <Input 
                                        id="phone"
                                        type="text"
                                        innerRef={ref} 
                                        {...fieldProps } 
                                        className={`textInput form-control ${errors.phone ? 'is-invalid' : ''}`}/>
                                    {errors.phone && <div class="invalid-feedback">{errors.phone.type === 'required' && 'Please enter a phone number'}</div>}
                                </Col>
                            </FormGroup>
                        )}
                />
                
                <Controller id="fax" name="fax" 
                        control={control}
                        rules={{required: true}}
                        render={({field: {ref, ...fieldProps}}) => (
                            <FormGroup row>
                                <Label column="true" sm="4" for="fax">Fax Number<BsDot color="red" /></Label>
                                <Col sm={10}>
                                    <Input 
                                        id="fax"
                                        type="text"
                                        innerRef={ref} 
                                        {...fieldProps } 
                                        className={`textInput form-control ${errors.fax ? 'is-invalid' : ''}`}/>
                                    {errors.fax && <div class="invalid-feedback">{errors.fax.type === 'required' && 'Please enter a fax number'}</div>}
                                </Col>
                            </FormGroup>
                        )}
                />
                
                <div className="formGroup financeForm">
                        <div className="groupTitle">Business Address</div>
                            {editAddress ? <AddressEdit address={businessAddress} addressType={AddressTypeEnum.Business} updateAddress={updateAddress} cancelEdit={cancelEditAddress} /> 
                            : 
                            <div>
                                <div className="topButtons"><Button size="sm" onClick={() => setEditAddress(true)}>Edit<MdEdit /></Button></div>
                                <AddressView address={businessAddress} />  
                            </div>}
                    </div>
                    <div className="formGroup">
                        <div className="groupTitle">Postal Address</div>
                            <FormText>If different to Business Address</FormText>
                            {editPostalAddress ? <AddressEdit address={postalAddress} addressType={AddressTypeEnum.Postal} updateAddress={updatePostalAddress} cancelEdit={cancelEditPostalAddress} /> 
                            : 
                            <div>
                                <div className="topButtons"><Button size="sm" onClick={() => setEditPostalAddress(true)}>Edit<MdEdit /></Button></div>
                                <AddressView address={postalAddress} />  
                            </div>}
                    </div>
                    <div className="formGroup">
                        <div className="groupTitle">Contact Person(s)</div>

                        <ContactList contacts={contacts} contactType={ContactTypeEnum.Finance} updatedContacts={contactsUpdated} onContactBeingEdited={onContactBeingEdited} onContactEditingComplete={onContactEditingComplete} />
                    </div>
            </div>

            <div className="formGroup financeForm">
                <div className="groupTitle">Overseas Bankding Information</div>

                <Controller id="beneficiaryBankName" name="beneficiaryBankName" 
                        control={control}
                        rules={{required: true}}
                        render={({field: {ref, ...fieldProps}}) => (
                            <FormGroup row>
                                <Label column="true" sm="4" for="beneficiaryBankName">Benificiary Bank Name<BsDot color="red" /></Label>
                                <Col sm={10}>
                                    <Input 
                                        id="beneficiaryBankName"
                                        type="text"
                                        innerRef={ref} 
                                        {...fieldProps } 
                                        className={`textInput form-control ${errors.beneficiaryBankName ? 'is-invalid' : ''}`}/>
                                    {errors.beneficiaryBankName && <div class="invalid-feedback">{errors.beneficiaryBankName.type === 'required' && 'Please enter Benificiary Bank Name'}</div>}
                                </Col>
                            </FormGroup>
                        )}
                />
               
                <Controller id="beneficiaryBankAddress" name="beneficiaryBankAddress" 
                        control={control}
                        rules={{required: true}}
                        render={({field: {ref, ...fieldProps}}) => (
                            <FormGroup row>
                                <Label column="true" sm="4" for="beneficiaryBankAddress">Benificiary Bank Address<BsDot color="red" /></Label>
                                <Col sm={10}>
                                    <Input 
                                        id="beneficiaryBankAddress"
                                        type="text"
                                        innerRef={ref} 
                                        {...fieldProps } 
                                        className={`textInput form-control ${errors.beneficiaryBankAddress ? 'is-invalid' : ''}`}/>
                                    {errors.beneficiaryBankAddress && <div class="invalid-feedback">{errors.beneficiaryBankAddress.type === 'required' && 'Please enter address of beneficiary bank'}</div>}
                                </Col>
                            </FormGroup>
                        )}
                />
                
                <Controller id="beneficiaryBankAccountName" name="beneficiaryBankAccountName" 
                        control={control}
                        rules={{required: true}}
                        render={({field: {ref, ...fieldProps}}) => (
                            <FormGroup row>
                                <Label column="true" sm="4" for="beneficiaryBankAccountName">Benificiary Bank Account Name<BsDot color="red" /></Label>
                                <Col sm={10}>
                                    <Input 
                                        id="beneficiaryBankAccountName"
                                        type="text"
                                        innerRef={ref} 
                                        {...fieldProps } 
                                        className={`textInput form-control ${errors.beneficiaryBankAccountName ? 'is-invalid' : ''}`}/>
                                    {errors.beneficiaryBankAccountName && <div class="invalid-feedback">{errors.beneficiaryBankAccountName.type === 'required' && 'Please enter bank account name'}</div>}
                                </Col>
                            </FormGroup>
                        )}
                />
               
                <Controller id="beneficiaryBankAccountNumber" name="beneficiaryBankAccountNumber" 
                        control={control}
                        rules={{required: true}}
                        render={({field: {ref, ...fieldProps}}) => (
                            <FormGroup row>
                                <Label column="true" sm="4" for="beneficiaryBankAccountNumber">Benificiary Bank Account Number<BsDot color="red" /></Label>
                                <Col sm={10}>
                                    <Input 
                                        id="beneficiaryBankAccountNumber"
                                        type="text"
                                        innerRef={ref} 
                                        {...fieldProps } 
                                        className={`textInput form-control ${errors.beneficiaryBankAccountNumber ? 'is-invalid' : ''}`}/>
                                    {errors.beneficiaryBankAccountNumber && <div class="invalid-feedback">{errors.beneficiaryBankAccountNumber.type === 'required' && 'Please enter bank account number'}</div>}
                                </Col>
                            </FormGroup>
                        )}
                />
                
                <Controller id="swiftCodeIbanOrSortCode" name="swiftCodeIbanOrSortCode" 
                        control={control}
                        rules={{required: true}}
                        render={({field: {ref, ...fieldProps}}) => (
                            <FormGroup row>
                                <Label column="true" sm="4" for="swiftCodeIbanOrSortCode">SWIFT CODE / IBAN / SORT CODE<BsDot color="red" /></Label>
                                <Col sm={10}>
                                    <Input 
                                        id="swiftCodeIbanOrSortCode"
                                        type="text"
                                        innerRef={ref} 
                                        {...fieldProps } 
                                        className={`textInput form-control ${errors.swiftCodeIbanOrSortCode ? 'is-invalid' : ''}`}/>
                                    {errors.swiftCodeIbanOrSortCode && <div class="invalid-feedback">{errors.swiftCodeIbanOrSortCode.type === 'required' && 'Please enter Swift, IBAN or Sort Code.'}</div>}
                                </Col>
                            </FormGroup>
                        )}
                />
            </div>

            <div className="formGroup financeForm">
                <div className="groupTitle">Other Details</div>

                <Controller id="emailRemittanceAdvice" name="emailRemittanceAdvice" 
                        control={control}
                        rules={{required: true, pattern:  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/}}
                        render={({field: {ref, ...fieldProps}}) => (
                            <FormGroup row>
                                <Label column="true" sm="4" for="emailRemittanceAdvice">Email Address (for payment advice only)<BsDot color="red" /></Label>
                                <Col sm={10}>
                                    <Input 
                                        id="emailRemittanceAdvice"
                                        type="text"
                                        innerRef={ref} 
                                        {...fieldProps } 
                                        className={`textInput form-control ${errors.emailRemittanceAdvice ? 'is-invalid' : ''}`}/>
                                    {errors.emailRemittanceAdvice && <div class="invalid-feedback">
                                        {errors.emailRemittanceAdvice.type === 'required' && 'Please enter email address to receive remittance advices.'}
                                        {errors.emailRemittanceAdvice.type === 'pattern' && 'Please enter valid email address to receive remittance advices.'}
                                    </div>}
                                </Col>
                            </FormGroup>
                        )}
                />
                
                <Controller id="paymentTerms" name="paymentTerms" 
                        control={control}
                        rules={{required: true}}
                        render={({field: {ref, ...fieldProps}}) => (
                            <FormGroup row>
                                <Label column="true" sm="4" for="paymentTerms">Payment Terms<BsDot color="red" /></Label>
                                
                                <Col sm={10}>
                                    <Input 
                                        id="paymentTerms"
                                        type="text"
                                        innerRef={ref} 
                                        {...fieldProps } 
                                        className={`textInput form-control ${errors.paymentTerms ? 'is-invalid' : ''}`}/>
                                    {errors.paymentTerms && <div class="invalid-feedback">{errors.paymentTerms.type === 'required' && 'Please enter payment terms'}</div>}
                                    <FormText>Please specify eg: 30% Initial Deposit, 70% Prior to Shipment</FormText>
                                </Col>
                            </FormGroup>
                        )}
                />
            </div>

            <div className="footer box-shadow">
                <Button color="primary" disabled={contactBeingEdited} onClick={handleSubmit(submitFinance)}>Submit</Button>
            </div>
            </Form>
        </div>
    )
}