import React from "react"
import { FaExclamationCircle } from "react-icons/fa"
import { Button, PopoverBody, PopoverHeader, UncontrolledPopover } from "reactstrap"
import "./errorPopover.css";

export default function ErrorPopover({errorId, header, errorMessages}) {
    return <div className="errorPopover">
    <Button id={errorId} color="danger" type="button" size="small"><FaExclamationCircle /></Button>
    <UncontrolledPopover placement="left" target={errorId} trigger="focus">
        <PopoverHeader>{header}</PopoverHeader>
        <PopoverBody>
            <ul>
            {errorMessages.map((errorMessage, index) => {
                return <li key={index}> {errorMessage}</li>
            })}
            </ul>
        </PopoverBody>
    </UncontrolledPopover>
</div>
}