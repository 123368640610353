import React, { useEffect, useState } from "react";
import { BsDot } from "react-icons/bs";
import { Alert, Button, Col, Form, FormFeedback, FormGroup, FormText, Input, InputGroup, InputGroupAddon, Label } from "reactstrap";
import { BASEAPI, DEFAULT_SELECT } from "../../constants";
import { useAuthState } from "../../contexts/AuthContext";
import { useFactsState } from "../../contexts/FactsContext";
import { useServerErrorState } from "../../contexts/ServerErrorContext";
import { GetJson, GetJsonWithToken } from "../../helpers/fetch_helpers";
import { displayMoney } from "../../helpers/formatting";
import { isEmpty } from "../../helpers/utils";
import { isProductValid } from "../../helpers/validators";
import { uuidv4 } from "../Utility/guid";
import BatteryRequirementsList from "./BatteryRequirementsList";
import CartonList from "./CartonList";
import ProductImageList from "./ProductImageList";
import ProductMandatoryStandardList from "./ProductMandatoryStandardList";
import ProductTestDateList from "./ProductTestDateList";
import ProductVideoList from "./ProductVideoList";

export default function ProductEdit({product, productUpdated, cancelUpdate, isInternational}) {
    let [supplierProductId, setSupplierProductId] = useState(product ? product.supplierProductId : uuidv4());
    let [supplierId, setSupplierId] = useState(product? product.supplierId : null);
    let [itemDescription, setItemDescription] = useState(product ? product.productProperties.itemDescription : '');
    let [hamperFeatures, setHamperFeatures] = useState(product ? product.productProperties.hamperFeatures : '');
    let [brand, setBrand] = useState(product ? product.productProperties.brand : '');
    let [warrantyPeriod, setWarrantyPeriod] = useState(product ? product.productProperties.warrantyPeriod : null);
    let [ageGrade, setAgeGrade] = useState(product ? product.productProperties.ageGrade : '');
    let [forecastOverride, setForecastOverride] = useState(product ? product.forecastOverride : null);
    let [currencyCode, setCurrencyCode] = useState(product ? product.currencyCode : '');
    let [supplierRRP, setSupplierRRP] = useState(product ? product.supplierRRP : null);
    let [quotationApplicableRegion, setQuotationApplicableRegion] = useState(product ? product.quotationApplicableRegion : -1);
    let [inboundDelivery, setInboundDelivery] = useState(product ? product.inboundDelivery : -1);
    let [deliveryPort, setDeliveryPort] = useState(product && product.deliveryProperties ? product.deliveryProperties.deliveryPort : '');
    let [itemCountryId, setItemCountryId] = useState(product ? product.country : -1);
    let [leadTime, setLeadTime] = useState(product && product.deliveryProperties ? product.deliveryProperties.leadTime : null);
    let [itemContainsWood, setItemContainsWood] = useState(product ? product.productProperties.itemContainsWood : false);
    let [areBatteriesRequired, setAreBatteriesRequired] = useState(product ? product.productProperties.areBatteriesRequired : null);
    let [minOrderQuantity, setMinOrderQuantity] = useState(product && product.orderingLimits ? product.orderingLimits.minimumOrderQuantity : null);
    let [maxQuantity, setMaxQuantity] = useState(product && product.orderingLimits ? product.orderingLimits.maximumQuantity : null);
    let [notes, setNotes] = useState(product ? product.productProperties.notes : '');
    let [cartons, setCartons] = useState(product ? product.cartons : []);
    let [productTestDates, setProductTestDates] = useState(product ? product.supplierProductTestDates : []);
    let [productImages, setProductImages] = useState(product ? product.supplierProductImages : []);
    let [productVideos, setProductVideos] = useState(product ? product.supplierProductVideos : []);
    let [batteryRequirements, setBatteryRequirements] = useState(product ? product.batteryRequirements : []);
    let [mandatoryStandards, setMandatoryStandards] = useState(product ? product.supplierProductMandatoryStandards : []);
    let [isWeightRated, setIsWeightRated] = useState(product ? product.productProperties.isWeightRated : null);
    let [weightLimit, setWeightLimit] = useState(product ? product.productProperties.weightLimit : '');
    let [isTippingHazard, setIsTippingHazard] = useState(product ? product.productProperties.isTippingHazard : null);
    let [hasButtonBatteries, setHasButtonBatteries] = useState(product ? product.productProperties.hasButtonBatteries : null);

    let [currencies, setCurrencies] = useState(null);
    let [domesticCurrencies, setDomesticCurrencies] = useState(null);
    let [internationalCurrencies, setInternationalCurrencies] = useState(null);
    let [factsState, dispatchFacts] = useFactsState();
    let [serverErrorState, dispatchServerError] = useServerErrorState();
    let [authState, dispatchAuth] = useAuthState();

    var selectedCurrencies = isInternational ? internationalCurrencies : domesticCurrencies;

    let currentProduct = buildProduct();
    let isProdValid = isProductValid(currentProduct, isInternational);

    async function getCurrencies() {
        var result = await GetJsonWithToken(BASEAPI + "/api/currency/AvailableCurrencies", null, authState.token)
        .catch(function(error) {
            console.log(error);
            dispatchServerError({type: "SET_ERROR", errorTitle:"Error Uploading Supplier Product Image", errorMessage: error.message});
        });

        if (result) {
            setCurrencies(result);
        }
    }

    useEffect( () => {
        getCurrencies();
    }, [])

    useEffect( () => {
        if (currencies) {
            setInternationalCurrencies(currencies);
            var domCur = currencies.filter(x => x.trim() == "AUD" || x.trim() == "NZD");
            setDomesticCurrencies(domCur);
        }
    }, [currencies]) 

    function cartonsUpdated(cartons) {
        setCartons(cartons);
    }

    function productTestDatesUpdated(productTestDates) {
        setProductTestDates(productTestDates);
    }

    function supplierProductImagesUpdated(images) {
        setProductImages(images);
    }

    function supplierProductVideosUpdated(videos) {
        setProductVideos(videos);
    }

    function batteryRequirementsUpdated(batteryRequirements) {
        setBatteryRequirements(batteryRequirements);
    }

    function mandatoryStandardsUpdated(mandatoryStandards) {
        setMandatoryStandards(mandatoryStandards);
    }

    function buildProduct() {
        var productProperties = {
            itemDescription: itemDescription,
            hamperFeatures: hamperFeatures,
            brand: brand,
            warrantyPeriod: warrantyPeriod,
            itemContainsWood: itemContainsWood,
            areBatteriesRequired: areBatteriesRequired,
            isWeightRated: isWeightRated,
            weightLimit: weightLimit,
            isTippingHazard: isTippingHazard,
            hasButtonBatteries: hasButtonBatteries,
            ageGrade: ageGrade,
            notes: notes
        };

        var product = {
            supplierProductId: supplierProductId,
            supplierId: supplierId,
            productProperties: productProperties,
            forecastOverride: forecastOverride,
            currencyCode: currencyCode,
            supplierRRP: supplierRRP,
            inboundDeliveryId: isInternational ? inboundDelivery : 1,
            deliveryProperties: {
                deliveryPort: deliveryPort
            },
            orderingLimits: {
                minimumOrderQuantity: minOrderQuantity,
                maximumQuantity: maxQuantity,
            },
            country: parseInt(itemCountryId),
            cartons: cartons,
            supplierProductTestDates: productTestDates,
            supplierProductImages: productImages,
            supplierProductVideos: productVideos,
            batteryRequirements: batteryRequirements,
            supplierProductMandatoryStandards: mandatoryStandards,
            quotationApplicableRegion: parseInt(quotationApplicableRegion)
        }

        return product;
    }

    function update() {      
        var updatedProduct = buildProduct();

        productUpdated(updatedProduct);
    }

    function isValid() {
        if (isInternational) {
            //validate those required if international fields
            if (!deliveryPort)
                return false;

            if (!leadTime)
                return false;
            
            if (!itemCountryId)
                return false;

            if (!minOrderQuantity)
                return false;
        }

        if (!itemDescription || itemDescription.length > 37)
            return false;
        
        if (!forecastOverride)
            return false;

        if (itemContainsWood === null)
            return false;

        if (isWeightRated === null)
            return false;

        if (isTippingHazard === null)
            return false;

        if (hasButtonBatteries === null)
            return false;

        return true;
    }

    return (
        <div>
            <div>
                <div className="formGroup">
                    <div className="groupTitle">Edit Product</div>
                    <Alert color="light">Required Fields <BsDot color="red" /></Alert>
                    
                    <FormGroup row>
                        <Label column="column" sm="3" for="itemDescription"><BsDot color="red" /> Item Name</Label>
                        <Col sm="10">
                            <Input type="text" id="itemDescription" value={itemDescription} maxLength="37" className="wideInput" onChange={e => setItemDescription(e.target.value)} invalid={isEmpty(itemDescription)} />
                            <FormFeedback valid={!isEmpty(itemDescription)}>Please enter an item name</FormFeedback>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label column="column" sm="3" for="hamperFeatures">Key Selling Features</Label>
                        <Col sm="10">
                            <Input type="textarea" id="hamperFeatures" value={hamperFeatures} className="wideArea" rows="5" onChange={e => setHamperFeatures(e.target.value)} invalid={hamperFeatures && hamperFeatures.length > 300} />
                            <FormFeedback valid={hamperFeatures && hamperFeatures.length <= 300}>Hamper features {hamperFeatures ? hamperFeatures.length - 300 : 0} characters too long.</FormFeedback>
                            <FormText>Max 300 Characters</FormText>
                            <div>current length: {hamperFeatures ? hamperFeatures.length : 0}</div>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label column="column" sm="3" for="brand">Brand</Label>
                        <Col sm="10">
                            <Input type="text" id="brand" value={brand} className="wideInput" onChange={e => setBrand(e.target.value)} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label column="column" sm="3" for="warrantyPeriod">Warranty Period (days)</Label>
                        <Col sm="10">
                            <Input type="number" id="warrantyPeriod" value={warrantyPeriod} min="0" onChange={e => setWarrantyPeriod(e.target.value)} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label column="column" sm="3" for="ageGrade">Age Grade</Label>
                        <Col sm="10">
                            <FormText>If Applicable</FormText>
                            <Input type="string" id="ageGrade" value={ageGrade} onChange={e => setAgeGrade(e.target.value)} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label column="column" sm="3" for="forecastOverride"><BsDot color="red" /> Unit Cost</Label>
                        <Col sm="10">
                            <InputGroup>
                                <InputGroupAddon addonType="append">
                                    <Input type="select" id="currencyCode" value={currencyCode} onChange={e => setCurrencyCode(e.target.value)}>
                                        {selectedCurrencies && selectedCurrencies.map((currency, index) => {
                                            return <option key={index}>{currency}</option>
                                        })}
                                    </Input>
                                </InputGroupAddon>
                                <Input type="number" id="forecastOverride" value={forecastOverride} onChange={e => setForecastOverride(displayMoney(e.target.value))} min="0" step="0.01" />
                            </InputGroup>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label column="column" sm="3" for="supplierRRP">RRP</Label>
                        <Col sm="10">
                            <Input type="number" id="supplierRRP" value={supplierRRP} onChange={e => setSupplierRRP(displayMoney(e.target.value))} min="0" step="0.01" />
                            <FormText>This value must be in AUD</FormText>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label column="column" sm="3" for="quotationApplicableRegion">Quotation Applicable For</Label>
                        <Col sm="10">
                            <Input type="select" id="quotationApplicableRegion" value={quotationApplicableRegion} onChange={e => setQuotationApplicableRegion(e.target.value)}>
                                <option value="-1">{DEFAULT_SELECT}</option>
                                <option value="1">Australia</option>
                                <option value="2">New Zealand</option>
                                <option value="3">Both</option>
                            </Input>
                        </Col>
                    </FormGroup>
                    {isInternational &&
                        <>
                            <FormGroup row>
                                <Label column="column" sm="3" for="inboundDelivery">Delivery Term</Label>
                                <Col sm="10">
                                    <Input type="select" id="inboundDelivery" value={inboundDelivery} onChange={e => setInboundDelivery(e.target.value)}>
                                        <option value="-1">{DEFAULT_SELECT}</option>
                                        <option value="2">FCA</option>
                                        <option value="3">FOB</option>
                                        <option value="4">Ex-Works</option>
                                    </Input>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label column="column" sm="3" for="deliveryPort"><BsDot color="red" /> Delivery Port</Label>
                                <Col sm="10">
                                    <Input type="text" id="deliveryPort" value={deliveryPort} onChange={e => setDeliveryPort(e.target.value)} />
                                </Col>
                            </FormGroup>
                           
                        </>
                    }
                        <FormGroup row>
                                <Label column="column" sm="3" for="leadTime">{isInternational && <BsDot color="red" />}Production &amp; Delivery Lead Time (days)</Label>
                                <Col sm="10">
                                    <Input type="number" id="leadTime" value={leadTime} onChange={e => setLeadTime(e.target.value)} />
                                </Col>
                            </FormGroup>
                        <FormGroup row>
                        <Label column="column" sm="3" for="itemCountryId">{isInternational && <BsDot color="red" />}Item Country of Origin</Label>
                        <Col sm="10">
                        <Input type="select" id="itemCountryId" onChange={e => setItemCountryId(e.target.value)} value={itemCountryId}>
                            <option key="-1" value={null}>{DEFAULT_SELECT}</option>
                        {factsState && factsState.countries && factsState.countries.map((countryItem, index) => {
                            return (
                                <option key={index} value={countryItem.countryId}>{countryItem.name.toUpperCase()}</option>
                            )
                        })}
                        </Input>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label column="column" sm="3" for="minOrderQuantity">{isInternational && <BsDot color="red" />}Minimum Order Quantity</Label>
                        <Col sm="10">
                            <Input type="number" id="minumumOrderQuantity" value={minOrderQuantity} onChange={e => setMinOrderQuantity(e.target.value)} min="0" />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                    <Label column="column" sm="3" for="maxAvailableQuantity">Maximum Available Quantity</Label>
                        <Col sm="10">
                            <Input type="number" id="maxAvailableQuantity" value={maxQuantity} onChange={e => setMaxQuantity(e.target.value)} min="0" />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label check for="itemContainsWood"><BsDot color="red" /> Item Contains Wood?</Label>
                        <Col sm="10">
                            <FormText>Please specify whether item contains wood</FormText>
                            <FormGroup check>
                                <Label check>
                                    <Input type="radio" name="itemContainsWood" value={true} checked={itemContainsWood} onChange={e => setItemContainsWood(true)} />
                                    Yes
                                </Label>
                            </FormGroup>
                            <FormGroup check>
                                <Label check>
                                <Input type="radio" name="itemContainsWood" value={false} checked={itemContainsWood !== null && itemContainsWood === false} onChange={e => {setItemContainsWood(false);}} />
                                No
                                </Label>
                            </FormGroup>  
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label check for="isWeightRated"><BsDot color="red" /> Weight Rated?</Label>
                        <Col sm="10">
                            <FormText>Please specify if item has a weight rating</FormText>
                            <FormGroup check>
                                <Label check>
                                    <Input type="radio" name="isWeightRated" value={true} checked={isWeightRated} onChange={e => setIsWeightRated(true)} />
                                    Yes
                                </Label>
                            </FormGroup>
                            <FormGroup check>
                                <Label check>
                                <Input type="radio" name="isWeightRated" value={false} checked={isWeightRated !== null && isWeightRated === false} onChange={e => {setIsWeightRated(false);}} />
                                No
                                </Label>
                            </FormGroup>  
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label column="column" sm="3" for="weightLimit">Weight Limit</Label>
                        <Col sm="10">
                            <Input type="text" id="weightLimit"  maxLength="50" onChange={e => setWeightLimit(e.target.value)} value={weightLimit} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label check for="isTippingHazard"><BsDot color="red" /> Tipping Hazard?</Label>
                        <Col sm="10">
                        <FormText>Please specify if item is a tipping hazard</FormText>
                            <FormGroup check>
                                <Label check>
                                    <Input type="radio" name="isTippingHazard" value={true} checked={isTippingHazard} onChange={e => setIsTippingHazard(true)} />
                                    Yes
                                </Label>
                            </FormGroup>
                            <FormGroup check>
                                <Label check>
                                <Input type="radio" name="isTippingHazard" value={false} checked={isTippingHazard !== null && isTippingHazard === false} onChange={e => {setIsTippingHazard(false);}} />
                                No
                                </Label>
                            </FormGroup>   
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label><BsDot color="red" /> Batteries Required?</Label>
                        <Col sm="10">
                            <FormGroup check>
                                <Label check>
                                    <Input type="radio" name="batteriesRequired" value={true} checked={areBatteriesRequired} onChange={e => setAreBatteriesRequired(true)} />
                                    Yes
                                </Label>
                            </FormGroup>
                            <FormGroup check>
                                <Label check>
                                <Input type="radio" name="batteriesRequired" value={false} checked={areBatteriesRequired != null && areBatteriesRequired == false} onChange={e => {setAreBatteriesRequired(false);setHasButtonBatteries(false);}} />
                                No
                                </Label>
                            </FormGroup>                            
                        </Col>
                    </FormGroup>
                    {areBatteriesRequired && 
                    <>
                        <FormGroup row>
                        <Label><BsDot color="red" /> Has Button Batteries?</Label>
                        <Col sm="10">
                            <FormGroup check>
                                <Label check>
                                    <Input type="radio" name="hasButtonBatteries" value={true} checked={hasButtonBatteries} onChange={e => setHasButtonBatteries(true)} invalid={hasButtonBatteries === null} />
                                    Yes
                                </Label>
                                <FormFeedback valid={hasButtonBatteries !== null}>Please specify if any of the batteries are button batteries</FormFeedback>
                            </FormGroup>
                            <FormGroup check>
                                <Label check>
                                <Input type="radio" name="hasButtonBatteries" value={false} checked={hasButtonBatteries != null && hasButtonBatteries == false} onChange={e => setHasButtonBatteries(false)} />
                                No
                                </Label>
                            </FormGroup>                            
                        </Col>
                    </FormGroup>
                        <BatteryRequirementsList batteryRequirementsList={batteryRequirements} updatedBatteryRequirements={batteryRequirementsUpdated} editing={true} />
                    </>}
                   
                    <div>
                        <div className="sectionHeader">Mandatory Standards</div>
                        <ProductMandatoryStandardList supplierProductMandatoryStandards={mandatoryStandards} editing={true} supplierId={supplierId} supplierProductId={supplierProductId} updatedMandatoryStandards={mandatoryStandardsUpdated} />
                    </div>

                    <div>
                        <div className="sectionHeader">Product Sample Images</div>
                        <ProductImageList supplierProductImages={productImages} supplierId={supplierId} supplierProductId={supplierProductId} editing={true} imagesUpdated={supplierProductImagesUpdated} />
                    </div>
                    <div>
                        <div className="sectionHeader">Product Sample Videos</div>
                        <ProductVideoList supplierProductVideos={productVideos} supplierId={supplierId} supplierProductId={supplierProductId} editing={true} videosUpdated={supplierProductVideosUpdated} />
                    </div>
                    
                    <ProductTestDateList productTestDates={productTestDates} updatedProductTestDates={productTestDatesUpdated} editing={true} />
                    <CartonList cartons={cartons} updatedCartons={cartonsUpdated} allowEditing={true} isInternational={isInternational} />

                    <br />
                    <FormGroup row>
                        <Label for="notes">Notes</Label>
                        <Col sm="10">
                            <Input type="textarea" id="notes" value={notes} className="wideArea" rows="5" onChange={e => setNotes(e.target.value)} />
                        </Col>
                    </FormGroup>
                </div>
            </div>
            {!isProdValid && 
            <Alert color="danger" className="errorSummary">
                <strong>Errors</strong>
                <ul>
                    {currentProduct.errorMessages.map((error, index) =>
                    {
                        return <li key={index}>{error}</li>
                    })}
                </ul>
            </Alert>}
            <div>
                <Button color="primary" disabled={!isValid()} onClick={() => update()}>Save</Button>
                <Button onClick={() => cancelUpdate()}>Cancel</Button>
            </div>
        </div>
    )
}