import React from "react";
import { Label } from "reactstrap";
import { SizeMeasurementUnitEnum, WeightMeasurementUnitEnum } from "../../helpers/enums";
import Dimensions from "../Dimensions/Dimensions";

export default function SkuView({sku}) {
    var retailUnitDimensions = {
        height: sku.retailUnitHeight,
        width: sku.retailUnitWidth,
        length: sku.retailUnitLength,
        sizeUnit: SizeMeasurementUnitEnum.MM,
        weight: sku.unitWeight,
        weightUnit: WeightMeasurementUnitEnum.KG,
    }

    var palletDimensions = {
        height: sku.palletHeight,
        width: sku.palletWidth,
        length: sku.palletDepth,
        sizeUnit: SizeMeasurementUnitEnum.MM,
        weight: sku.palletWeight,
        weightUnit: WeightMeasurementUnitEnum.KG
    }

    var masterCartonDimensions = {
        height: sku.masterCartonHeight,
        width: sku.masterCartonWidth,
        length: sku.masterCartonLength,
        sizeUnit: SizeMeasurementUnitEnum.MM,
        weight: sku.masterCartonWeight,
        weightUnit: WeightMeasurementUnitEnum.KG
    }
    return (
        <>
        {sku && 
        <div className="productContainer">
             <div>
                <Label>Item Code</Label>
                <span>{sku.itemCode}</span>
            </div>
            <div>
                <Label>Item Description</Label>
                <span>{sku.itemDescription}</span>
            </div>
            <div>
                <Label>Unit Size</Label>
                <span>{sku.unitSize}</span>
            </div>
            {sku.businessUnitId == 1 &&
            <>
                 <div>
                <Label>Stocking Unit</Label>
                <span>{sku.stockingUnitId == 1 ? "Pack" : "Each"}</span>
            </div>
            <div>
                <Label>Stocking Quantity</Label>
                <span>{sku.stockingQty}</span>
            </div>
            </>
            }
             <div>
                <Label>Supplier Product Code</Label>
                <span>{sku.supplierProductCode}</span>
            </div>
            <div>
                <Label>Model Number</Label>
                <span>{sku.modelNumber}</span>
            </div>
            <div>
                <Label>Supplier Product Code</Label>
                <span>{sku.supplierProductCode}</span>
            </div>
            {sku.hasBarcodeGTIN13 && 
            <div>
                <Label>GTIN 13 (EAN)</Label>
                <span>{sku.barcodeGTIN13}</span>
            </div>}
            {sku.hasBarcodeGTIN14 &&
                <div>
                    <Label>GTIN 14 (EAN)</Label>
                    <span>{sku.barcodeGTIN14}</span>
                </div>
            }
             <div>
                    <Label>Minimum Order Level</Label>
                    <span>{sku.minimumOrderLevel}</span>
                </div>
                <div>
                    <Label>MOQ Units</Label>
                    <span>{sku.minimumOrderQuantity}</span>
                </div>
                <div>
                    <Label>Maximum Available Quantity</Label>
                    <span>{sku.maximumAvailableQuantity}</span>
                </div>
                <div>
                    <Label>Lead Time (Days)</Label>
                    <span>{sku.leadTime}</span>
                </div>
                <div>
                    <Label>Shelf Life (Days)</Label>
                    <span>{sku.shelfLife}</span>
                </div>
                <div>
                    <Label>Warranty Period (Days)</Label>
                    <span>{sku.warrantyPeriod}</span>
                </div>
                <div>
                    <Label>Warranty Phone #</Label>
                    <span>{sku.warrantyContactPhoneNumber}</span>
                </div>
                <div className="formGroup">
                    <div className="groupTitle">Retail Unit Details</div>
                    <div>
                        <Label>Retail Unit Dimensions</Label>
                        <Dimensions dimensions={retailUnitDimensions} />
                    </div>
                </div>
                
                <div className="formGroup">
                    <div className="groupTitle">Pallet Details</div>
                    <div>
                        <Label>Pallet Dimensions</Label> 
                        <Dimensions dimensions={palletDimensions} />
                    </div>
                    <div>
                        <Label>Cartons Per Layer</Label>
                        <span>{sku.cartonsPerLayer}</span>
                    </div>
                    <div>
                        <Label>Layers Per Pallet</Label>
                        <span>{sku.layersPerPallet}</span>
                    </div>
                </div>
                <div className="formGroup">
                    <div className="groupTitle">Carton Details</div>

                    <div>
                        <Label>Units Per Carton</Label>
                        <span>{sku.stockingQtyPerCarton}</span>
                    </div>
                    <div>
                        <Label>Carton Dimensions</Label>
                        <Dimensions dimensions={masterCartonDimensions} />
                    </div>
                </div>
        </div>
        }
        </>
    )
}