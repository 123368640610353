export var SupplierRequestTypeEnum = {
    PricingQuotes: 1,
    FinanceDetails: 2,
    Contact: 3,
    SKUs: 4,
    TradingTerm: 5,
    ProductSubmissions: 6
}

export function SupplierRequestTypeLabel(supplierRequestType) {
    switch (supplierRequestType) {
        case SupplierRequestTypeEnum.PricingQuotes:
            return "Pricing Quotes";
        case SupplierRequestTypeEnum.FinanceDetails:
            return "Finance Information";
        case SupplierRequestTypeEnum.Contact:
            return "Contact Information";
        case SupplierRequestTypeEnum.SKUs:
            return "Product Information";
        case SupplierRequestTypeEnum.TradingTerm:
            return "Trading Terms";
        case SupplierRequestTypeEnum.ProductSubmissions:
            return "Product Submissions";
        default:
            throw new Error("Could not find supplier request type: " + supplierRequestType);
    }
}

export var TradingTermEnum = {
    DamagedGoodsAllowance: 1,
    DamagedGoodsHandlingFee: 2,
    SurplusGoodsAllowance: 3,
    SettlementDiscount: 4,
    MarketingAllowance: 5,
    VolumeAllowance: 6,
    GrowthRebate: 7,
    Other: 8,
    CatalogueAllowance: 9
}

export function TradingTermLabel(tradingTerm) {
    switch(tradingTerm) {
        case TradingTermEnum.DamagedGoodsAllowance:
            return "Damaged Goods Handling Fee";
        case TradingTermEnum.DamagedGoodsHandlingFee:
            return "Damaged Goods Handling Fee";
        case TradingTermEnum.SurplusGoodsAllowance:
            return "Surplus Goods Allowance";
        case TradingTermEnum.SettlementDiscount:
            return "Settlement Discount";
        case TradingTermEnum.MarketingAllowance:
            return "Marketing Allowance";
        case TradingTermEnum.VolumeAllowance:
            return "Volume Allowance";
        case TradingTermEnum.GrowthRebate:
            return "Growth Rebate";
        case TradingTermEnum.Other:
            return "Other";
        case TradingTermEnum.CatalogueAllowance:
            return "Catalogue Allowance";
    }
}

export var TradingTermTypeEnum = {
    Fixed: 1,
    Variable: 2
}

export function TradingTermTypeLabel(tradingTermType) {
    switch(tradingTermType) {
        case TradingTermTypeEnum.Fixed:
            return "Fixed";
        case TradingTermTypeEnum.Variable:
            return "Variable";
    }
}

export var ContactTypeEnum = {
        Purchasing: 1,
        Finance: 2,
        Factory: 3,
        Warehouse: 4
}

export function ContactTypeEnumLabel(contactType) {
    switch (contactType) {
        case ContactTypeEnum.Finance:
            return "Finance";
        case ContactTypeEnum.Purchasing:
            return "Purchasing";
        case ContactTypeEnum.Factory:
            return "Factory";
        case ContactTypeEnum.Warehouse:
            return "Warehouse";
    }
}

export var QuotationApplicableRegionEnum = {
    Australia: 1,
    NewZealand: 2,
    Both: 3
}

export var QuotableApplicableRegion_SelectList = [
    {
        label: "Australia",
        value: 1
    },
    {
        label: "New Zealand",
        value: 2
    },
    {
        label: "Both",
        value: 3
    }
]

export function QuotationApplicableRegionEnumLabel(quotationRegion) {
    switch (quotationRegion) {
        case QuotationApplicableRegionEnum.Australia:
            return "Australia";
        case QuotationApplicableRegionEnum.NewZealand:
            return "New Zealand";
        case QuotationApplicableRegionEnum.Both:
            return "Both";
    }
}

export var InboundDeliveryEnum = {
    Local: 1,
    FCA: 2,
    FOB: 3,
    ExWorks: 4,
    LocalImport: 5
}

export function InboundDeliveryEnumLabel(inboundDelivery) {
    switch (inboundDelivery) {
        case InboundDeliveryEnum.Local:
            return "Local";
        case InboundDeliveryEnum.FCA:
            return "FCA";
        case InboundDeliveryEnum.FOB:
            return "FOB";
        case InboundDeliveryEnum.ExWorks:
            return "Ex-Works";
        case InboundDeliveryEnum.LocalImport:
            return "Local (Import)"
    }
}

export var SizeMeasurementUnitEnum = {
    MM: 1,
    CM: 2,
    Inch: 3,
    Meter: 4
}

export function SizeMeasurementUnitEnumLabel(sizeMeasurementUnit) {
    switch (sizeMeasurementUnit) {
        case SizeMeasurementUnitEnum.MM:
            return "mm";
        case SizeMeasurementUnitEnum.CM:
            return "cm";
        case SizeMeasurementUnitEnum.Inch:
            return "inch";
        case SizeMeasurementUnitEnum.Meter:
            return "meter";
    }
}

export var WeightMeasurementUnitEnum = {
    KG: 1,
    G: 2,
    LB: 3
}

export function WeightMeasurementUnitEnumLabel(weightMeasurementUnit) {
    switch (weightMeasurementUnit) {
        case WeightMeasurementUnitEnum.KG:
            return "Kilogram";
        case WeightMeasurementUnitEnum.G:
            return "Gram";
        case WeightMeasurementUnitEnum.LB:
            return "Pound";
    }
}

export var DocumentTypeEnum = {
    Other: 1,
    SupplierAgreement: 2,
    ModernSlaveryStatement: 3,
    MandatoryStandard: 4
}

export var ProductTestType = {
     ISO8124: 1,
     EN71: 2,
     EMC: 3
}

export function ProductTestTypeEnumLabel(productTestType) {
    switch (productTestType) {
        case ProductTestType.ISO8124:
            return 'ISO8124';
        case ProductTestType.EN71:
            return 'EN71';
        case ProductTestType.EMC:
            return 'EMC';
    }
}

export var OrderLevelEnum = {
    Unit: 1,
    Carton: 2,
    Layer: 3,
    Pallet: 4,
    MOQ: 5
}

export function OrderLevelEnumLabel(orderLevel) {
    switch (orderLevel) {
        case OrderLevelEnum.Unit :
            return 'Unit';
        case OrderLevelEnum.Carton:
            return 'Carton';
        case OrderLevelEnum.Layer:
            return 'Layer';
        case OrderLevelEnum.Pallet:
            return 'Pallet';
        case OrderLevelEnum.MOQ:
            return 'MOQ';
    }
}

export var StockingUnitEnum = {
    PK: 1,
    EA: 2
}

export function StockingUnitEnumLabel(stockingUnit) {
    switch (stockingUnit) {
        case StockingUnitEnum.PK:
            return 'Pack';
        case StockingUnitEnum.EA:
            return 'Each';
    }
}

export var BusinessUnitEnum = {
    AU: 1,
    NZ: 2
}

export function BusinessUnitEnumLabel(businessUnit) {
    switch (businessUnit) {
        case BusinessUnitEnum.AU:
            return 'Australia';
        case BusinessUnitEnum.NZ:
            return 'New Zealand';
    }
}

export var VideoUploadType = {
    File: 1,
    Link: 2
}

export var SkuReviewStatusType = {
    Pending: 1,
    Completed: 2
}

export function SkuReviewStatusLabel(skuReviewStatus) {
    switch (skuReviewStatus) {
        case SkuReviewStatusType.Pending:
            return "Pending";
        case SkuReviewStatusType.Completed:
            return "Completed";
    }
}