/* eslint-disable default-case */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { BASEAPI } from "../../constants";
import { useAuthState } from "../../contexts/AuthContext";
import { useServerErrorState } from "../../contexts/ServerErrorContext";
import { GetJsonWithToken } from "../../helpers/fetch_helpers";
import ProgressBar from "../Utility/ProgressBar";
import GeneralInfo from "./Submissions/GeneralInfo";
import "./ProductSubmissions.css";
import ContactInfo from "./Submissions/ContactInfo";
import { ContactTypeEnum } from "../../helpers/enums";
import ProductInfo from "./Submissions/ProductInfo";
import SubmitProducts from "./Submissions/SubmitProducts";
import { Spinner } from "reactstrap";
import { sub } from "date-fns";

export var SubmissionStepEnum = {
    General: 1,
    ProcurementContact: 2,
    WarehouseContact: 3,
    FactoryDetails:4,
    Products: 5,
    Submit: 6
}

const domesticProgressLabels = ["General", "Procurement Contact", "Warehouse Contact", "Products", "Submit"];
const internationalProgressLabels = ["General", "Procurement Contact", "Factory Details", "Products", "Submit"];

export default function ProductSubmissions() {
    let { requestId } = useParams();
    let [submissionModel, setSubmissionModel] = useState();
    let [serverErrorState, dispatchServerError] = useServerErrorState();
    let [authState, dispatchAuth] = useAuthState();
    let [currentStep, setCurrentStep] = useState(SubmissionStepEnum.General); //useState(SubmissionStepEnum.General);
    let [loading, setLoading] = useState(false);

    var isInternational = submissionModel ? submissionModel.products.isInternational : false;

    var progressLabels = isInternational ? internationalProgressLabels : domesticProgressLabels;

    async function getSubmissionModel() {
        const uri = BASEAPI + "/api/submission/" + requestId;
        var result = await GetJsonWithToken(uri, null, authState.token)
        .catch(function(error){
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error retrieving submission model", errorMessage: error.message});
        })
        .finally(() => setLoading(false));

        setSubmissionModel(result);        
    }

    useEffect(() => {
        setLoading(true);
        getSubmissionModel();
    }, [authState.user, requestId])

    function nextStep() {
        var nextValue = currentStep + 1;
        if (isInternational && currentStep == SubmissionStepEnum.ProcurementContact)
            nextValue = nextValue + 1;
        if (!isInternational && currentStep == SubmissionStepEnum.WarehouseContact)
            nextValue = nextValue + 1;

        setCurrentStep(nextValue);
    }

    function prevStep() {
        var prevValue = currentStep - 1;
        if (isInternational && currentStep == SubmissionStepEnum.FactoryDetails)
            prevValue = prevValue - 1;
        if (!isInternational && currentStep == SubmissionStepEnum.Products)
            prevValue = prevValue - 1;
        setCurrentStep(prevValue);
    }

    function generalInfoUpdated(newGeneralInfo) {
        updateSection(SubmissionStepEnum.General, newGeneralInfo);
    }

    function contactInfoUpdated(contactType, newContactInfo) {
        switch (contactType) {
            case ContactTypeEnum.Factory:
                updateSection(SubmissionStepEnum.FactoryDetails, newContactInfo);
                break;
            case ContactTypeEnum.Warehouse:
                updateSection(SubmissionStepEnum.WarehouseContact, newContactInfo);
                break;
            case ContactTypeEnum.Purchasing:
                updateSection(SubmissionStepEnum.ProcurementContact, newContactInfo);
                break;
        }
    }

    function productInfoUpdated(newProductInfo) {
        updateSection(SubmissionStepEnum.Products, newProductInfo);
    }

    function updateSection(submissionStep, sectionInfo) {

        var model = submissionModel;

        switch (submissionStep) {
            case SubmissionStepEnum.General:
                model.generalInfo = sectionInfo;
                break;
            case SubmissionStepEnum.ProcurementContact:
                model.procurementContacts = sectionInfo;
                break;
            case SubmissionStepEnum.WarehouseContact:
                model.warehouseContacts = sectionInfo;
                break;
            case SubmissionStepEnum.FactoryDetails:
                model.factoryContacts = sectionInfo;
                break;
            case SubmissionStepEnum.Products:
                model.products = sectionInfo;
                break;
        }

        setSubmissionModel(model);
    }

    function getCurrentView() {
        switch(currentStep) {
            case SubmissionStepEnum.General:
                return submissionModel && submissionModel.general ? <GeneralInfo requestId={requestId} generalInfo={submissionModel.general} nextStep={nextStep} generalInfoUpdated={generalInfoUpdated} /> : null
            case SubmissionStepEnum.ProcurementContact:
                return submissionModel && submissionModel.procurementContacts ? <ContactInfo requestId={requestId} contactInfo={submissionModel.procurementContacts} contactType={ContactTypeEnum.Purchasing} nextStep={nextStep} prevStep={prevStep} contactInfoUpdated={contactInfoUpdated} /> : null
            case SubmissionStepEnum.WarehouseContact:
                return submissionModel && submissionModel.warehouseContacts ? <ContactInfo requestId={requestId} contactInfo={submissionModel.warehouseContacts} contactType={ContactTypeEnum.Warehouse} nextStep={nextStep} prevStep={prevStep} contactInfoUpdated={contactInfoUpdated} /> : null
            case SubmissionStepEnum.FactoryDetails:
                return submissionModel && submissionModel.factoryContacts ? <ContactInfo requestId={requestId} contactInfo={submissionModel.factoryContacts} contactType={ContactTypeEnum.Factory} nextStep={nextStep} prevStep={prevStep} contactInfoUpdated={contactInfoUpdated} /> : null;
            case SubmissionStepEnum.Products:
                return submissionModel && submissionModel.products ? <ProductInfo requestId={requestId} productInfo={submissionModel.products} nextStep={nextStep} prevStep={prevStep} productInfoUpdated={productInfoUpdated} isInternational={isInternational} /> : null
            case SubmissionStepEnum.Submit:
                return submissionModel ? <SubmitProducts requestId={requestId} prevStep={prevStep} /> : null;
        }
    }

    return (
            <div>
                <ProgressBar labels={progressLabels} currentStepNo={currentStep} />
                <div className="form-wrapper">
                    {loading ? <Spinner /> : getCurrentView()}
                </div>
            </div>
    )
}