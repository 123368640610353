export function convertAddress(address) {
    if (!address)
        return null;

    var convertedAddress = {
        addressId: address.addressId,
        addressType: address.addressType,
        addressLine1: address.addressLine1,
        addressLine2: address.addressLine2,
        country: address.country ? parseInt(address.country) : null,
        postCode: address.postCode,
        state: address.state ? parseInt(address.state) : null,
        suburb: address.suburb
    }

    return convertedAddress;
}