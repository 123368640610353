import React, { useState } from 'react';
import './Login.css';
import { BASEAPI } from "../../constants";
import { handleErrors, PostJson } from '../../helpers/fetch_helpers';
import { useServerErrorState } from '../../contexts/ServerErrorContext';
import { useAuthState } from '../../contexts/AuthContext';
import logo from '../../images/Chrisco_logo_underline_reg.png';
import { Button, FormFeedback, Input } from 'reactstrap';

export default function Login() {
    const [username, setUserName] = useState();
    const [password, setPassword] = useState();
    let [authState, dispatchAuth] = useAuthState();
    let [errorState, dispatchServerError] = useServerErrorState();

    async function handleSubmit(e) {
        e.preventDefault();
        
        if (username === undefined)
        {
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error Logging In", errorMessage: "Please enter username"});
            e.preventDefault();
            return false;
        }

        if (password === undefined)
        {
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error Logging In", errorMessage: "Please enter password"});
            e.preventDefault();
            return false;
        }

        try
        {
            var result = await loginUser({username, password})
            var token = result.token;
            var user = result.user;

            console.log("token = " + token);
            dispatchAuth({type: "SET_TOKEN", token: token});

            console.log("user = " + JSON.stringify(user));
            dispatchAuth({type: "SET_USER", user: user});

            if (!user.isStaff && user.supplierId) {
                dispatchAuth({type: "SET_SUPPLIER", supplierId: user.supplierId});
            }
        }
        catch (err)
        {
             console.log(err);
        }

        return true;
    }

    async function loginUser(credentials)
    {
        const uri = BASEAPI + "/api/account/signin"
        console.log('fetching: ' + uri)

        var args = {
            email: credentials.username,
            password: credentials.password
        };

        var result = await PostJson(uri, args)
        .catch(function(error){
                console.log(error);
                dispatchServerError({type: "SET_ERROR",errorTitle:"Error Logging In", errorMessage: error.message});
        })

        return result;
    }

    return (
        <>
            <div className="logo-wrapper">
                <img src={logo} className="logo" /> Supplier Portal
            </div>
            <div className="login-wrapper">
                <h3>Please Log In</h3>
                {/* <div className="login-form"> */}
                    <form onSubmit={handleSubmit} inline>
                        <label>
                            <p>Username</p>
                            <Input type="text" className="login-field" onChange={e => setUserName(e.target.value)} invalid={username && username.length <= 0} />
                            <FormFeedback valid={!username || username.length == 0}>Please user email address</FormFeedback>
                        </label>
                        <label>
                            <p>Password</p>
                            <Input type="password" className="login-field" onChange={e => setPassword(e.target.value)} invalid={password && password.length <= 0} />
                            <FormFeedback valid={!password || password.length == 0}>Please enter password</FormFeedback>
                        </label>
                        <div>
                            <Button>Login</Button>
                        </div>
                    </form>
                {/* </div> */}
                <div className="forgotpwd">
                    <Button color="dark" href="/forgotPwd">Forgot Password</Button>
                </div>
            </div>
           
        </>
    )
}