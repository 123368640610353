import React from "react"
import { Label, Row, Col } from "reactstrap"
import { SizeMeasurementUnitEnumLabel, WeightMeasurementUnitEnumLabel } from "../../helpers/enums"

export default function Dimensions({dimensions}) {
    
    return (
        <>
            {dimensions &&
            <div>
                <Row>
                    <Col>H: {dimensions.height}</Col>
                    <Col>W: {dimensions.width}</Col>
                    <Col>L: {dimensions.length}</Col>
                    <Col>{SizeMeasurementUnitEnumLabel(dimensions.sizeUnit)}</Col>
                </Row>
                <Row>
                    <Col>Weight: {dimensions.weight}</Col>
                    <Col>{WeightMeasurementUnitEnumLabel(dimensions.weightUnit)}</Col>
                    <Col></Col>
                    <Col></Col>
                </Row>
            </div>
            }
        </>
    )
}