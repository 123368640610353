import React, { useEffect, useState } from "react";
import { FaAngleDoubleDown, FaAngleUp, FaFacebook, FaInstagramSquare } from "react-icons/fa";
import { useHistory } from "react-router";
import Select from "react-select";
import { Button, Card, CardBody, CardTitle, Col, Collapse, Container, FormText, Row } from "reactstrap";
import { BASEAPI } from "../../constants";
import { GetJson } from "../../helpers/fetch_helpers";
import logo from '../../images/DacLogoInverted.png';
import "./BoxContent.css";

export default function HamperContentsHeader({year, businessUnit}) {
    var history = useHistory();
    const isAu = businessUnit.toLowerCase() === "au";

    let [selectHamper, setSelectHamper] = useState(false);
    let [hamperCodes, setHamperCodes] = useState();
    let [hamperCode, setHamperCode] = useState();

    const toggleHamperSelect = () => setSelectHamper(!selectHamper);

    const getHamperList = async () => {
        var uri = BASEAPI + "/api/boxContents/GetHamperList/" + year + "/" + businessUnit
        var result = await GetJson(uri)
        .catch(function(error){
            console.log(error);
        })

        setHamperCodes(result);
    }

    const buildHamperOptions = () => {
        var options = hamperCodes && hamperCodes.length > 0 ? hamperCodes.map((hamperCode, index) => {
            return { label: hamperCode.hamperName + " [" + hamperCode.hamperCode + "]", value: hamperCode.hamperCode}
        }) : [];

        return options;
    }

    const onHamperChange = (e) => {
        if (e) { //ignore if it is being cleared
        var val = e.value;
        setHamperCode(val);
        }
    }

    const transferHamper = () => {
        if (hamperCode !== null) {
            history.push("/hamperContents/" + year + "/" + businessUnit + "/" + hamperCode);
        }
    }

    useEffect(() => {
        //todo - get hamper list for year
        getHamperList();
    }, [])

    return (
        <Container fluid>
            <Row className="contentsBanner">
                <Col className="float-start contentsBannerTitle">Hamper Contents List</Col>
                <Col> <div className="float-right"><img src={logo} alt="Chrisco Hampers" className="contentsBannerLogo" /> </div></Col>
            </Row>
            <Row>
                <Col>
                    <Button size="sm" color="secondary" className="anotherHamperBtn" onClick={toggleHamperSelect} >Select Another Hamper {selectHamper ? <FaAngleUp /> : <FaAngleDoubleDown />}</Button>
                </Col>
                <Col><div className="float-right contentHeaderSubtitle">{isAu ? 'www.chrisco.com.au' : 'www.chrisco.co.nz'} | {isAu ? '1800 830 830' : '0800 555 333'}</div></Col>
            </Row>
            <Row>
                <Col> 
                </Col>
                <Col><div className="float-right contentHeaderSubtitle"><FaInstagramSquare />{isAu ? '@chrisco.au' : '@chrisco.nz'} <FaFacebook />{isAu ? '/chrisco.au' : '/chrisco.newzealand'}</div></Col>
            </Row>
            {selectHamper &&
            <Row>
                <Col sm="6">
                    <Collapse isOpen={selectHamper}>
                        <Card color="danger" outline>
                            <CardBody>
                                <CardTitle tag="h5">Select another Hamper from List</CardTitle>
                                <Container>
                                    <Row>
                                        <Col xs="10">
                                            <Select className="hamperSearch" options={buildHamperOptions()} name="hamperCodes" placeholder="Choose a Hamper" isSearchable={true} isClearable={true} onChange={onHamperChange} />
                                            <FormText>Filter results by typing in the selection box.</FormText>
                                        </Col>
                                        <Col xs="2">
                                            <Button color="primary" onClick={transferHamper}>Go</Button>
                                        </Col>
                                    </Row>
                                </Container>
                            </CardBody>
                        </Card>
                    </Collapse>
                </Col>
            </Row>}
        </Container>
    )
}