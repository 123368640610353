import React, { useState } from "react"
import { Alert, Button, FormFeedback, FormGroup, Input, Label, Spinner } from "reactstrap";
import { BASEAPI } from "../../constants";
import { useAuthState } from "../../contexts/AuthContext";
import { useServerErrorState } from "../../contexts/ServerErrorContext";
import { PostJson, PostJsonWithToken } from "../../helpers/fetch_helpers";
import logo from '../../images/Chrisco_logo_underline_reg.png';
import "./Login.css";

export default function ChangePassword() {
    let [existingPwd, setExistingPwd] = useState();
    let [newPwd, setNewPwd] = useState();
    let [newPwdConfirm, setNewPwdConfirm] = useState();
    let [authState, dispatchAuth] = useAuthState();

    let [serverError, dispatchServerError] = useServerErrorState();
    let [success, setSuccess] = useState(false);
    let [submitting, setSubmitting] = useState(false);

    function isValid() {
        if (!existingPwd || !newPwd || !newPwdConfirm)
            return false;
       
        if (newPwd === existingPwd)
            return false;
        
        if (newPwd !== newPwdConfirm)
            return false;

        return true;
    }

    async function submitChangePwd() {
        setSubmitting(true);

        var uri = BASEAPI + "/api/account/ChangePassword";
        var data = {
            existingPassword: existingPwd,
            newPassword: newPwd
        }
        var result = await PostJsonWithToken(uri, data, authState.token)
        .catch(function(error){
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error submitting change password", errorMessage: error.message});
        })
        .finally(() => setSubmitting(false))

        if (result) {
            setSuccess(true);
        }
    }

    return (
        <>
           <div className="changePwd-wrapper">
               <h3>Change Password</h3>

               <div className="change-pwd-form">
                   <FormGroup>
                       <Label for="existingPwd">Current Password</Label>
                       <Input className="change-pwd-field" type="password" name="existingPwd" id="existingPwd" value={existingPwd} onChange={e => setExistingPwd(e.target.value)} invalid={!existingPwd} valid={existingPwd} />
                       <FormFeedback>
                           Please enter existing password.
                       </FormFeedback>
                   </FormGroup>

                   <FormGroup>
                       <Label for="newPwd">New Password</Label>
                       <Input className="change-pwd-field" type="password" name="newPwd" id="newPwd" value={newPwd} onChange={e => setNewPwd(e.target.value)} invalid={!newPwd} valid={newPwd} />
                       <FormFeedback>
                           Please enter existing password.
                       </FormFeedback>
                   </FormGroup>

                   <FormGroup>
                       <Label for="newPwd">Confirm New Password</Label>
                       <Input className="change-pwd-field" type="password" name="newPwdConfirm" id="newPwdConfirm" value={newPwdConfirm} onChange={e => setNewPwdConfirm(e.target.value)} invalid={!newPwdConfirm || (newPwdConfirm != newPwd)} valid={newPwdConfirm && (newPwdConfirm === newPwd)} />
                       <FormFeedback>
                           Please confirm existing password and make sure it matches.
                       </FormFeedback>
                   </FormGroup>

                   <Button disabled={!isValid() || submitting} onClick={submitChangePwd}>Change Password</Button>
               </div>

               {submitting && <Spinner color="dark" />}

               {success &&
               <Alert color="success">
                   Successfully changed password.
               </Alert>}
           </div>
       </>
    )
}