import React, { useEffect, useState } from "react";
import { CustomInput, FormGroup, Input, InputGroup, InputGroupAddon, Label } from "reactstrap";

export default function SkuQuote({skuQuote, quoteUpdated}) {
    let [gst, setGst] = useState(skuQuote ? skuQuote.isPurchaseGstExempted : null);
    let [rrp, setRRP] = useState(skuQuote ? skuQuote.supplierRRP : '');
    let [quotedPrice, setQuotedPrice] = useState(skuQuote ? skuQuote.supplierUnitCostPricePending : '');

    function updateQuote() {
        skuQuote.isPurchaseGstExempt = gst;
        skuQuote.supplierRRP = rrp;
        skuQuote.supplierUnitCostPricePending = quotedPrice;
        
        quoteUpdated(skuQuote);
    }

    useEffect(() => {
        if (skuQuote.isPurchaseGstExempted !== gst || skuQuote.supplierRRP !== rrp || skuQuote.supplierUnitCostPricePending !== quotedPrice)
            updateQuote();
    }, [gst, rrp, quotedPrice])

    return (
        <tr>
            <td>{skuQuote.itemCode}</td>
            <td>{skuQuote.itemDescription}</td>
            <td>{skuQuote.unitWeight}</td>
            <td>{skuQuote.supplierProductCode}</td>
            <td>{skuQuote.sizeUnit}</td>
            <td>
                Yes<input type="radio" name={skuQuote.skuId + "_gst"} value={true} checked={gst === true} onChange={e => setGst(true)} />
                No<input type="radio"  name={skuQuote.skuId + "_gst"} value={false} checked={gst !== null && gst === false} onChange={e => setGst(false)} />
                    {/* <CustomInput type="radio" name="gst" value={true} checked={gst === true} onChange={e => setGst(true)} label="Yes" /> */}
                    {/* <CustomInput type="radio" name="gst" value={false} checked={gst === false} onChange={e => setGst(false)} label="No" /> */}
            </td>
            <td>
                <InputGroup>
                    <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                    <Input type="number" name="rrp" value={rrp} onChange={e => setRRP(e.target.value)} />
                </InputGroup>
            </td>
            <td>
                <InputGroup>
                    <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                    <Input type="number" name="quotedPrice" value={quotedPrice} onChange={e => setQuotedPrice(e.target.value)} />
                </InputGroup>
            </td>
        </tr>
    )
}