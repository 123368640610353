import React, { useEffect, useRef, useState } from "react"
import { FaEdit, FaEllipsisH, FaExclamationCircle, FaFileDownload, FaFileImport, FaPlus } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { Alert, Badge, Button, Col, Container, Input, Modal, ModalBody, ModalFooter, ModalHeader, PopoverBody, PopoverHeader, Row, UncontrolledPopover } from "reactstrap"
import ProductEdit from "../../Product/ProductEdit";
import ProductList from "../../Product/ProductList"
import ProductView from "../../Product/ProductView";
import { uuidv4 } from "../../Utility/guid";

import "../../Product/product.css";
import { BASEAPI } from "../../../constants";
import { GetJsonWithToken, PostFormWithToken, PostJsonWithToken } from "../../../helpers/fetch_helpers";
import { useAuthState } from "../../../contexts/AuthContext";
import { useServerErrorState } from "../../../contexts/ServerErrorContext";
import { enIN } from "date-fns/locale";

import 'react-tabulator/lib/styles.css';
import 'react-tabulator/lib/css/tabulator.min.css';
import { reactFormatter, ReactTabulator } from 'react-tabulator';
import { useFactsState } from "../../../contexts/FactsContext";
import { QuotableApplicableRegion_SelectList } from "../../../helpers/enums";
import { isBatteryRequirementValid, isCartonValid, isProductTestValid, isProductValid } from "../../../helpers/validators";
import ErrorPopover from "../../Utility/ErrorPopover";

export default function ProductInfo({requestId, productInfo, nextStep, prevStep, isInternational, productInfoUpdated}) {
    let [productList, setProductList] = useState();
    let [editProduct, setEditProduct] = useState(false);
    let [currentProduct, setCurrentProduct] = useState();
    let [newProductId, setNewProductId] = useState(null);

    let [showProductImportModal ,setShowProductImportModal] = useState(false);
    let [showProductImportReturnModal, setShowProductImportReturnModal] = useState(false);
    let [showProductImportCartonModal, setShowProductImportCartonModal] = useState(false);
    let [showProductImportBatteryModal, setShowProductImportBatteryModal] = useState(false);
    let [showProductImportProductTestModal, setShowProductImportProductTestModal] = useState(false);
    let [file, setFile] = useState();
    let [importingProducts, setImportingProducts] = useState();
    let [updatedImportingProducts, setUpdatedImportingProducts] = useState();
    let [currentSupplierProductId, setCurrentSupplierProductId] = useState();
    let [currentRow, setCurrentRow] = useState();
    let [currentCartons, setCurrentCartons] = useState();
    let [currentBatteryRequirements, setBatteryRequirements] = useState();
    let [currentProductTests, setCurrentProductTestDates] = useState();

    let [currencies, setCurrencies] = useState();

    let [factsState, dispatchFacts] = useFactsState();
    let [authState, dispatchAuth] = useAuthState();
    let [serverErrorState, dispatchServerError] = useServerErrorState();

    let countryList = factsState && factsState.countries ? factsState.countries.map((country, index) => {
        return {
            label: country.name.toUpperCase(),
            value: country.countryId
        } 
    }) : [];

    let currencyList = currencies ? currencies.map((currency, index) => {
        return {
            label: currency.trimEnd(),
            value: currency.trimEnd()
        }
    }) : [];

    let sizeList = [{label: 'mm', value: 1}, {label: 'cm', value: 2}];
    let weightList = [{label: 'kg', value: 1}, { label: 'g', value: 2}];

    async function getCurrencies() {
        var result = await GetJsonWithToken(BASEAPI + "/api/currency/AvailableCurrencies", null, authState.token)
        .catch(function(error) {
            console.log(error);
            dispatchServerError({type: "SET_ERROR", errorTitle:"Error Uploading Supplier Product Image", errorMessage: error.message});
        });

        if (result) {
            setCurrencies(result);
        }
    }

    useEffect( () => {
        getCurrencies();
    }, [])

    useEffect(() => {
        if (productInfo.products && productInfo.products.length > 0) {
            productInfo.products.forEach(prod => {
                isProductValid(prod);
            })
        }
        setProductList(productInfo.products);
    }, [productInfo]);

    function sortItems(a,b) {
        if (a.productProperties.itemDescription.toLowerCase() < b.productProperties.itemDescription.toLowerCase()) {
            return -1;
        }
        if (a.productProperties.itemDescription.toLowerCase() > b.productProperties.itemDescription.toLowerCase()) {
            return 1;
        }
        return 0;        
    }

    function addProduct() {
        var supplierId = authState.user.isStaff ? authState.supplierId : authState.user.supplierId;

        var newProduct = 
        {
            supplierProductId: uuidv4(),
            supplierId: supplierId, 
            currencyCode: isInternational ? "USD" : "AUD",
            productProperties: { itemDescription: 'New Product'}, 
            supplierProductTestDates: [],
            supplierProductImages: [],
            supplierProductVideos: [],
            batteryRequirements: []
        };
        var products = productList.concat(newProduct);
        products.sort(sortItems);
        setProductList(products);
        setNewProductId(newProduct.supplierProductId);
    }

    function selectedProductChange(productId) {
            //set selected product for form
            var product = productList.find(x => x.supplierProductId == productId);
            if (product) {
                setCurrentProduct(product);
            }
    }

    function convertProductInfo() {
        var productInfo = {
            products: productList
        }

        return productInfo;
    }

    async function saveAndContinue() {
        //todo save progress of products
        var uri = BASEAPI + "/api/submission/SaveProductInfo";
        var data = {
            supplierRequestId: requestId,
            productInformation: convertProductInfo(),
        }
        var result = await PostJsonWithToken(uri, data, authState.token)
        .catch(function(error){
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error saving product information", errorMessage: error.message});
        })

        if (result) {
            productInfoUpdated(convertProductInfo())
            nextStep();
        }
    }

    function productUpdated(product) {
        isProductValid(product);

        var ind = productList.findIndex(x => x.supplierProductId === product.supplierProductId);
        if (ind >= 0) {
            productList[ind] = product;
            productList.sort(sortItems);
            setProductList(productList);
        }
        else {
            var prods = productList.concat(product);
            prods.sort(sortItems);
            setProductList(prods);
        }

        setEditProduct(false);
        setCurrentProduct(product);

        recordProgress();
    }

    async function recordProgress() {
        var uri = BASEAPI + "/api/submission/SaveProductInfo";
        var data = {
            supplierRequestId: requestId,
            productInformation: convertProductInfo(),
        }
        var result = await PostJsonWithToken(uri, data, authState.token)
        .catch(function(error){
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error saving product information", errorMessage: error.message});
        })

        if (result) {
            productInfoUpdated(convertProductInfo())
        }
    }

    function cancelProductUpdate() {
        setEditProduct(false);
    }

    function productsUpdated(products) {
        if (products && products.length > 0) {
            products.forEach(prod => {
                isProductValid(prod);
            })
        }
        setProductList(products);

        recordProgress();
    }

    function showProductImport() {
        setShowProductImportModal(true);
    }

    function productFileChanged() {
        var fileInput = document.querySelector('#productFile');
        setFile(fileInput.files[0]);
    }

    async function uploadProductFile() {
        var fileInput = document.querySelector('#productFile');

        const data = new FormData();
        data.append('uploadedFile', fileInput.files[0]);
        data.append('SupplierRequestId', requestId);
        data.append('IsInternational', isInternational)

        var uri = BASEAPI + "/api/submission/ImportProducts";

        var result = await PostFormWithToken(uri, data, authState.token)
        .catch(function(error) {
            console.log(error);
            dispatchServerError({type: "SET_ERROR", errorTitle:"Error Uploading Submission Products", errorMessage: error.message});
        })

        if (result) {
            result.forEach(product => {
                isProductValid(product,isInternational);
            });

            setImportingProducts(result);
            setUpdatedImportingProducts(result);
            setShowProductImportModal(false);
            setShowProductImportReturnModal(true);
        }
    }

    function confirmUploadedProducts() {
        var updatedProducts = productList.concat(importingProducts);

        var firstId = importingProducts[0].supplierProductId;

        updatedProducts.sort(sortItems);
        setProductList(updatedProducts);
        setNewProductId(firstId);

        recordProgress();

        setShowProductImportModal(false);
        setShowProductImportReturnModal(false);
        setImportingProducts([]);
    }

    function cancelUpload() {
        setShowProductImportModal(false);
        setShowProductImportReturnModal(false);
        setImportingProducts([]);
    }

    function showBatteryRequirements(row, supplierProductId, batteryReqirements) {
        setCurrentRow(row);
        setCurrentSupplierProductId(supplierProductId);
        setBatteryRequirements(batteryReqirements);
        setShowProductImportBatteryModal(true);
    }

    function closeBatteryModal() {
        setShowProductImportBatteryModal(false);

        //also update the underlying product row
        currentRow.updateData(currentRow.getData());
    }

    function showProductTests(row, supplierProductId, prodTests) {
        setCurrentRow(row);
        setCurrentSupplierProductId(supplierProductId);
        setCurrentProductTestDates(prodTests);
        setShowProductImportProductTestModal(true);
    }

    function closeProductTests() {
        setShowProductImportProductTestModal(false);
        
        //also update the underlying product row
        currentRow.updateData(currentRow.getData());
    }

    function showCartons(row, supplierProductId, cartons) {
        setCurrentRow(row);
        setCurrentSupplierProductId(supplierProductId);
        setCurrentCartons(cartons);
        setShowProductImportCartonModal(true);
    }

    function closeCartonModal() {
        setShowProductImportCartonModal(false);
        
        //also update the underlying product row
        currentRow.updateData(currentRow.getData());
    }

    function tabulator_getSelectCellLabel(cell) {
        let cgv = cell.getValue();
        let values = cell?._cell?.column?.definition?.editorParams?.values;

        if (cgv && values && values.length) {    
            for (const v of values) {
                if (v?.value == cgv && v?.label) { return v.label;}
            }
        }

        return '';
    }

    function BatteryButton(props) {
        const row = props.cell._cell.row;
        const rowData = row.data;
        const batteries = rowData.batteryRequirements;

        const allValid = batteries.every(x => x.isValid);
        var errorId = "errorBatteryRequirementsPopover" + rowData.supplierProductId;

        return <>
        {!allValid && <><Button id={errorId} color="danger" type="button"><FaExclamationCircle /></Button>
        <UncontrolledPopover placement="left" target={errorId} trigger="focus">
            <PopoverHeader>Battery Requirements Errors</PopoverHeader>
            <PopoverBody>
               Battery Requirements contain errors.  Open Battery Requirement view to remedy issues.
            </PopoverBody>
        </UncontrolledPopover></>}
        <Button onClick={() => {showBatteryRequirements(row, rowData.supplierProductId, batteries)}} size="small" color="secondary">Edit <FaEdit /></Button></>
    }

    function ProductTestButton(props) {
        const row = props.cell._cell.row;
        const rowData = row.data;
        const prodTests = rowData.supplierProductTestDates;

        const allValid = prodTests.every(x => x.isValid);
        var errorId = "errorTestDatePopover" + rowData.supplierProductId;
        
        return <>
        {!allValid && <><Button id={errorId} color="danger" type="button"><FaExclamationCircle /></Button>
        <UncontrolledPopover placement="left" target={errorId} trigger="focus">
            <PopoverHeader>Product Test Date Errors</PopoverHeader>
            <PopoverBody>
               Product Test Dates contain errors.  Open product test view to remedy issues.
            </PopoverBody>
        </UncontrolledPopover></>}
        <Button onClick={() => {showProductTests(row, rowData.supplierProductId, prodTests)}} size="small" color="secondary">Edit <FaEdit /></Button>
        </>
    }

    function CartonButton(props) {
        const row = props.cell._cell.row;
        const rowData = row.data;
        const cartons = rowData.cartons;    

        const allValid = cartons.every(x => x.isValid);
        var errorId = "errorCartonPopover" + rowData.supplierProductId;

        return <>
            {!allValid && <><Button id={errorId} color="danger" type="button"><FaExclamationCircle /></Button>
                    <UncontrolledPopover placement="left" target={errorId} trigger="focus">
                        <PopoverHeader>Carton Errors</PopoverHeader>
                        <PopoverBody>
                           Cartons contain errors.  Open carton view to remedy issues.
                        </PopoverBody>
                    </UncontrolledPopover></>}
            <Button onClick={() => {showCartons(row, rowData.supplierProductId, cartons)}} size="small" color="secondary">Edit <FaEdit /></Button></>
    }

    function ErrorMessages(props) {
        const rowData = props.cell._cell.row.data;
        const errorMessages = rowData.errorMessages;

        if (errorMessages && errorMessages.length > 0) {
            var errorId = "productErrorPopover" + rowData.supplierProductId;

            return <ErrorPopover errorId={errorId} header="Product Errors" errorMessages={errorMessages} />
        }
        else {
            return '';
        }
    }

    function CartonErrorMessages(props) {
        const rowData = props.cell._cell.row.data;
        const errorMessages = rowData.errorMessages;

        if (errorMessages && errorMessages.length > 0) {
            var errorId = "cartonErrorPopover" + rowData.cartonId;

            return <ErrorPopover errorId={errorId} header="Carton Errors" errorMessages={errorMessages} />
        }
        else {
            return '';
        }
    }

    function TestDatesErrorMessages(props) {
        const rowData = props.cell._cell.row.data;
        const errorMessages = rowData.errorMessages;

        if (errorMessages && errorMessages.length > 0) {
            var errorId = "testDatesErrorPopover" + rowData.cartonId;

            return <ErrorPopover errorId={errorId} header="Product Test Date Errors" errorMessages={errorMessages} />
        }
        else {
            return '';
        }
    }

    function BatteryRequirementsErrorMessages(props) {
        const rowData = props.cell._cell.row.data;
        const errorMessages = rowData.errorMessages;

        if (errorMessages && errorMessages.length > 0) {
            var errorId = "batteryErrorPopover" + rowData.cartonId;

            return <ErrorPopover errorId={errorId} header="Battery Requirement Errors" errorMessages={errorMessages} />
        }
        else {
            return '';
        }
    }

    function renderComplete() {
        // var rows = this.getRows();
        // for (var i = 0; i < rows.length; i++) {
        //     rows[i].validate();
        // }
    }

    function rowEnter(e, row) {
//        row.validate();
    }

    function productGrid_cellEdited(cell) {
        var row = cell.getRow();
        var product = row.getData();
        isProductValid(product, isInternational);
        row.update(product);
    }

    function productGrid_rowUpdated(row) {
        var product = row.getData();
        isProductValid(product, isInternational);
        row.reformat();
    }

    function productGrid_dataChanged(data) {
        setUpdatedImportingProducts(data);
    }

    function cartonGrid_cellEditod(cell) {
        var row = cell.getRow();
        var carton = row.getData();
        isCartonValid(carton, isInternational);
        row.update(carton);
        row.reformat();
    }

    function cartonGrid_dataChanged(data) {
        var product = updatedImportingProducts.find(x => x.supplierProductId === currentSupplierProductId);
        product.cartons = data;
        setUpdatedImportingProducts(updatedImportingProducts);
    }

    function batteryGrid_cellEdited(cell) {
        var row = cell.getRow();
        var br = row.getData();
        isBatteryRequirementValid(br, isInternational);
        row.update(br);
        row.reformat();
    }

    function batteryGrid_dataChanged(data) {
        var product = updatedImportingProducts.find(x => x.supplierProductId === currentSupplierProductId);
        product.batteryRequirements = data;
        setUpdatedImportingProducts(updatedImportingProducts);
    }

    function productTestGrid_cellEdited(cell) {
        var row = cell.getRow();
        var pt = row.getData();
        isProductTestValid(pt, isInternational);
        row.update(pt);
        row.reformat();
    }

    function productTestGrid_dataChanged(data) {
        var product = updatedImportingProducts.find(x => x.supplierProductId === currentSupplierProductId);
        product.supplierProductTestDates = data;
        setUpdatedImportingProducts(updatedImportingProducts);
    }

    function grid_validEntity(row) {
        var entity = row.getData();
        if (!entity.isValid)
            row.getElement().style.backgroundColor = "#ffc3bf";
        else 
            row.getElement().style.backgroundColor = "#ffffff";
    }

    const importProductOptions = {
        width: 1200,
        validationMode: "highlight"
        //rowFormatter: tabulator_rowFormatter
    }

    const importProductColumns = [
        {
            title: "",
            field: "errorMessages",
            editor: false,
            formatter: reactFormatter(
                <ErrorMessages />
            )
        },
        {
            title: "Item Name",
            field: "productProperties.itemDescription",
            editor: "input",
            validator: ["required", "maxlength: 37"]
        },
        {
            title: "Key Features",
            field: "productProperties.hamperFeatures",
            editor: "input"
        },
        {
            title: "Model Number",
            field: "productProperties.modelNumber",
            editor: "input"
        },
        {
            title: "Brand",
            field: "productProperties.brand",
            editor: "input"
        },
        {
            title: "Warranty Period",
            field: "productProperties.warrantyPeriod",
            editor: "input"
        },
        {
            title: "Unit Cost ($)",
            field: "forecastOverride",
            editor: "number",
            formatter: "money",
            validator: "required"
        },
        {
            title: "CurrencyCode",
            field: "currencyCode",
            editor: "select",
            editorParams: { 
                values: currencyList
            },
            formatter: tabulator_getSelectCellLabel
        },
        {
            title: "ItemCountryOfOrigin",
            field: "country",
            editor: "select",
            editorParams: {
                values: countryList
            },
            formatter: tabulator_getSelectCellLabel
        },
        {
            title: "Quotation Applicable Region",
            field: "quotationApplicableRegion",
            editor: "select",
            editorParams: {
                values: QuotableApplicableRegion_SelectList
            },
            formatter: tabulator_getSelectCellLabel
        },
        {
            title: "Delivery Port",
            field: "deliveryProperties.deliveryPort",
            editor: "input"
        },
        {
            title: "Minimum Order Quantity",
            field: "orderingLimits.minimumOrderQuantity",
            editor: "number"
        },
        {
            title: "Maximum Order Quantity",
            field: "orderingLimits.maximumQuantity",
            editor: "number"
        },
        {
            title: "Lead Time",
            field: "deliveryProperties.leadTime",
            editor: "number"
        },
        {
            title: "Item Contains Wood?",
            field: "productProperties.itemContainsWood",
            formatter: "tickCross",
            formatterParams: { allowEmpty: true },
            editor: true,
            validator: "required"
        },
        {
            title: "Is Weight Rated?",
            field: "productProperties.isWeightRated",
            formatter: "tickCross",
            formatterParams: { allowEmpty: true },
            editor: true,
            validator: "required"
        },
        {
            title: "Weight Limit",
            field: "productProperties.weightLimit",
            editor: "input"
        },
        {
            title: "Is Tipping Hazard?",
            field: "productProperties.isTippingHazard",
            formatterParams: { allowEmpty: true },
            formatter: "tickCross",
            editor: true,
            validator: "required"
        },
        {
            title: "Notes",
            field: "notes",
            editor: "textarea"
        },
        {
            title: "Are Batteries Required?",
            field: "productProperties.areBatteriesRequired",
            editor: true,
            formatter: "tickCross",
            formatterParams: { allowEmpty: true },
        },
        {
            title: "Has Button Batteries?",
            field: "productProperties.hasButtonBatteries",
            editor: true,
            formatter: "tickCross",
            formatterParams: { allowEmpty: true },
            validator: "required"
        },
        {
            title: "Battery Requirements",
            field: "batteryRequirements",
            editor: false,
            hozAlign: "center",
            formatter: reactFormatter(
                <BatteryButton />
            )
        },
        {
            title: "Product Test Dates",
            field: "supplierProductTestDates",
            editor: false,
            hozAlign: "center",
            formatter: reactFormatter(
                <ProductTestButton />
            )
        },
        {
            title: "Cartons",
            field: "cartons",
            editor: false,
            hozAlign: "center",
            formatter: reactFormatter(
                <CartonButton />
            )
        }

    ];

    const importBatteryRequirementColumns = [
        {
            title: "",
            field: "errorMessages",
            editor: false,
            formatter: reactFormatter(
                <BatteryRequirementsErrorMessages />
            )
        },
        {
            title: "BatteryType",
            field: "batteryType",
            editor: "input"
        },
        {
            title: "Is Included?",
            field: "isIncluded",
            editor: true,
            formatter: "tickCross",
            formatterParams: { allowEmpty: true },
        },
        {
            title: "Quantity",
            field: "quantity",
            editor: "number"
        }
    ];

    const importProductTestColumns = [
        {
            title: "",
            field: "errorMessages",
            editor: false,
            formatter: reactFormatter(
                <TestDatesErrorMessages />
            )
        },
        {
            title: "Product Test",
            field: "productTestType",
            editor: "input"
        },
        {
            title: "Product Test Date",
            field: "dateTested",
            editor: true
        }
    ];

    const importCartonColumns = [
        {
            title: "",
            field: "errorMessages",
            editor: false,
            formatter: reactFormatter(
                <CartonErrorMessages />
            )
        },
        {
            title: "GTIN13",
            field: "barcodeDetails.barcodeGTIN13",
            editor: "input",
            validator: "required"
        },
        {
            title: "GTIN14",
            field: "barcodeDetails.barcodeGTIN14",
            editor: "input"
        },
        {
            title: "Master Carton Height",
            field: "masterCartonDimensions.height",
            editor: "number"
        },
        {
            title: "Master Carton Length",
            field: "masterCartonDimensions.length",
            editor: "number"
        },
        {
            title: "Master Carton Depth",
            field: "masterCartonDimensions.width",
            editor: "number"
        },
        {
            title: "Master Carton Size Unit",
            field: "masterCartonDimensions.sizeUnit",
            editor: "select",
            editorParams: {
                values: sizeList
            },
            formatter: tabulator_getSelectCellLabel
        },
        {
            title: "Master Carton Weight",
            field: "masterCartonDimensions.weight",
            editor: "number",
        },
        {
            title: "Master Carton Weight Unit",
            field: "masterCartonDimensions.weightUnit",
            editor: "select",
            editorParams: {
                values: weightList,
            },
            formatter: tabulator_getSelectCellLabel
        },
        {
            title: "Retail Carton Height",
            field: "retailUnitDimensions.height",
            editor: "number",
            validator: "required"
        },
        {
            title: "Retail Carton Length",
            field: "retailUnitDimensions.length",
            editor: "number",
            validator: "required"
        },
        {
            title: "Retail Carton Depth",
            field: "retailUnitDimensions.width",
            editor: "number",
            validator: "required"
        },
        {
            title: "Retail Carton Size Unit",
            field: "retailUnitDimensions.sizeUnit",
            editor: "select",
            editorParams: {
                values: sizeList
            },
            formatter: tabulator_getSelectCellLabel,
            validator: "required"
        },
        {
            title: "Retail Carton Weight",
            field: "retailUnitDimensions.weight",
            editor: "number",
            validator: "required"
        },
        {
            title: "Retail Carton Weight Unit",
            field: "retailUnitDimensions.weightUnit",
            editor: "select",
            editorParams: {
                values: weightList,
            },
            formatter: tabulator_getSelectCellLabel,
            validator: "required"
        },
        {
            title: "Retail Units Per Carton",
            field: "retailUnitsPerMasterCarton",
            editor: "number",
            validator: "required"
        },
        {
            title: "Product Code",
            field: "supplierProductCode",
            editor: "input"
        }

    ];

    return (
        <div className="submissionContainer">
            <h3>Products</h3>
            <Container>
                <Row>
                    <Col xs="4">
                        <Button disabled={editProduct} color="warning" onClick={addProduct}><FaPlus />Add Product</Button>{' '}<Button disabled={editProduct} color="info" onClick={showProductImport}><FaFileImport /> Import Products</Button>
                        <div className="productListContainer">
                            <ProductList products={productList} selectedProductChange={selectedProductChange} isDisabled={editProduct} setProductId={newProductId} updatedProducts={productsUpdated}  />
                        </div>
                    </Col>
                    <Col xs="8">
                        {currentProduct ? 
                            (editProduct ? <ProductEdit product={currentProduct} productUpdated={productUpdated} cancelUpdate={cancelProductUpdate} isInternational={isInternational} /> : 
                                <div>
                                    <div className="productViewTopButtons">
                                        <Button color="secondary" size="sm" onClick={() => setEditProduct(true)}><MdEdit /> Edit</Button>
                                    </div>
                                    <ProductView product={currentProduct} />
                                </div>) 
                            : <div>Select Product From List, or Add New Product</div>}
                    </Col>
                </Row>
            </Container>
            <div className="footer box-shadow">
                <Button color="primary" onClick={saveAndContinue} disabled={editProduct || (productList && productList.length > 0 && !productList.every(x => x.isValid))}>Save and Continue</Button>{' '}
                <Button disabled={editProduct} onClick={() => prevStep()}>Go Back</Button>{' '}
                {editProduct && 
                    <Badge color="warning" className="productEditWarning">
                        <small>Please finish editing product (or cancel editing) to continue</small>
                    </Badge>
                }
                {productList && productList.length > 0 && !productList.every(x => x.isValid) && 
                    <Badge color="danger" className="productEditWarning">
                    <small>Please fix all errors in products to continue</small>
                </Badge>
                }
            </div>
            <Modal isOpen={showProductImportModal} fade={true} backdrop={true}>
                <ModalHeader>Upload Products</ModalHeader>
                <ModalBody>
                    <div>
                        <div>
                            Download the excel file template found below.  
                        </div>
                        <div>
                            Look at the 'Instructions' worksheet for field descriptions.  
                        </div>
                        <div>
                            <a className="btn btn-warning" href="/ImportProductSubmissionsTemplate.xlsx"><FaFileDownload /> Excel Template</a>
                        </div>

                        <div>
                            After completing the file upload it below:
                        </div>
                    </div>
                    <div className="formGroup">
                        <div className="groupTitle">Upload Products</div>
                        <Input type="file" id="productFile" onChange={productFileChanged} />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={uploadProductFile}>Upload Products</Button>
                    <Button onClick={cancelUpload}>Cancel</Button>
                </ModalFooter>
            </Modal>
            <Modal isOpen={showProductImportReturnModal} fade={true} backdrop={true} size="xl" scrollable={true}>
                <ModalHeader>Uploaded Products</ModalHeader>
                <ModalBody>
                    <div>
                       Below are the products uploaded in the file.  Please verify the data, remedy any issues and once satisfied confirm to add the products.
                    </div>
                    <div className="formGroup">
                        <div className="groupTitle">Products</div>
                        {importingProducts && importingProducts.length > 0 && 
                            <div>
                                <ReactTabulator columns={importProductColumns} data={importingProducts} layout="fitData" options={importProductOptions} 
                                rowFormatter={grid_validEntity}
                                events={{
                                    cellEdited: productGrid_cellEdited,
                                    dataChanged: productGrid_dataChanged,
                                    rowUpdated: productGrid_rowUpdated
                                }}
                                 />
                            </div>
                        }
                    </div>
                    <Modal isOpen={showProductImportBatteryModal} fade={true} backdrop={true} size="xl" scrollable={true}>
                        <ModalHeader>Uploaded Battery Requirements</ModalHeader>
                        <ModalBody>
                        <div className="formGroup">
                            <div className="groupTitle">Battery Requirements</div>
                            <div>
                                <ReactTabulator columns={importBatteryRequirementColumns} data={currentBatteryRequirements} layout="fitData" 
                                rowFormatter={grid_validEntity}
                                events={{
                                    cellEdited: batteryGrid_cellEdited,
                                    dataChanged: batteryGrid_dataChanged
                                }}
                                />
                            </div>
                        </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={closeBatteryModal}>Close</Button>
                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={showProductImportProductTestModal} fade={true} backdrop={true} size="xl" scrollable={true}>
                        <ModalHeader>Uploaded Product Test Dates</ModalHeader>
                        <ModalBody>
                        <div className="formGroup">
                            <div className="groupTitle">Product Test Dates</div>
                            <div>
                                <ReactTabulator columns={importProductTestColumns} data={currentProductTests} layout="fitData" 
                                rowFormatter={grid_validEntity} 
                                events={{
                                    cellEdited: productTestGrid_cellEdited,
                                    dataChanged: productTestGrid_dataChanged
                                 }} />
                            </div>
                        </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={closeProductTests}>Close</Button>
                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={showProductImportCartonModal} fade={true} backdrop={true} size="xl" scrollable={true}>
                        <ModalHeader>Uploaded Cartons</ModalHeader>
                        <ModalBody>
                        <div className="formGroup">
                            <div className="groupTitle">Cartons</div>
                            <div>
                                <ReactTabulator columns={importCartonColumns} data={currentCartons} layout="fitData" options={importProductOptions} 
                                rowFormatter={grid_validEntity} events={{
                                    cellEdited: cartonGrid_cellEditod,
                                    dataChanged: cartonGrid_dataChanged
                                }}  />
                            </div>
                        </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={closeCartonModal}>Close</Button>
                        </ModalFooter>
                    </Modal>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" disabled={!updatedImportingProducts || !updatedImportingProducts.every(x => x.isValid)} onClick={confirmUploadedProducts}>Upload Products</Button>
                    <Button onClick={cancelUpload}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}