import React, { useEffect, useState } from "react";
import { FaFileExcel } from "react-icons/fa";
import { useParams, useHistory } from "react-router";
import { Button, FormText, Spinner, Table, Toast, ToastBody, ToastHeader } from "reactstrap";
import { BASEAPI } from "../../constants";
import { useAuthState } from "../../contexts/AuthContext";
import { useServerErrorState } from "../../contexts/ServerErrorContext";
import { GetFileWithToken, GetJsonWithToken, PostJsonWithToken } from "../../helpers/fetch_helpers";
import SkuQuote from "./Quotes/SkuQuote";

export default function PricingQuotes() {
    let { requestId } = useParams();
    let [isLoading, setIsLoading] = useState(false);
    let [isSaving, setIsSaving] = useState(false);
    let [isExporting, setIsExporting] = useState(false);
    let [showSuccessToast, setShowSuccessToast] = useState(false);
    let history = useHistory();

    let [quotes, setQuotes] = useState();

    let [updatedQuotes, setUpdatedQuotes] = useState([]);

    let [authState, dispatchAuthState] = useAuthState();
    let [serverErrorState, dispatchServerError] = useServerErrorState();

    async function loadQuotes() {
        setIsLoading(true);
        var uri = BASEAPI + "/api/skuquotes/" + requestId;
        var result = await GetJsonWithToken(uri, null, authState.token)
        .catch(function(error) {
            console.log(error);
            dispatchServerError({type: "SET_ERROR", errorTitle: "Error Getting Quotes", errorMessage: error.message});
        })
        .finally(() => setIsLoading(false))

        setQuotes(result.skuQuotes);
    }

    useEffect(() => {
        loadQuotes();
    }, [requestId]);

    function quoteUpdated(quote) {
        var index = updatedQuotes.findIndex(x => x.skuId == quote.skuId);
        if (index >= 0) {
            updatedQuotes[index] = quote;
            setUpdatedQuotes(updatedQuotes);
        } else {
            var qts = updatedQuotes.concat(quote);
            setUpdatedQuotes(qts);
        }
    }

    async function saveProgress() {
        setIsSaving(true);
        var model = {
            supplierRequestId: requestId,
            progressQuotes: updatedQuotes
        };

        var uri = BASEAPI + "/api/skuquotes/savePendingQuotes";

        var result = await PostJsonWithToken(uri, model, authState.token)
        .catch(function(error) {
            console.log(error);
            dispatchServerError({type: "SET_ERROR", errorTitle: "Error Saving Quotes", errorMessage: error.message});
        })
        .finally(() => setIsSaving(false))

        if (result) {
            setUpdatedQuotes([])
        }
    }

    async function submitQuotes() {
        setIsSaving(true);
        const uri = BASEAPI + "/api/supplierrequest/SubmitSkuQuotes/" + requestId;
        
        var result = await PostJsonWithToken(uri, null, authState.token)
        .catch(function (error) {
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error Submitting Quotes", errorMessage: error.message});
        })
        .finally(() => setIsSaving(false))

        if (result.result) {
            setShowSuccessToast(true);
        }
        else {
            dispatchServerError({type: "SET_ERROR", errorTitle:"Error Submitting Quotes", errorMessage: "Could not submit Quotes, please contact Chrisco Representative"})
        }
    }

    async function exportQuotes() {
        setIsExporting(true);
        var uri = BASEAPI + "/api/skuquotes/exportQuotes/" + requestId;

        GetFileWithToken(uri, authState.token, "QuoteExport.xlsx")
        .catch(function(error){
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error Generating Excel Document", errorMessage:"Server Error: " + error.message});
        })
        .finally(() => setIsExporting(false))
    }

    function toggle() {
        setShowSuccessToast(false);
        setTimeout(() => { history.push("/") }, 200);
    }

    return (
        <>
        {isLoading ? <Spinner /> : 
        <div>
            <h2>Pricing Quotes</h2>

            <Button color="warning" onClick={exportQuotes}>Export Quotes <FaFileExcel /></Button> {isExporting && <Spinner color="warning" />}

            <Table>
                <thead>
                    <tr>
                        <th>Chrisco Item Code</th>
                        <th>Item Description</th>
                        <th>Unit Weight <br /> (KG)</th>
                        <th>Supplier <br /> Product Code</th>
                        <th>Unit</th>
                        <th>GST (Yes/No)</th>
                        <th>Current RRP</th>
                        <th>Quoted Price<br />(ex Gst)<br />Per Unit</th>
                    </tr>
                </thead>

                <tbody>
                {quotes && quotes.length > 0 && quotes.map((quote, index) => {
                    return <SkuQuote skuQuote={quote} index={index} quoteUpdated={quoteUpdated} />
                })}
                </tbody>
            </Table>
            <div>
                <Button disabled={updatedQuotes == null || updatedQuotes.length == 0} color="primary" onClick={() => saveProgress()}>Save Progress</Button>{isSaving && <Spinner color="primary" />}
                <Button disabled={updatedQuotes != null && updatedQuotes.length > 0} color="success" onClick={() => submitQuotes()}>Submit</Button>{updatedQuotes != null && updatedQuotes.length > 0 && <FormText>Please save progress, before submitting</FormText>}
            </div>
            {showSuccessToast &&
            <div className="p-3 my-2 rounded bg-success standardToast">
                <Toast isOpen={showSuccessToast} >
                    <ToastHeader icon="success" toggle={toggle}>Quotes Submitted</ToastHeader>
                    <ToastBody>
                        Quotes Successfully Submitted to server.
                    </ToastBody>
                </Toast>
            </div>
            }
        </div>
        }
        </>
    )
}