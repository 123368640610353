import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { BsDot } from "react-icons/bs";
import { MdEdit } from "react-icons/md";
import { Alert, Button, Col, FormGroup, Input, InputGroup, InputGroupAddon, Label } from "reactstrap";
import { ContactTypeEnum } from "../../helpers/enums";
import AddressEdit, { AddressTypeEnum } from "../Address/AddressEdit";
import AddressView from "../Address/AddressView";
import { uuidv4 } from "../Utility/guid";

export default function ContactEdit({contact, contactTypeArg, updateContact, cancelUpdate}) {
    let [contactType, setContactType] = useState(contact ? contact.contactType : contactTypeArg);
    let [address, setAddress] = useState(contact ? contact.address : null);
    let [receiveRemmittanceAdviceEmail, setReceiveRemmittanceAdviceEmail] = useState(contact ? contact.messagePreferences.receiveRemmittanceAdviceEmail : false);

    let [editAddress, setEditAddress] = useState(false);

    const { handleSubmit, control, formState: { errors }} = useForm({
        mode: "all",
        defaultValues: {
            firstName: contact ? contact.firstName : '',
            lastName: contact ? contact.lastName : '',
            jobTitle: contact ? contact.jobTitle : '',
            phone: contact ? contact.phone : '',
            phoneAreaCode: contact ? contact.phoneAreaCode : '',
            fax: contact ? contact.fax : '',
            faxAreaCode: contact ? contact.faxAreaCode : '',
            mobile: contact ? contact.mobile : '',
            email: contact ? contact.email : ''
        }
    });

    function update(formData) {
        var con = {
            contactId: contact ? contact.contactId : uuidv4(),
            contactType: contactType,
            firstName: formData.firstName,
            lastName: formData.lastName,
            jobTitle: formData.jobTitle,
            phone: formData.phone,
            phoneAreaCode: formData.phoneAreaCode,
            fax: formData.fax,
            faxAreaCode: formData.faxAreaCode,
            mobile: formData.mobile,
            email: formData.email,
            address: address,
            addressId: address ? address.addressId : null,
            messagePreferences: {
                receiveRemmittanceAdviceEmail: receiveRemmittanceAdviceEmail
            }
        }

        updateContact(con);
    }

    function updateAddress(address) {
        setAddress(address);
        setEditAddress(false);
    }

    function cancelEditAddress() {
        setEditAddress(false);
    }

    return (
        <div>
            <div className="formGroup">
                <div className="groupTitle">Edit Contact</div>
                <Alert color="light">Required Fields <BsDot color="red" /></Alert>

                <Controller id="firstName" name="firstName" 
                    control={control}
                    rules={{required: true}}
                    render={({field: {ref, ...fieldProps}}) => (
                        <FormGroup row>
                            <Label column="true" sm="2" for="firstName"><BsDot color="red" /> First Name</Label>
                            <Col sm={10}>
                                <Input 
                                    id="firstName"
                                    type="text"
                                    innerRef={ref} 
                                    {...fieldProps } 
                                    className={`wideInput form-control ${errors.firstName ? 'is-invalid' : ''}`}/>
                                {errors.firstName && <div class="invalid-feedback">{errors.firstName.type === 'required' && 'Please enter a First Name'}</div>}
                            </Col>
                        </FormGroup>
                    )}
                />

                <Controller id="lastName" name="lastName" 
                        control={control}
                        rules={{required: true}}
                        render={({field: {ref, ...fieldProps}}) => (
                            <FormGroup row>
                                <Label column="true" sm="2" for="lastName"><BsDot color="red" /> Last Name</Label>
                                <Col sm={10}>
                                    <Input 
                                        id="lastName"
                                        type="text"
                                        innerRef={ref} 
                                        {...fieldProps } 
                                        className={`wideInput form-control ${errors.lastName ? 'is-invalid' : ''}`}/>
                                    {errors.lastName && <div class="invalid-feedback">{errors.lastName.type === 'required' && 'Please enter a Last Name'}</div>}
                                </Col>
                            </FormGroup>
                        )}
                />
                   
                <Controller id="jobTitle" name="jobTitle" 
                    control={control}
                    render={({field: {ref, ...fieldProps}}) => (
                        <FormGroup row>
                            <Label column="true" sm="2" for="jobTitle">Job Title</Label>
                            <Col sm={10}>
                                <Input 
                                    id="jobTitle"
                                    type="text"
                                    innerRef={ref} 
                                    {...fieldProps } 
                                    className="wideInput" />
                            </Col>
                        </FormGroup>
                    )}
                />

                <FormGroup inline row>
                    <Label column sm="2" for="phone">Phone</Label>
                    <Col sm="10">
                    <InputGroup className="inputGrp">
                    <InputGroupAddon addonType="prepend">
                    (<Controller id="phoneAreaCode" name="phoneAreaCode" 
                            control={control}
                            render={({field: {ref, ...fieldProps}}) => (
                                        <Input 
                                            id="jobTitle"
                                            type="text"
                                            innerRef={ref} 
                                            {...fieldProps } 
                                            className="areaCodeInput" />
                            )}
                        />)
                            </InputGroupAddon>
                        <Controller id="phone" name="phone" 
                            control={control}
                            render={({field: {ref, ...fieldProps}}) => (
                                        <Input 
                                            id="phone"
                                            type="text"
                                            innerRef={ref} 
                                            {...fieldProps } 
                                            className="phoneNumber" />
                            )}
                        />
                    </InputGroup>
                    </Col>
                </FormGroup>
                   
                <FormGroup inline row>
                    <Label column sm="2" for="fax">Fax</Label>
                    <Col sm="10">
                    <InputGroup className="inputGrp">
                    <InputGroupAddon addonType="prepend">
                    (<Controller id="faxAreaCode" name="faxAreaCode" 
                            control={control}
                            render={({field: {ref, ...fieldProps}}) => (
                                        <Input 
                                            id="faxAreaCode"
                                            type="text"
                                            innerRef={ref} 
                                            {...fieldProps } 
                                            className="areaCodeInput" />
                            )}
                        />)
                            </InputGroupAddon>
                        <Controller id="fax" name="fax" 
                            control={control}
                            render={({field: {ref, ...fieldProps}}) => (
                                        <Input 
                                            id="fax"
                                            type="text"
                                            innerRef={ref} 
                                            {...fieldProps } 
                                            className="phoneNumber" />
                            )}
                        />
                    </InputGroup>
                    </Col>
                </FormGroup>
                   
                <Controller id="mobile" name="mobile" 
                    control={control}
                    render={({field: {ref, ...fieldProps}}) => (
                        <FormGroup row>
                            <Label column="true" sm="2" for="jobTitle">Mobile</Label>
                            <Col sm={10}>
                                <Input 
                                    id="mobile"
                                    type="text"
                                    innerRef={ref} 
                                    {...fieldProps } 
                                    className="wideInput" />
                            </Col>
                        </FormGroup>
                    )}
                />
                    
                <Controller id="email" name="email" 
                        control={control}
                        rules={{required: true, pattern:  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/}}
                        render={({field: {ref, ...fieldProps}}) => (
                            <FormGroup row>
                                <Label column="true" sm="2" for="email"><BsDot color="red" /> E-Mail</Label>
                                <Col sm={10}>
                                    <Input 
                                        id="email"
                                        type="text"
                                        innerRef={ref} 
                                        {...fieldProps } 
                                        className={`wideInput form-control ${errors.email ? 'is-invalid' : ''}`}/>
                                    {errors.email && <div class="invalid-feedback">
                                        {errors.email.type === 'required' && 'Please enter an e-mail address'}
                                        {errors.email.type === 'pattern' && 'Please enter valid email address.'}
                                    </div>}
                                </Col>
                            </FormGroup>
                        )}
                />                   
                {contactType === ContactTypeEnum.Finance && 
                <FormGroup>
                    <Label>Is Remittance Advices Email Recipient?</Label>
                    <Col sm="10">
                        Yes<input type="radio" name="remittanceEmail" value={true} checked={receiveRemmittanceAdviceEmail === true} onChange={e => setReceiveRemmittanceAdviceEmail(true)} />
                        No<input type="radio"  name="remittanceEmail" value={false} checked={receiveRemmittanceAdviceEmail !== null && receiveRemmittanceAdviceEmail === false} onChange={e => setReceiveRemmittanceAdviceEmail(false)} />
                    </Col>
                </FormGroup>}
                <div className="formGroup">
                    <div className="groupTitle">Address</div>
                        {editAddress ? <AddressEdit address={address} addressType={AddressTypeEnum.Postal} updateAddress={updateAddress} cancelEdit={cancelEditAddress} /> 
                        : 
                        <div>
                            <div className="topButtons"><Button size="sm" onClick={() => setEditAddress(true)}>Edit<MdEdit /></Button></div>
                            <AddressView address={address} />  
                        </div>}
                    </div>
                    <div>
                        <Button color="primary" onClick={handleSubmit(update)}>Save Contact</Button>
                        <Button onClick={() => cancelUpdate()}>Cancel</Button>
                    </div>
            </div>
        </div>

    )
}