import React from "react";
import { Link } from "react-router-dom";
import { SupplierRequestTypeEnum, SupplierRequestTypeLabel } from "../../helpers/enums";

export default function ActionsList({requests}) {

    function buildActionLocation(supplierRequestTypeId, requestId) {
        switch (supplierRequestTypeId) {
            case SupplierRequestTypeEnum.PricingQuotes:
                return "/pricingQuotes/" + requestId;
            case SupplierRequestTypeEnum.FinanceDetails:
                return "/financeDetails/" + requestId;
            case SupplierRequestTypeEnum.Contact:
                return "/contact/" + requestId;
            case SupplierRequestTypeEnum.SKUs:
                return "/productDetails/" + requestId;
            case SupplierRequestTypeEnum.TradingTerm:
                return "/tradingTerms/" + requestId;
            case SupplierRequestTypeEnum.ProductSubmissions:
                return "/productSubmissions/" + requestId;
            default:
                throw new Error("Unexecpected Supplier Request Type");
        }
    }

    return (
        <ul className="actionList">
            {requests && requests.map((request, index) => {
                return (
                        <li key={index}>
                            <Link className="actionBtn" to={buildActionLocation(request.supplierRequestType, request.supplierRequestId)}> {SupplierRequestTypeLabel(request.supplierRequestType)}</Link>
                        </li>
                    )
        })}
        </ul>
    )
}