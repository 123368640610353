import React, { useState } from "react";
import { Toast, ToastHeader, ToastBody } from "reactstrap";
import { useServerErrorState } from "../../contexts/ServerErrorContext";
import "./error.css";

export default function ErrorToast() {
    let [show, setShow] = useState(true);
    let [serverErrorState, serverErrorDispatch] = useServerErrorState();

    const toggle = () => {
        setShow(!show);
        if (show) { //if currently show, clear error because we are hiding
            serverErrorDispatch({type: "CLEAR_ERROR"});
        }
    }

    return (
        <div className="p-3 my-2 rounded errorToast">
            <Toast isOpen={show} >
                <ToastHeader icon="danger" toggle={toggle}>{serverErrorState.errorTitle}</ToastHeader>
                <ToastBody>
                    <small>
                        {serverErrorState.errorMessage}
                    </small>
                </ToastBody>
            </Toast>
        </div>
    );
}