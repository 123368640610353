import React from "react";
import { Route } from "react-router";
import ChangePassword from "./components/Account/ChangePassword";
import ForgotPassword from "./components/Account/ForgotPassword";
import SelectSupplier from "./components/Account/SelectSupplier";
import AdminLog from "./components/Admin/AdminLog";
import Home from "./components/Home";
import { Layout } from "./components/Layout";
import Contact from "./components/Requests/Contact";
import FinancialDetails from "./components/Requests/FinancialDetails";
import PricingQuotes from "./components/Requests/PricingQuotes";
import ProductDetails from "./components/Requests/ProductDetails";
import ProductSubmissions from "./components/Requests/ProductSubmissions";
import TradingTerms from "./components/Requests/TradingTerms";
import SkuReview from "./components/Sku/SkuReview";
import SkuReviews from "./components/Sku/SkuReviews";

export default function AppLayout() {
    return (
        <Layout>
            <Route exact path="/" component={Home} />
            <Route path="/pricingQuotes/:requestId">
                <PricingQuotes />
            </Route>
            <Route path="/tradingTerms/:requestId">
                <TradingTerms />
            </Route>
            <Route path="/financeDetails/:requestId">
                <FinancialDetails />
            </Route>
            <Route path="/contact/:requestId">
                <Contact />
            </Route>
            <Route path="/productDetails/:requestId">
                <ProductDetails />
            </Route>
            <Route path="/productSubmissions/:requestId">
                <ProductSubmissions />
            </Route>
            <Route path="/forgotPwd">
                <ForgotPassword />
            </Route>
            <Route path="/selectSupplier">
                <SelectSupplier />
            </Route>
            <Route path="/changePassword">
                <ChangePassword />
            </Route>
            <Route path="/admin/log">
                <AdminLog />
            </Route>
            <Route path="/skuReviews">
                <SkuReviews />
            </Route>
            <Route path="/skuReview/:skuReviewId">
                <SkuReview />
            </Route>
        </Layout>
    )
}