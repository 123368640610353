import React from "react";
import { FaTimes } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { Button, Label } from "reactstrap";
import Dimensions from "../Dimensions/Dimensions";
import "./carton.css";

export default function CartonView({carton, editCarton, removeCarton, editing, cartonIndex, cartonTotal}) {
    return (
        <div className="cartonContainer">
            {editing && 
                <div className="topButtonsCarton">
                    <Button size="sm" onClick={() => editCarton(carton.cartonId)}><MdEdit />Edit</Button>
                    <Button size="sm" color="danger" onClick={() => removeCarton(carton.cartonId)}><FaTimes />Remove</Button>
                </div>
            }
            <div className="cartonCountContainer">
                {cartonIndex} of {cartonTotal}
            </div>
            <div>
                <Label>Master Carton</Label>
                <Dimensions dimensions={carton.masterCartonDimensions} />
            </div>
            <div>
                <Label>Retail Units Per Carton:</Label>
                {carton.retailUnitsPerMasterCarton}
            </div>
            <div>
                <Label>Retail Carton</Label>
                <Dimensions dimensions={carton.retailUnitDimensions} />
            </div>
            <div>
                <Label>Product Code:</Label>
                {carton.supplierProductCode}
            </div>
            <div>
                <Label>Barcodes:</Label>
                {carton.barcodeDetails.barcodeGTIN13 && <span>GTIN13 - {carton.barcodeDetails.barcodeGTIN13} </span>}
                {carton.barcodeDetails.barcodeGTIN14 && <span>GTIN14 - {carton.barcodeDetails.barcodeGTIN14}</span>}
            </div>
        </div>
    )
}