import React, { useState, useEffect } from "react";
import { Alert, Button, Carousel, CarouselControl, CarouselIndicators, CarouselItem, FormText, Input, Modal, ModalBody, ModalFooter, ModalHeader, Label, Nav, TabPane, NavItem, TabContent, NavLink } from "reactstrap";
import { FaCross, FaPlus, FaTrashAlt } from "react-icons/fa";
import { useAuthState } from "../../contexts/AuthContext";
import { useServerErrorState } from "../../contexts/ServerErrorContext";
import "./product.css";
import { BASEAPI } from "../../constants";
import { uuidv4 } from "../Utility/guid";
import { PostFormWithToken } from "../../helpers/fetch_helpers";
import { VideoUploadType } from "../../helpers/enums";
import { isEmpty } from "../../helpers/utils";

export default function ProductVideoList({supplierProductVideos, supplierId, supplierProductId, editing, videosUpdated}) {
    let [activeIndex, setActiveIndex] = useState(0);
    let [animating, setAnimating] = useState(false);
    let [showUploadProductVideo, setShowUploadProductVideo] = useState(false);
    let [file, setFile] = useState();
    let [videoUri, setVideoUri] = useState('');
    let [allVideos, setAllVideos] = useState();
    let [uploadError, setUploadError] = useState(null);
    let [currentVideoTab, setCurrentVideoTab] = useState(VideoUploadType.File);

    let [authState, dispatchAuth] = useAuthState();
    let [serverError, dispatchServerError] = useServerErrorState();

    useEffect(() => {
        setAllVideos(supplierProductVideos);
    }, [supplierProductVideos])

    function next() {
        if (animating) return;
        var nextIndex = activeIndex === supplierProductVideos.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    }

    function prev() {
        if (animating) return;
        var nextIndex = activeIndex === 0 ? supplierProductVideos.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    }

    function gotoIndex(newIndex) {
        if (animating) return;
        setActiveIndex(newIndex);
    }

    function addVideo() {
        setShowUploadProductVideo(true);
        setFile(null);
    }

    function cancelUpload() {
        setUploadError(null);
        setShowUploadProductVideo(false);
    }

    function removeVideo(videoId) {
        if (window.confirm('Are you sure you want to remove current video?')) {
            var index = allVideos.findIndex(x => x.supplierProductVideoId == videoId);
            if (index > -1) {
                allVideos.splice(index, 1);
                setAllVideos(allVideos);
                videosUpdated(allVideos);
            }
        }
    }

    function supplierProductVideoChanged() {
        var fileInput = document.querySelector('#supplierProductVideoFile');
        setFile(fileInput.files[0]);
    }

    async function uploadVideo() {
        var fileInput = document.querySelector('#supplierProductVideoFile');

        var videoId = uuidv4();

        const data = new FormData();
        data.append('uploadedFile', fileInput.files[0]);
        data.append('supplierProductVideoId', videoId);
        data.append('supplierProductId', supplierProductId);
        data.append('supplierId', supplierId);
        data.append('videoUri', videoUri)

        var uri = BASEAPI + "/api/video/ImportVideo";

        var result = await PostFormWithToken(uri, data, authState.token)
        .catch(function(error) {
            console.log(error);
            dispatchServerError({type: "SET_ERROR", errorTitle:"Error Uploading Supplier Product Video", errorMessage: error.message});
        })

        if (result && result.result) {
            var newVid = {
                supplierProductVideoId: videoId,
                supplierProductId: supplierProductId,
                videoFileName: videoId,
                videoUri: videoUri
            }
            var videos = allVideos.concat(newVid);
            setAllVideos(videos);
            videosUpdated(videos);
            setShowUploadProductVideo(false);
            setUploadError(null);
        }
        else if (result) {
            setUploadError(result.errorMessage);
        }
    }

    function toggleVideoTab(videoUploadType) {
        if (videoUploadType !== currentVideoTab) {
            if (videoUploadType === VideoUploadType.File) {
                setVideoUri('');
            } else if (videoUploadType === VideoUploadType.Link) {
                var fileInput = document.querySelector('#supplierProductVideoFile');
                fileInput.value = null;
                setFile(null);
            }
            setCurrentVideoTab(videoUploadType);
        }
    }

    const videos = allVideos ? allVideos.map((productVideo) => {
        var videoUri = BASEAPI + "/api/video/" + productVideo.supplierProductVideoId + "/?supplierId=" + supplierId

        return (
            <CarouselItem className="carouselItem" key={productVideo.supplierProductVideoId} onExiting={() => setAnimating(true)} onExited={() => setAnimating(false)}>
                {editing && <Button className="carItemRemoveBtn" size="sm" color="danger" onClick={() => removeVideo(productVideo.supplierProductVideoId)}><FaTrashAlt />Remove</Button>}
                {!productVideo.videoUri && <video controls width="450">
                    <source src={videoUri} />
                </video>}
                {productVideo.videoUri && <div className="videoLinkContainer"><a className="videoLink" href={productVideo.videoUri}>{productVideo.videoUri}</a></div>} 
            </CarouselItem>
        )
    }) : null;


    return (
        <div className="videoListContainer">
            {editing && <div>
                <Button size="sm" color="primary" onClick={addVideo}><FaPlus />Add Product Video</Button>
            </div>}
            {allVideos &&
            <div className="productVideoContainer">
                <Carousel activeIndex={activeIndex} next={next} previous={prev} interval="300000">
                    <CarouselIndicators items={allVideos} activeIndex={activeIndex} onClickHandler={gotoIndex} />
                    {videos}
                    <CarouselControl direction="prev" directionText="Previous" onClickHandler={prev} />
                    <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
                </Carousel>
            </div>
            }
           <Modal isOpen={showUploadProductVideo} fade={true}>
                <ModalHeader>Upload Product Video</ModalHeader>
                <ModalBody>
                    <div>Upload a video via File or a link to a download site:</div>

                    <Nav tabs>
                        <NavItem>
                            <NavLink className={currentVideoTab === VideoUploadType.File ? 'active' : ''} onClick={() => toggleVideoTab(VideoUploadType.File)}>
                                Upload File
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={currentVideoTab === VideoUploadType.Link ? 'active' : ''} onClick={() => toggleVideoTab(VideoUploadType.Link)}>
                                Upload Link
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={currentVideoTab}>
                        <TabPane tabId={VideoUploadType.File}>
                            <Input type="file" id="supplierProductVideoFile" onChange={supplierProductVideoChanged} />
                            <FormText>Note - Video can only be max 25Mb any bigger, please upload to a filesharing service and use Link Tab</FormText>
                        </TabPane>
                        <TabPane tabId={VideoUploadType.Link}>
                            <Label for="supplierProductVideoUri">Video Link</Label>
                            <Input type="text" id="supplierProductVideoUri" value={videoUri} onChange={e => setVideoUri(e.target.value)} />
                        </TabPane>
                    </TabContent>
                    {uploadError && 
                    <Alert color="danger"><h4>Upload Issue</h4>{uploadError}</Alert>}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" disabled={isEmpty(videoUri) && file === null} onClick={uploadVideo}>Upload</Button>
                    <Button onClick={cancelUpload}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}