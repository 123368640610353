import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, FormGroup, Input, Label, Spinner, Table } from "reactstrap";
import { BASEAPI } from "../../constants";
import { useAuthState } from "../../contexts/AuthContext";
import { useServerErrorState } from "../../contexts/ServerErrorContext";
import { GetJsonWithToken, PostJsonWithToken } from "../../helpers/fetch_helpers";
import { isEmpty } from "../../helpers/utils";

export default function SkuReview() {
    let [review, setReview] = useState();
    let [loading, setLoading] = useState(false);
    let [note, setNote] = useState();

    let { skuReviewId } = useParams();
    let [authState, dispatchAuth] = useAuthState();
    let [serverError, dispatchServerError] = useServerErrorState();

    async function getReview() {
        setLoading(true);
        var uri = BASEAPI + "/api/skuReview/" + skuReviewId;

        var result = await GetJsonWithToken(uri, null, authState.token)
        .catch(function(error){
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error getting review", errorMessage: error.message});
        })
        .finally(() => setLoading(false));
        
        setReview(result);
        setNote(result.approvalNote);
    }

    useEffect(() => {
        if (skuReviewId) {
            getReview();
        }
    }, [skuReviewId]);

    async function approveReview() {
        setLoading(true);

        var uri = BASEAPI + "/api/skuReview/ApproveRequest/";

        var data = {
            skuReviewId: skuReviewId,
            approvalNote: note
        };

        var result = await PostJsonWithToken(uri, data, authState.token)
        .catch(function(error){
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error approving review", errorMessage: error.message});
        })
        .finally(() => setLoading(false));

        if (result.result) {
            getReview();
        }
    }

    async function rejectReview() {
        if (window.confirm('If review is denied, the attached SKU will be marked as deleted in Stocklist.\n\nTo confirm, click OK.\nTo abort, click Cancel.')) {
            setLoading(true);

            var uri = BASEAPI + "/api/skuReview/DenyRequest/";

            var data = {
                skuReviewId: skuReviewId,
                denyNote: note
            };

            var result = await PostJsonWithToken(uri, data, authState.token)
            .catch(function(error){
                console.log(error);
                dispatchServerError({type: "SET_ERROR",errorTitle:"Error deny review", errorMessage: error.message});
            })
            .finally(() => setLoading(false));

            if (result.result) {
                getReview();
            }

        }
    }

    return (
        <>
            {loading && <Spinner color="primary" />}

            {review && 
            <div>
                <h3>Review for Sku: {review.skuName} <i>by {review.submitterName}</i> <small>at {format(new Date(review.submittedAt), 'dd/MM/yyyy hh:mm:ss')}</small></h3>

                <div className="formGroup">
                    <div className="groupTitle">Changes</div>

                    <strong>Key Fields</strong>

                    <Table striped>
                        <thead>
                            <tr>
                                <th width="450px">Field Name</th>
                                <th>Original Value</th>
                                <th>New Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            {review.changes.changeList.filter(x => x.keyField === true).map((change, index) => {
                                return (<tr key={index}>
                                    <td>{change.fieldName}</td>
                                    <td>{change.originalValue}</td>
                                    <td>{change.newValue}</td>
                                </tr>)
                            })}
                        </tbody>
                    </Table>
               
                    <strong>Other Fields</strong>

                    <Table striped>
                        <thead>
                            <tr>
                                <th width="450px">Field Name</th>
                                <th>Original Value</th>
                                <th>New Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            {review.changes.changeList.filter(x => x.keyField === false).map((change, index) => {
                                return (<tr key={index}>
                                    <td>{change.fieldName}</td>
                                    <td>{change.originalValue}</td>
                                    <td>{change.newValue}</td>
                                </tr>)
                            })}
                        </tbody>
                    </Table>
                </div>

                <div className="formGroup">
                    <div className="groupTitle">Review</div>
                    
                    <FormGroup>
                        <Label for="note">Approval/Rejection Note:</Label>
                        {review.reviewStatus === 1 &&
                        <Input type="textarea" name="note" id="note" onChange={e => setNote(e.target.value)} />
                        }
                        {review.reviewStatus === 2 && 
                        <pre>{review.approvalNote}</pre>
                        }
                    </FormGroup>

                    {review.reviewStatus === 1 &&
                    <div>
                        <Button disabled={isEmpty(note)} color="primary" onClick={approveReview}>Approve</Button>
                        <Button disabled={isEmpty(note)} color="warning" onClick={rejectReview}>Reject</Button>
                    </div>}
                </div>
            </div>}
        </>
    )
}