import React, { useEffect, useState } from "react";
import { BASEAPI } from "../../constants";
import { useAuthState } from "../../contexts/AuthContext";
import { useServerErrorState } from "../../contexts/ServerErrorContext";
import { GetJsonWithToken } from "../../helpers/fetch_helpers";

export default function AdminLog() {
    let [logs, setLogs] = useState();
    
    let [authState, ] = useAuthState();
    let [, dispatchServerError] = useServerErrorState();

    async function getLogs() {
        var uri = BASEAPI + "/api/admin/logs/details";
        var result = await GetJsonWithToken(uri, null, authState.token)
        .catch(function(error) {
            console.log(error);
            dispatchServerError({type: "SET_ERROR", errorTitle: "Error getting log", errorMessage: error.message});
        });

        if (result.result) {
            setLogs(result.logList);
        }
    }

    useEffect(() => {
        getLogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div>
            <h1>Current Log</h1>
            {logs && 
            <table>
                <thead>
                    <tr>
                        <td>Time</td>
                        <td>Level</td>
                        <td>Logger</td>
                        <td>Message</td>
                        <td>Exception</td>
                    </tr>
                </thead>
                <tbody>
                    {logs.map((l, i) => {
                        return (
                            <tr key={i}>
                                <td>{l.loggedOn}</td>
                                <td>{l.level}</td>
                                <td>{l.logger}</td>
                                <td>{l.message}</td>
                                <td>{l.exception}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            }
        </div>
    )
}