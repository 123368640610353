import React, { useEffect, useState } from 'react';
import { Collapse, Container, DropdownItem, DropdownMenu, DropdownToggle, Navbar, NavbarBrand, NavbarText, NavbarToggler, NavItem, NavLink, UncontrolledDropdown } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import {GiHamburgerMenu} from "react-icons/gi";
import { GetJsonWithToken, PostJsonWithToken } from '../helpers/fetch_helpers';
import { useAuthState } from '../contexts/AuthContext';
import { BASEAPI } from '../constants';
import { useServerErrorState } from '../contexts/ServerErrorContext';
import logo from '../images/Chrisco_logo_underline_reg.png';

export function NavMenu() {
  const displayName = NavMenu.name;
  let [collapsed, setCollapsed] = useState(true);
  let [authState, dispatchAuth] = useAuthState();
  let [, dispatchServerError] = useServerErrorState();
  let [supplier, setSupplier] = useState();
  let [terms, setTerms] = useState();

  function toggleNavbar () {
    setCollapsed(!collapsed);
  }

  async function logout() {
    const uri = BASEAPI + "/api/account/signout"

    var result = await PostJsonWithToken(uri, null, authState.token)
                      .catch(function(error){
                          console.log(error);
                          dispatchServerError({type: "SET_ERROR",errorTitle:"Error Logging Out", errorMessage: error.message});
                      });

    if (result) {
      dispatchAuth({type: 'CLEAR'});
    }
  }

  async function getSupplier() {
    var uri = BASEAPI + "/api/supplier/" + authState.supplierId;

    var result = await GetJsonWithToken(uri, null, authState.token)
    .catch(function(error) {
        console.log(error);
        dispatchServerError({type: "SET_ERROR", errorTitle: "Error getting supplier", errorMessage: error.message});
    })
    
    setSupplier(result);
  }

  async function getTerms() {

    var uri = BASEAPI + "/api/term/";
    var result = await GetJsonWithToken(uri, null, authState.token)
    .catch(function(error){
        console.log(error);
        dispatchServerError({type: "SET_ERROR",errorTitle:"Error getting terms", errorMessage: error.message});
    })

    setTerms(result);
  }

  function getTermBuLabel(supplier) {
    if (terms && supplier)
    {
      var term = terms.find(x => x.termId === supplier.termId);
      var businessUnit = supplier.businessUnitId === 1 ? 'AU' : 'NZ';

      return businessUnit + '-' + term.termName;
    }
    else {
      return ''
    }
  }

  useEffect(() => {
    getTerms()
  }, [])

  useEffect(() => {
    if (authState.supplierId && authState.user && authState.user.isStaff) {
      getSupplier();
    }
  }, [authState.supplierId])

    return (
      <header>
        <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
          <Container>
            <NavbarBrand tag={Link} to="/"><img src={logo} alt="Chrisco" className="logo" />Supplier Portal</NavbarBrand>
            {authState.user && 
              <NavbarText>Signed in as {authState.user.firstName} {authState.user.lastName} </NavbarText>
            }
            
            <NavbarToggler onClick={toggleNavbar} className="mr-2" />
            <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!collapsed} navbar>
              <ul className="navbar-nav flex-grow">
                <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/">Home</NavLink>
                </NavItem>
                {/* <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/counter">Counter</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/fetch-data">Fetch data</NavLink>
                </NavItem> */}
                {authState.user && authState.user.isStaff && authState.user.firstName === "Graeme" && authState.user.lastName === "Finn" &&
                <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/admin/log">Log</NavLink>
                </NavItem>
                }
                {authState.user && authState.user.isStaff &&
                <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/skuReviews">Sku Reviews</NavLink>
                </NavItem>
                }
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav>
                    <GiHamburgerMenu />
                  </DropdownToggle>
                  <DropdownMenu right>
                    {authState.user && authState.user.isStaff && authState.supplierId &&
                      <DropdownItem tag={Link} to="/selectSupplier">
                        Change Supplier
                      </DropdownItem>
                    }
                    <DropdownItem tag={Link} to="/changePassword">
                      Change Password
                    </DropdownItem>
                    <DropdownItem onClick={logout}>
                      Logout
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </ul>
            </Collapse>
          </Container>
        </Navbar>
        {supplier && <NavbarText className="selectedSupplier">Current Supplier: {supplier.supplierName} [{getTermBuLabel(supplier)}] </NavbarText>}
      </header>
    );
}
