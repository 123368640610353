import React, { createRef, useEffect, useState } from "react";
import { FaTrashAlt } from "react-icons/fa";
import { Button, ListGroup, ListGroupItem, Spinner } from "reactstrap";
import "./sku.css";

export default function SkuList({skus, selectedSkuChange, isDisabled, setSkuId, updatedSkus, allowDelete}) {
    let [allSkus, setSkus] = useState();
    let [selectedSkuId, setSelectedSkuId] = useState();
    let [disableItems, setDisableItems] = useState(isDisabled);

    const refs = skus ? skus.reduce((sku, value) => {
        sku[value.skuId] = createRef();

        return sku;
    }, {}) : []

    useEffect(() => {
        setSkus(skus);
    }, [skus])

    useEffect(() => {
        setDisableItems(isDisabled);
    }, [isDisabled])

    useEffect(() => {
        if (setSkuId) {
            setSelectedSkuId(setSkuId);
            //selectedSkuChange(setSkuId);
        }
    }, [setSkuId])

    useEffect(() => {
        if (selectedSkuId)
            scrollToActive(selectedSkuId);
    })


    function scrollToActive(activeSkuId) {
        var index = skus ? skus.findIndex(x => x.skuId == activeSkuId) : -1;
        if (index > -1)
        {
            var node = refs[activeSkuId].current;
            if (node) {
                node.scrollIntoView({block: 'nearest', behavior: 'smooth'});
            }
        }
    }

    function changeSelectedSku(skuId) {
        setSelectedSkuId(skuId);
        selectedSkuChange(skuId);
    }

    function removeSku(skuId, skuName) {
        if (window.confirm(skuName + " will be deleted.\n\nTo continue, click OK.\nTo abort, click Cancel.")) {
            // var index = allSkus.findIndex(x => x.skuId == skuId);
            // if (index > -1)
            // {
            //     allSkus.splice(index, 1);
            //     var skus = allSkus.map((sku) => sku);
            //     setSkus(skus);
            //     updatedSkus(skus);
            // }
            var sku = allSkus.find(x => x.skuId == skuId);
            if (sku) {
                sku.isDeleted = true;
                var skus = allSkus.map((sku) => sku);
                setSkus(skus);
                updatedSkus(skus);
            }
        }
    }

    return (
    <>
    {allSkus ?
    <ListGroup className="productList" >
        {allSkus.map((sku, index) => {
            if (!sku.isDeleted) {
                return (
                    <div ref={refs[sku.skuId]}>
                        <ListGroupItem  disabled={disableItems} active={selectedSkuId === sku.skuId} key={index} tag="button" action onClick={() => changeSelectedSku(sku.skuId)}>
                            {sku.itemDescription}
                            {allowDelete && <div className="topButtonsSkuDelete"><Button size="sm" color="danger" onClick={() => removeSku(sku.skuId, sku.itemDescription)}><FaTrashAlt /></Button></div>}
                        </ListGroupItem>
                    </div>
                )
            }
        })
    }
    
    </ListGroup> : <Spinner />}
    </>
    )
}