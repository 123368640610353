import ReactDOM from "react-dom";
import React, { createRef, useEffect, useRef, useState } from "react";
import { Button, List, ListGroup, ListGroupItem, Spinner } from "reactstrap";
import {FaExclamationCircle, FaTrashAlt} from "react-icons/fa";
import "./product.css";
import { isProductValid } from "../../helpers/validators";
import ErrorPopover from "../Utility/ErrorPopover";

export default function ProductList({products, selectedProductChange, isDisabled, setProductId, updatedProducts}) {
    let [allProducts, setProducts] = useState();
    let [selectedProductId, setSelectedProductId] = useState();
    let [disableItems, setDisableItems] = useState(isDisabled);

    const refs = products ? products.reduce((product, value) => {
        product[value.supplierProductId] = createRef();

        return product;
    }, {}) : []

    useEffect(() => {
        setProducts(products);
    }, [products])

    useEffect(() => {
        setDisableItems(isDisabled);
    }, [isDisabled])

    useEffect(() => {
        if (setProductId) {
            setSelectedProductId(setProductId);
            selectedProductChange(setProductId);
        }
    }, [setProductId])

    useEffect(() => {
        if (selectedProductId)
            scrollToActive(selectedProductId);
    })


    function scrollToActive(activeProductId) {
        var index = products ? products.findIndex(x => x.supplierProductId == activeProductId) : -1;
        if (index > -1)
        {
            var node = refs[activeProductId].current;
            if (node) {
                node.scrollIntoView({block: 'nearest', behavior: 'smooth'});
            }
        }
    }

    function changeSelectedProduct(productId) {
        setSelectedProductId(productId);
        selectedProductChange(productId);
    }

    function removeProduct(productId, productName) {
        if (window.confirm(productName + " will be deleted.\n\nTo continue, click OK.\nTo abort, click Cancel.")) {
            var index = allProducts.findIndex(x => x.supplierProductId == productId);
            if (index > -1)
            {
                allProducts.splice(index, 1);
                var products = allProducts.map((product) => product);
                setProducts(products);
                updatedProducts(products);
            }
        }
    }

    return (
        <>
        {allProducts ?
        <ListGroup className="productList" >
            {allProducts.map((product, index) => {
                var errorId = "productList" + product.supplierProductId;

                isProductValid(product);

                return (
                    <div ref={refs[product.supplierProductId]}>
                        <ListGroupItem  disabled={disableItems} active={selectedProductId === product.supplierProductId} key={index} tag="button" action onClick={() => changeSelectedProduct(product.supplierProductId)}>
                            {product.errorMessages && product.errorMessages.length > 0 &&
                                <ErrorPopover errorId={errorId} header="Product Errors" errorMessages={product.errorMessages} />
                            }
                            {product.productProperties.itemDescription}
                            <div className="topButtonsProductDelete"><Button size="sm" color="danger" onClick={() => removeProduct(product.supplierProductId, product.productProperties.itemDescription)}><FaTrashAlt /> </Button></div>
                        </ListGroupItem>
                        
                    </div>
                )
            })
        }
        
        </ListGroup> : <Spinner />}
        </>
    )
}