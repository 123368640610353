import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { BASEAPI } from "../../constants";
import { useAuthState } from "../../contexts/AuthContext";
import { useServerErrorState } from "../../contexts/ServerErrorContext";
import { GetJsonWithToken, PostJsonWithToken } from "../../helpers/fetch_helpers";
import TradingTermsList from "./TradingTerms/TradingTermsList";
import "./tradingTerms.css";
import { useHistory, useParams } from "react-router-dom";

export default function TradingTerms() {
    let { requestId } = useParams();
    let [authState, dispatchAuth] = useAuthState();
    let [serverErrorState, dispatchServerError] = useServerErrorState();
    let [tradingTerms, setTradingTerms] = useState();
    let [showConfirm, setShowConfirm] = useState(false);
    let history = useHistory();

    function openModal() {
        setShowConfirm(true);
    }

    function closeModal() {
        setShowConfirm(false);
    }

    async function submitResponse() {
        const uri = BASEAPI + "/api/supplierrequest/SubmitTradingTerm/" + requestId;
        var result = await PostJsonWithToken(uri, null, authState.token)
        .catch(function (error) {
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error Submitting Trading Term Response", errorMessage: error.message});
        })

        if (result.result) {
            //redirect to home?
            setShowConfirm(false);
            history.push("/");
        }
        else {
            setShowConfirm(false);
            dispatchServerError({type: "SET_ERROR", errorTitle:"Error Submitting Trading Term Response", errorMessage: "Could not submit Trading Term Response, please contact Chrisco Representative"})
        }
    }

    async function getTradingTerms() {
        if (authState.user) {
            var supplierId = authState.user.isStaff ? authState.supplierId : authState.user.supplierId;

            const uri = BASEAPI + "/api/suppliertradingterm/" + supplierId;
            var result = await GetJsonWithToken(uri, null, authState.token)
            .catch(function(error){
                console.log(error);
                dispatchServerError({type: "SET_ERROR",errorTitle:"Error retrieving trading terms", errorMessage: error.message});
            })

            setTradingTerms(result);
        }
    }

    useEffect(() => {
        getTradingTerms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authState.user, requestId])

    return (
        <>
            <div>
                {tradingTerms && 
                <>
                    <h3>
                        Trading Terms
                    </h3>
                    <TradingTermsList tradingTerms={tradingTerms} />
                    <Button className="ackBtn" color="primary" onClick={openModal}>Acknowledge</Button>
                </>
                }
            </div>
            <Modal isOpen={showConfirm}>
                <ModalHeader>Acknowledge Trading Terms</ModalHeader>
                <ModalBody>You are going to accept the supplier agreement and the associated trading terms as specified in this form.<br /><br />
                To continue, click OK.<br /><br />
                To abort, click Cancel.</ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={submitResponse}>Confirm</Button>
                    <Button color="secondary" onClick={closeModal}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </>
    )
}