import React from "react";
import { Button } from "reactstrap";
import AddressView from "../Address/AddressView";
import { FaAddressCard, FaCross, FaFax, FaMobileAlt, FaPhoneAlt, FaTimes, FaTrashAlt } from "react-icons/fa";
import "./contact.css";
import { displayPhoneNo, displayYesNo } from "../../helpers/formatting";
import { MdEdit, MdEmail } from "react-icons/md";
import { BiBuildingHouse } from "react-icons/bi";
import { ContactTypeEnum } from "../../helpers/enums";

export default function ContactView({contact, editContact, removeContact}) {

    return (
    <div className="contactContainer">
        {contact && 
        <div>
            <div className="topButtons">
                <Button size="sm" onClick={() => editContact(contact.contactId)}><MdEdit />Edit</Button>
                <Button size="sm" color="danger" onClick={() => removeContact(contact.contactId)}><FaTrashAlt /></Button>
            </div>
            <div className="contactName">{contact.firstName} {contact.lastName}</div>
            <div>{contact.jobTitle}</div>
            {contact.address ? 
                <div>
                    <BiBuildingHouse /> <AddressView address={contact.address} />
                </div> 
                : 
                <div><BiBuildingHouse /></div>
            }
            <div className="contactLineWithHeader"><FaPhoneAlt />{displayPhoneNo(contact.phoneAreaCode,contact.phone)}</div>
            {contact.fax ? <div><FaFax />{displayPhoneNo(contact.faxAreaCode, contact.fax)}</div> : null}
            <div><FaMobileAlt />{contact.mobile}</div>
            <div><MdEmail /> {contact.email}</div>
            {contact.contactType === ContactTypeEnum.Finance ? <div>Is Remittance Advices Email Recipient? {contact.messagePreferences ? displayYesNo(contact.messagePreferences.receiveRemmittanceAdviceEmail) : ''} </div> : null}
        </div>
    }   
    </div>)
}