import { sk } from "date-fns/locale";
import React, { useEffect, useState } from "react";
import { BsDot } from "react-icons/bs";
import { Alert, Button, Form, FormFeedback, FormGroup, FormText, Input, Label } from "reactstrap";
import { DEFAULT_SELECT } from "../../constants";
import { OrderLevelEnum, OrderLevelEnumLabel, StockingUnitEnum, StockingUnitEnumLabel } from "../../helpers/enums";
import { uuidv4 } from "../Utility/guid";
import SkuList from "./SkuList";
import "./sku.css";
import { stripCommasAndQuotes } from "../../helpers/utils";

export default function SkuEdit({sku, skuUpdated, cancelUpdate, confirmedSkuList}) {
    
    const PALLET_HEIGHT_VALUE_MAXIMUM_DIFFERENCE = 100;
    const PALLET_WEIGHT_VALUE_MAXIMUM_DIFFERENCE = 13;
    const STANDARD_PALLET_WEIGHT = 46.5; //kg
    const STANDARD_PALLET_HEIGHT = 150; //mm

    let [skuId, setSkuId] = useState(sku ? sku.skuId : uuidv4());
    let [itemCode, setItemCode] = useState(sku ? sku.itemCode : '');
    let [businessUnitId, setBusinessUnitId] = useState(sku ? sku.businessUnitId : -1);
    let [isConfirmed, setIsConfirmed] = useState(sku ? sku.isConfirmed : null);

    let [itemDescription, setItemDescription] = useState(sku ? sku.itemDescription : '');
    let [unitSize, setUnitSize] = useState(sku ? sku.unitSize : '');
    let [stockingUnit, setStockingUnit] = useState(sku ? (sku.businessUnitId == 1 ? sku.stockingUnitId : 2) : '');
    let [stockingQuantity, setStockingQuantity] = useState(sku ? sku.stockingQty : '');
    let [supplierProductCode, setSupplierProductCode] = useState(sku ? sku.supplierProductCode : '');
    let [modelNumber, setModelNumber] = useState(sku ? sku.modelNumber : '')
    let [hasBarcodeGTIN13, setHasBarcodeGTIN13] = useState(sku ? sku.hasBarcodeGTIN13 : null);
    let [barcodeGTIN13, setBarcodeGTIN13] = useState(sku ? sku.barcodeGTIN13 : '');
    let [hasBarcodeGTIN14, setHasBarcodeGTIN14] = useState(sku ? sku.hasBarcodeGTIN14 : null);
    let [barcodeGTIN14, setBarcodeGTIN14] = useState(sku ? sku.barcodeGTIN14 : '');
    let [minimumOrderLevel, setMinimumOrderLevel] = useState(sku ? sku.minimumOrderLevel : '');
    let [minimumOrderQuantity, setMinimumOrderQuantity] = useState(sku ? sku.minimumOrderQuantity : '');
    let [maximumOrderQuantity, setMaximumOrderQuantity] = useState(sku ? sku.maximumOrderQuantity : '');
    let [leadTime, setLeadTime] = useState(sku ? sku.leadTime : '');
    let [shelfLife, setShelfLife] = useState(sku ? sku.shelfLife : '');
    let [warrantyPeriod, setWarrantyPeriod] = useState(sku ? sku.warrantyPeriod : '');
    let [warrantyContactPhoneNumber, setWarrantyContactPhoneNumber] = useState(sku ? sku.warrantyContactPhoneNumber : '');
    let [retailCartonHeight, setRetailCartonHeight] = useState(sku ? sku.retailUnitHeight : '');
    let [retailCartonWidth, setRetailCartonWidth] = useState(sku ? sku.retailUnitWidth : '');
    let [retailCartonLength, setRetailCartonLength] = useState(sku ? sku.retailUnitLength : '');
    let [retailCartonWeight, setRetailCartonWeight] = useState(sku ? sku.unitWeight : '');
    let [palletHeight, setPalletHeight] = useState(sku ? sku.palletHeight : '');
    let [palletLength, setPalletLength] = useState(sku ? sku.palletDepth : '');
    let [palletWidth, setPalletWidth] = useState(sku ? sku.palletWidth : '');
    let [palletWeight, setPalletWeight] = useState(sku ? sku.palletWeight : '');
    let [isPalletHeightOverride, setIsPalletHeightOverride] = useState(sku ? sku.isPalletHeightOverride : '');
    let [isPalletWeightOverride, setIsPalletWeightOverride] = useState(sku ? sku.isPalletWeightOverride : '');
    let [layersPerPallet, setLayersPerPallet] = useState(sku ? sku.layersPerPallet : '');
    let [cartonsPerLayer, setCartonsPerLayer] = useState(sku ? sku.cartonsPerLayer : '');
    let [masterCartonHeight, setMasterCartonHeight] = useState(sku ? sku.masterCartonHeight : '');
    let [masterCartonWidth, setMasterCartonWidth] = useState(sku ? sku.masterCartonWidth : '');
    let [masterCartonLength, setMasterCartonLength] = useState(sku ? sku.masterCartonLength : '');
    let [masterCartonWeight, setMasterCartonWeight] = useState(sku ? sku.masterCartonWeight : '');

    let [showPalletHeightOverride, setShowPalletHeightOverride] = useState(false);
    let [showPalletWeightOverride, setShowPalletWeightOverride] = useState(false);
    
    let [copySelectedSkuId, setCopySelectedSkuId] = useState(null);
    
    let [copyUnit, setCopyUnit] = useState(false);
    let [copyPallet, setCopyPallet] = useState(false);
    let [copyCarton, setCopyCarton] = useState(false);
    
    useEffect(() => {
        determineShowHeightOverride();
    }, [palletHeight, masterCartonHeight, layersPerPallet])

    useEffect(() => {
        determineShowWeightOverride();
    }, [palletWeight, masterCartonWeight, layersPerPallet, cartonsPerLayer])


    function determineShowHeightOverride() {
        var difference = 0;

        if (masterCartonHeight != null && layersPerPallet != null && palletHeight != null) {
            var allowedValue = masterCartonHeight * layersPerPallet + STANDARD_PALLET_HEIGHT;
            difference = Math.abs(allowedValue - palletHeight);
        }

        setShowPalletHeightOverride(difference >= PALLET_HEIGHT_VALUE_MAXIMUM_DIFFERENCE);
    }

    function determineShowWeightOverride() {
        var difference = 0;
        
        if (masterCartonWeight != null && layersPerPallet != null && cartonsPerLayer != null && palletWeight != null) {
            var allowedValue = masterCartonWeight * layersPerPallet * cartonsPerLayer + STANDARD_PALLET_WEIGHT;
            difference = Math.abs(allowedValue - palletWeight);
        }

        setShowPalletWeightOverride(difference >= PALLET_WEIGHT_VALUE_MAXIMUM_DIFFERENCE);
    }

    function autoCalculatePalletWeight() {
        var pWeight = masterCartonWeight * layersPerPallet * cartonsPerLayer + STANDARD_PALLET_WEIGHT;

        setPalletWeight(pWeight);
    }

    function autoCalculatePalletHeight() {
        var pHeight = masterCartonHeight * layersPerPallet + STANDARD_PALLET_HEIGHT;

        setPalletHeight(pHeight);
    }

    function buildOrderLevelOptions() {
        var options = [];
        options.push(<option key={-1} value="-1">{DEFAULT_SELECT}</option>)

        var entries = Object.entries(OrderLevelEnum);

        for (var i = 0; i < entries.length; i++)
        {
            var key = parseInt(entries[i][1]);

            var opt = <option key={key} value={key}>{OrderLevelEnumLabel(key)}</option>;

            options.push(opt);
        }

        return options;
    }

    function buildStockingUnitOptions() {
        var options = [];
        options.push(<option key={-1} value="-1">{DEFAULT_SELECT}</option>)

        var entries = Object.entries(StockingUnitEnum);

        for (var i = 0; i < entries.length; i++)
        {
            var key = parseInt(entries[i][1]);

            var opt = <option key={key} value={key}>{StockingUnitEnumLabel(key)}</option>;

            options.push(opt);
        }

        return options;
    }

    function BuildSkuOptions() {
        var options = [];
        options.push(<option key={-1} value="-1">{DEFAULT_SELECT}</option>)
        
        confirmedSkuList.map((sku, index) => {
            options.push(<option key={index} value={sku.skuId}>{sku.itemDescription}</option>)
        })

        return options;
    }

    function isValid() {
        if (!itemDescription || itemDescription.length <= 0)
            return false;
        
        if (!supplierProductCode || supplierProductCode.length <= 0)
            return false;

        if (hasBarcodeGTIN14 && !barcodeGTIN14)
            return false;
        
        if (barcodeGTIN14 && barcodeGTIN14.length > 14)
            return false;

        if (hasBarcodeGTIN13 && !barcodeGTIN13)
            return false;

        if (barcodeGTIN13 && barcodeGTIN13.length > 13)
            return false;

        if (!leadTime)
            return false;

        if (businessUnitId == 2 && !shelfLife)
            return false;

        if (businessUnitId == 2 && !unitSize)
            return false;

        if (stockingUnit == StockingUnitEnum.PK && !stockingQuantity)
            return false;

        if (minimumOrderLevel == OrderLevelEnum.MOQ)
        {
            if (!minimumOrderQuantity)
                return false;
        }

        if (showPalletHeightOverride && !isPalletHeightOverride)
            return false;

        if (showPalletWeightOverride && !isPalletWeightOverride)
            return false;

        return true;
    }

    function update() {
        var sku = {
            skuId: skuId,
            itemCode: itemCode,
            itemDescription: itemDescription,
            isConfirmed: isConfirmed,
            stockingUnitId: stockingUnit,
            stockingQty: stockingQuantity,
            supplierProductCode: supplierProductCode,
            modelNumber: modelNumber,
            hasBarcodeGTIN13: hasBarcodeGTIN13,
            barcodeGTIN13: barcodeGTIN13,
            hasBarcodeGTIN14: hasBarcodeGTIN14,
            barcodeGTIN14: barcodeGTIN14,
            maximumOrderQuantity: maximumOrderQuantity,
            leadTime: leadTime,
            shelfLife: shelfLife,
            warrantyPeriod: warrantyPeriod,
            warrantyContactPhoneNumber: warrantyContactPhoneNumber,
            retailUnitHeight: retailCartonHeight,
            retailUnitWidth: retailCartonWidth,
            retailUnitLength: retailCartonLength,
            unitWeight: retailCartonWeight,
            palletHeight: palletHeight,
            palletWidth: palletWidth,
            palletDepth: palletLength,
            palletWeight: palletWeight,
            isPalletHeightOverride: isPalletHeightOverride,
            isPalletWeightOverride: isPalletWeightOverride,
            layersPerPallet: layersPerPallet,
            cartonsPerLayer: cartonsPerLayer,
            masterCartonHeight: masterCartonHeight,
            masterCartonLength: masterCartonLength,
            masterCartonWidth: masterCartonWidth,
            masterCartonWidth: masterCartonWidth
        }

        skuUpdated(sku);
    }

    function orderLevelChanged(e) {
        setMinimumOrderLevel(e.target.value);
        if (e.target.value !== OrderLevelEnum.MOQ) {
            setMinimumOrderQuantity(null);
        }
    }

    function copyDimensions() {
        var skuToCopyFrom = confirmedSkuList.find(x => x.skuId == copySelectedSkuId);

        if (copyUnit) {
            setRetailCartonHeight(skuToCopyFrom.retailUnitHeight);
            setRetailCartonWidth(skuToCopyFrom.retailCartonWidth);
            setRetailCartonLength(skuToCopyFrom.retailUnitLength);
            setRetailCartonWeight(skuToCopyFrom.retailCartonWeight);
        }

        if (copyCarton) {
            setMasterCartonHeight(skuToCopyFrom.masterCartonHeight);
            setMasterCartonWidth(skuToCopyFrom.masterCartonWidth);
            setMasterCartonLength(skuToCopyFrom.masterCartonLength);
            setMasterCartonWeight(skuToCopyFrom.masterCartonWeight);
            setCartonsPerLayer(skuToCopyFrom.cartonsPerLayer);
        }

        if (copyPallet) {
            setPalletHeight(skuToCopyFrom.palletHeight);
            setPalletWidth(skuToCopyFrom.palletWidth);
            setPalletLength(skuToCopyFrom.palletLength);
            setPalletWeight(skuToCopyFrom.palletWwight);
            setLayersPerPallet(skuToCopyFrom.layersPerPallet);
        }
    }

    return (
    <div>
        <h4 className="productTitle">{itemDescription ? itemDescription : itemCode} - {isConfirmed ? "Confirmed" : "To Be Confirmed"}</h4>

        <Form className="formGroup">
                <div className="groupTitle">Product Identification &amp; Detail</div>
                <Alert color="light">Required Fields <BsDot color="red" /></Alert>

                <FormGroup row>
                    <Label for="itemCode">Item Code</Label>
                    <Input type="text" readOnly={true} value={sku.itemCode} />
                </FormGroup>
                <FormGroup row>
                    <Label for="itemDescription">Item Description<BsDot color="red" /></Label>
                    <Input type="text" id="itemDescription" name="itemDescription" value={itemDescription} maxLength="100" onChange={e => setItemDescription(stripCommasAndQuotes(e.target.value))} invalid={!itemDescription || itemDescription.length <= 0} />
                    <FormFeedback>Please enter a product description</FormFeedback>
                </FormGroup>
                <FormGroup row>
                    <Label for="unitSize">Unit Size{businessUnitId == 2 && <BsDot color="red" />}</Label>
                    <Input type="text" id="unitSize" name="unitSize" value={unitSize} onChange={e => setUnitSize(e.target.value)} invalid={businessUnitId == 2 && !unitSize} />
                    <FormFeedback>Please enter a unit size</FormFeedback>
                </FormGroup>
                {sku && sku.businessUnitId == 1 &&
                <>
                    <FormGroup row>
                        <Label for="stockingUnit">Stocking Unit<BsDot color="red" /></Label>
                        <Input type="select" id="stockingUnit" name="stockingUnit" value={stockingUnit} onChange={e => setStockingUnit(e.target.value)} invalid={!stockingUnit || stockingUnit < 0}>
                            {buildStockingUnitOptions()}
                        </Input>
                        <FormFeedback>Please select a Stocking Unit</FormFeedback>
                    </FormGroup>
                    {stockingUnit == StockingUnitEnum.PK &&
                    <FormGroup row>
                        <Label for="stockingQuantity">Stocking Quantity{stockingUnit == StockingUnitEnum.PK && <BsDot color="red" />}</Label>
                        <Input type="text" id="stockingQuantity" name="stockingQuantity" value={stockingQuantity} onChange={e => setStockingQuantity(e.target.value)} invalid={stockingUnit == StockingUnitEnum.PK && !stockingQuantity} />
                        <FormFeedback>Please enter a Stocking Quantity value</FormFeedback>
                    </FormGroup>
                    }
                </>
                }
                <FormGroup row>
                    <Label for="supplierProductCode">Supplier Product Code<BsDot color="red" /></Label>
                    <Input type="text" id="supplierProductCode" name="supplierProductCode" value={supplierProductCode} onChange={e => setSupplierProductCode(stripCommasAndQuotes(e.target.value))} invalid={!supplierProductCode} />
                    <FormFeedback>Please enter Product Code</FormFeedback>
                </FormGroup>
                {sku && sku.businessUnitId == 1 &&
                    <FormGroup row>
                        <Label for="has">Model Number</Label>
                        <Input type="text" id="modelNumber" name="modelNumber" value={modelNumber} onChange={e => setModelNumber(stripCommasAndQuotes(e.target.value))} />
                    </FormGroup>
                }
                <FormGroup row>
                    <Label for="hasBarcodeGTIN13">New SKU, No EAN<BsDot color="red" /></Label>
                    <Input type="checkbox" id="hasBarcodeGTIN13" name="hasBarcodeGTIN13" value={hasBarcodeGTIN13} onChange={e => setHasBarcodeGTIN13(!hasBarcodeGTIN13)} invalid={hasBarcodeGTIN13 == null} />
                    <FormFeedback>Please select if the product has a GTIN 13 (EAN) Barcode</FormFeedback>
                </FormGroup>
                {hasBarcodeGTIN13 && 
                <FormGroup row>
                    <Label for="barcodeGTIN13">GTIN 13 (EAN)<BsDot color="red" /></Label>
                    <Input type="number" id="barcodeGTIN13" name="barcodeGTIN13" value={barcodeGTIN13} onChange={e => setBarcodeGTIN13(e.target.value)} invalid={!barcodeGTIN13 || barcodeGTIN13.length > 13} />
                    <FormFeedback>Please enter valid GTIN 13 (EAN) Barcode</FormFeedback>
                </FormGroup>
                }
                 <FormGroup row>
                    <Label for="hasBarcodeGTIN14">New SKU, No TUN<BsDot color="red" /></Label>
                    <Input type="checkbox" id="hasBarcodeGTIN14" name="hasBarcodeGTIN14" value={hasBarcodeGTIN14} onChange={e => setHasBarcodeGTIN14(!hasBarcodeGTIN14)} invalid={hasBarcodeGTIN14 == null} />
                    <FormFeedback>Please select if the product has a GTIN 14 (TUN) Barcode</FormFeedback>
                </FormGroup>
                {hasBarcodeGTIN14 && 
                <FormGroup row>
                    <Label for="barcodeGTIN14">GTIN 14 (TUN)<BsDot color="red" /></Label>
                    <Input type="number" id="barcodeGTIN14" name="barcodeGTIN14" value={barcodeGTIN14} onChange={e => setBarcodeGTIN14(e.target.value)} invalid={!barcodeGTIN14 || barcodeGTIN14.length > 14} />
                    <FormFeedback>Please enter valid GTIN 14 (TUN) Barcode</FormFeedback>
                </FormGroup>
                }
        </Form>
        <Form className="formGroup">
            <div className="groupTitle">Ordering Details</div>

            <FormGroup row>
                <Label for="minimumOrderLevel">Minimum Order Level</Label>
                <Input type="select" id="minimumOrderLevel" name="minimumOrderLevel" value={minimumOrderLevel} onChange={e => orderLevelChanged(e)}>
                    {buildOrderLevelOptions()}
                </Input>
            </FormGroup>
            {minimumOrderLevel == OrderLevelEnum.MOQ && 
            <FormGroup row>
                <Label for="minimumOrderQuantity">Minimum Order Quantity</Label>
                <Input type="text" id="minimumOrderQuantity" name="minimumOrderQuantity" value={minimumOrderQuantity} onChange={e => setMinimumOrderQuantity(e.target.value)} invalid={minimumOrderLevel == OrderLevelEnum.MOQ && !minimumOrderQuantity} />
                <FormFeedback>Please enter Minimum Order Quantity</FormFeedback>
            </FormGroup>
            }
            <FormGroup row>
                <Label for="maximumOrderQuantity">Maximum Order Quantity</Label>
                <Input type="text" id="maximumOrderQuantity" name="maximumOrderQuantity" value={maximumOrderQuantity} onChange={e => setMaximumOrderQuantity(e.target.value)} />
            </FormGroup>
            <FormGroup row>
                <Label for="leadTime">Lead Time (Days)<BsDot color="red" /></Label>
                <Input type="text" id="leadTime" name="leadTime" value={leadTime} onChange={e => setLeadTime(e.target.value)} invalid={!leadTime} />
                <FormFeedback>Please enter how much lead time is required</FormFeedback>
            </FormGroup>
            <FormGroup row>
                <Label for="shelfLife">Shelf Life (days){businessUnitId == 2 && <BsDot color="red" />}</Label>
                <Input type="text" id="shelfLife" name="shelfLife" value={shelfLife} onChange={e => setShelfLife(e.target.value)} invalid={businessUnitId == 2 && !shelfLife} />
                <FormFeedback>Please enter how the shelf life of product</FormFeedback>
            </FormGroup>
            <FormGroup row>
                <Label for="warrantyPeriod">Warranty Period (Days)</Label>
                <Input type="text" id="warrantyPeriod" name="warrantyPeriod" value={warrantyPeriod} onChange={e => setWarrantyPeriod(e.target.value)} />
            </FormGroup>
            <FormGroup row>
                <Label for="warrantyContactPhoneNumber">Warranty Phone #</Label>
                <Input type="text" id="warrantyContactPhoneNumber" name="warrantyContactPhoneNumber" value={warrantyContactPhoneNumber} onChange={e => setWarrantyContactPhoneNumber(e.target.value)} />
            </FormGroup>
        </Form>
        <Form className="formGroup">
            <div className="groupTitle">Dimensions</div>

            <FormGroup>
                <Label for="retailCartonHeight"><BsDot color="red" /> Unit Height (mm)</Label>
                <Input type="number" min="0" max="999999" step="0.01" id="retailCartonHeight" value={retailCartonHeight} onChange={e => setRetailCartonHeight(e.target.value)} invalid={!retailCartonHeight} />
                <FormText>Top to Bottom</FormText>
                <FormFeedback>Please enter height of unit (mm)</FormFeedback>
            </FormGroup>
            <FormGroup>
                <Label for="retailCartonWidth"><BsDot color="red" /> Unit Width (mm)</Label>
                <Input type="number" min="0" max="999999" step="0.01" id="retailCartonWidth" value={retailCartonWidth} onChange={e => setRetailCartonWidth(e.target.value)} invalid={!retailCartonWidth} />
                <FormText>Left to Right</FormText>
                <FormFeedback>Please enter width of unit (mm)</FormFeedback>
            </FormGroup>
            <FormGroup>
                <Label for="retailCartonLength"><BsDot color="red" /> Unit Length (mm)</Label>
                <Input type="number" min="0" max="999999" step="0.01" id="retailCartonLength" value={retailCartonLength} onChange={e => setRetailCartonLength(e.target.value)} invalid={!retailCartonLength} />
                <FormText>Front to Back</FormText>
                <FormFeedback>Please enter length of unit (mm)</FormFeedback>
            </FormGroup>
            <FormGroup>
                <Label for="retailCartonWeight"><BsDot color="red" /> Unit Weight (kg)</Label>
                <Input type="number" min="0" max="999999" step="0.01" id="retailCartonWeight" value={retailCartonWeight} onChange={e => setRetailCartonWeight(e.target.value)} invalid={!retailCartonWeight} />
                <FormFeedback>Please enter weight of unit (kg)</FormFeedback>
            </FormGroup>
        </Form>
        <Form className="formGroup">
            <div className="groupTitle">Carton</div>

            <FormGroup>
                <Label for="cartonHeight"><BsDot color="red" /> Carton Height (mm)</Label>
                <Input type="number" min="0" max="999999" step="0.01" id="cartonHeight" value={masterCartonHeight} onChange={e => { setMasterCartonHeight(e.target.value); autoCalculatePalletHeight(); } } invalid={!masterCartonHeight} />
                <FormText>Top to Bottom</FormText>
                <FormFeedback>Please enter Carton Height (mm)</FormFeedback>
            </FormGroup>
            <FormGroup>
                <Label for="cartonWidth"><BsDot color="red" /> Carton Width (mm)</Label>
                <Input type="number" min="0" max="999999" step="0.01" id="cartonWidth" value={masterCartonWidth} onChange={e => setMasterCartonWidth(e.target.value)} invalid={!masterCartonWidth} />
                <FormText>Left to Right</FormText>
                <FormFeedback>Please enter Carton Width (mm)</FormFeedback>
            </FormGroup>
            <FormGroup>
                <Label for="cartonLength"><BsDot color="red" /> Carton Length (mm)</Label>
                <Input type="number" min="0" max="999999" step="0.01" id="cartonLength" value={masterCartonLength} onChange={e => setMasterCartonLength(e.target.value)} invalid={!masterCartonLength} />
                <FormText>Front to Back</FormText>
                <FormFeedback>Please enter Carton Length (mm)</FormFeedback>
            </FormGroup>
            <FormGroup>
                <Label for="cartonWeight"><BsDot color="red" /> Carton Weight (kg)</Label>
                <Input type="number" min="0" max="999999" step="0.01" id="cartonWeight" value={masterCartonWeight} onChange={e => { setMasterCartonWeight(e.target.value); autoCalculatePalletWeight() }} invalid={!masterCartonWeight} />
                <FormFeedback>Please enter Carton Weight (kg)</FormFeedback>
            </FormGroup>
        </Form>
        <Form className="formGroup">
            <div className="groupTitle">Pallet</div>

            <FormGroup>
                <Label for="palletHeight"><BsDot color="red" /> Pallet Height (mm)</Label>
                <Input type="number" min="0" max="999999" step="0.01" id="palletHeight" value={palletHeight} onChange={e => setPalletHeight(e.target.value)} invalid={!palletHeight} />
                <FormText>Top to Bottom</FormText>
                <FormFeedback>Please enter height of Pallet (mm)</FormFeedback>
            </FormGroup>
            {showPalletHeightOverride &&
            <FormGroup check inline className="overrideNote">
                    <Label check for="isPalletHeightOverride"><BsDot color="red" /> Pallet height not in calculated range. Override?</Label>
                    <Input type="checkbox" id="isPalletHeightOverride" name="isPalletHeightOverride" value={isPalletHeightOverride} onChange={() => setIsPalletHeightOverride(!isPalletHeightOverride)} />
            </FormGroup>
            }
            <FormGroup>
                <Label for="palletWidth"><BsDot color="red" /> Pallet Width (mm)</Label>
                <Input type="number" min="0" max="999999" step="0.01" id="palletWidth" value={palletWidth} onChange={e => setPalletWidth(e.target.value)} invalid={!palletWidth} />
                <FormText>Left to Right</FormText>
                <FormFeedback>Please enter width of Pallet (mm)</FormFeedback>
            </FormGroup>
            <FormGroup>
                <Label for="palletLength"><BsDot color="red" /> Pallet Length (mm)</Label>
                <Input type="number" min="0" max="999999" step="0.01" id="palletLength" value={palletLength} onChange={e => setPalletLength(e.target.value)} invalid={!palletLength} />
                <FormText>Front to Back</FormText>
                <FormFeedback>Please enter length of Pallet (mm)</FormFeedback>
            </FormGroup>
            <FormGroup>
                <Label for="palletWeight"><BsDot color="red" /> Pallet Weight (kg)</Label>
                <Input type="number" min="0" max="999999" step="0.01" id="palletWeight" value={palletWeight} onChange={e => setPalletWeight(e.target.value)} invalid={!palletWeight} />
                <FormFeedback>Please enter weight of Pallet (kg)</FormFeedback>
            </FormGroup>
            {showPalletWeightOverride &&
            <FormGroup check inline className="overrideNote">
                    <Label check for="isPalletWeightOverride"><BsDot color="red" /> Pallet weight not in calculated range. Override?</Label>
                    <Input type="checkbox" id="isPalletWeightOverride" name="isPalletWeightOverride" value={isPalletWeightOverride} onChange={() => setIsPalletWeightOverride(!isPalletWeightOverride)} />
            </FormGroup>
            }
             <FormGroup>
                <Label for="cartonsPerLayer">Cartons Per Layer</Label>
                <Input type="number" min="0" max="999999" id="cartonsPerLayer" value={cartonsPerLayer} onChange={e => { setCartonsPerLayer(e.target.value); autoCalculatePalletWeight() }} />
            </FormGroup>
            <FormGroup>
                <Label for="layersPerPallet">Layers Per Pallet</Label>
                <Input type="number" min="0" max="999999" id="layersPerPallet" value={layersPerPallet} onChange={e => {setLayersPerPallet(e.target.value); autoCalculatePalletHeight(); autoCalculatePalletWeight();}} />
            </FormGroup>
        </Form>

        {confirmedSkuList && confirmedSkuList.length > 0 &&
        <div className="copyFromWrapper">
            <Form>
                <FormGroup>
                    <Label for="skuList">Copy Dimensions from: </Label>
                    <Input type="select" name="skuList" id="skuList" onChange={e => setCopySelectedSkuId(e.target.value)} value={copySelectedSkuId}>
                        {BuildSkuOptions()}
                    </Input>
                </FormGroup>
                <FormGroup check inline>
                    <Label check>
                        <Input type="checkbox" name="dimensions" id="dimensions" value={copyUnit} onChange={() => setCopyUnit(!copyUnit)} />{' '}
                        Unit
                    </Label>
                </FormGroup>
                <FormGroup check inline>
                    <Label check>
                        <Input type="checkbox" name="dimensions" id="dimensions" value={copyCarton} onChange={() => setCopyCarton(!copyCarton)} />{' '}
                        Carton
                    </Label>
                </FormGroup>
                <FormGroup check inline>
                    <Label check>
                        <Input type="checkbox" name="dimensions" id="dimensions" value={copyPallet} onChange={() => setCopyPallet(!copyPallet)} />{' '}
                        Pallet
                    </Label>
                </FormGroup>

                <FormGroup>
                    <Button color="primary" onClick={copyDimensions}>Copy</Button>
                </FormGroup>
            </Form>
        </div>
        }
        <div>
            {showPalletHeightOverride && !isPalletHeightOverride &&
                <Alert color="danger">The pallet height is not within the allowed value range. To proceed, please tick the Override checkbox.</Alert>
            }
            {showPalletWeightOverride && !isPalletWeightOverride &&
                <Alert color="danger">The pallet weight is not within the allowed value range. To proceed, please tick the Override checkbox.</Alert>
            }

                <Button disabled={!isValid()} onClick={() => update()}>Save</Button>
                <Button onClick={() => cancelUpdate()}>Cancel</Button>
            </div>
    </div>)
}