import { format } from "date-fns";
import React from "react";
import { FaTimes } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { Button, Col, Row } from "reactstrap";
import { ProductTestTypeEnumLabel } from "../../helpers/enums";
import "./product.css";

export default function ProductTestDateView({productTestDate, editing, productTestEdit, productTestRemove}) {
    return (
        <tr>
            <td>{ProductTestTypeEnumLabel(productTestDate.productTestType)}</td>
            <td>{format(new Date(productTestDate.dateTested), 'yyyy-MM-dd')}</td>
            <td>{editing ? 
                <div>
                    <Button size="sm" onClick={() => productTestEdit(productTestDate.supplierProductTestDateId)}><MdEdit />Edit</Button>
                    <Button size="sm" color="danger" onClick={() => productTestRemove(productTestDate.supplierProductTestDateId)}><FaTimes />Delete</Button>
                </div> : null}
            </td>
        </tr>
    )
}