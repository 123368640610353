import React from "react";
import { useFactsState } from "../../contexts/FactsContext";
import "./address.css";

export default function AddressView({address}) {
    let [factsState, dispatchFacts] = useFactsState();

    function getStateName(stateId) {
        var state = factsState.states.find(x => x.stateId == stateId);
        return state ? state.name.toUpperCase() : undefined;
    }

    function getCountry(countryId) {
        var country = factsState.countries.find(x => x.countryId == countryId);
        return country ? country.name.toUpperCase() : undefined;
    }

    return (
        <div>
            {address && <div>
                <div className="addressViewLine">{address.addressLine1} {address.addressLine2}</div>
                <div className="addressViewLine">{address.suburb} {getStateName(address.state)} {address.postCode}</div>
                <div>{getCountry(address.country)}</div>
                </div>
            }
            {!address && <div>
                No Address Set
                </div>}
        </div>
    )
}