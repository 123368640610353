import React from "react";
import { FaCheck, FaTimes } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { Button } from "reactstrap";

export default function BatteryRequirementView({batteryRequirement, editing, batteryRequirementEdit, batteryRequirementRemove}) {
    return (
        <tr>
            <td>{batteryRequirement.batteryType}</td>
            <td>{batteryRequirement.quantity}</td>
            <td>{batteryRequirement.isIncluded ? <FaCheck /> : <FaTimes />}</td>
            <td>{editing ? 
                <div>
                    <Button size="sm" onClick={() => batteryRequirementEdit(batteryRequirement.batteryRequirementId)}><MdEdit />Edit</Button>
                    <Button size="sm" color="danger" onClick={() => batteryRequirementRemove(batteryRequirement.batteryRequirementId)}><FaTimes />Delete</Button>
                </div> : null}
            </td>
        </tr>
    )
}