import React, {createContext, useContext, useReducer } from "react";

let AuthContext = createContext();

export function useAuthState() {
    return useContext(AuthContext);
}

const authStateReducer = (state, action) => {
    console.log("call auth state reducer with: " + action.type)

    switch (action.type) {
        case "SET_TOKEN":
            console.log("setting state to token: " + action.token)
            sessionStorage.setItem("token", action.token);
            return {...state, token: action.token};        
        case "SET_USER":
            sessionStorage.setItem("user", JSON.stringify(action.user));
            return {...state, user: action.user};
        case "SET_SUPPLIER":
            sessionStorage.setItem("supplierId", action.supplierId);
            return {...state, supplierId: action.supplierId};
        case "CLEAR":
                sessionStorage.removeItem("token");
                sessionStorage.removeItem("user");
                sessionStorage.removeItem("supplierId");
                return {...state, token: null, user: null}
        default:
            return state;
    }
}

const initialState = {
    token: null,
    user: null,
    supplierId: null
};

export function AuthStateProvider({children}) {
    let authState = useReducer(authStateReducer, initialState);

    return <AuthContext.Provider value={authState}>{children}</AuthContext.Provider>
}