import React, {createContext, useContext, useReducer } from "react";

let SupplierRequestContext = createContext();

export function useSupplierRequestState() {
    return useContext(SupplierRequestContext);
}

const supplierRequestStateReducer = (state, action) => {

    switch (action.type) {
        case "INIT_REQUESTS":
            return {...state, requests: action.requests};        
        case "CLEAR":
                return {...state, requests: []}
        default:
            return state;
    }
}

const initialState = {
    requests: []
};

export function SupplierRequestStateProvider({children}) {
    let supplierRequestState = useReducer(supplierRequestStateReducer, initialState);

    return <SupplierRequestContext.Provider value={supplierRequestState}>{children}</SupplierRequestContext.Provider>
}