import React from "react";
import { Alert, Button, Col, FormGroup, Input, Label } from "reactstrap";
import { DEFAULT_SELECT } from "../../constants";
import { useFactsState } from "../../contexts/FactsContext";
import { uuidv4 } from "../Utility/guid";
import { BsDot } from "react-icons/bs";
import "./address.css";
import { Controller, useForm } from "react-hook-form";

export var AddressTypeEnum = {
    Business:1,
    Postal:2
}

export default function AddressEdit({address, addressType, updateAddress, cancelEdit}) {
    let [factsState, dispatchFacts] = useFactsState();

    const { handleSubmit, control, watch, setValue, formState: { errors }} = useForm({
        mode: "all",
        defaultValues: {
            addressLine1: address ? address.addressLine1 : '',
            addressLine2: address ? address.addressLine2 : '',
            suburb: address ? address.suburb : '',
            postCode: address ? address.postCode : '',
            state: address ? address.state : '',
            country: address ? address.country : ''
        }
    });

    var currentCountry = watch('country');
    var currentState = watch('state');

    if (parseInt(currentCountry) > 1) { //if not default or australia
        var state = parseInt(currentState);
        if (state !== 9) {
            setValue('state', '9', { shouldValidate: true});
        }
    }

    function update(formData) {
        var address = {
            addressId: address ? address.addressId : uuidv4(),
            addressType: addressType,
            addressLine1: formData.addressLine1,
            addressLine2: formData.addressLine2,
            suburb: formData.suburb,
            postCode: formData.postCode,
            state: parseInt(formData.state),
            country: parseInt(formData.country)
        }

        updateAddress(address);
    }

    return (
        <div>
            <div className="formGroup">
                <div className="groupTitle">Edit Address</div>
                <Alert color="light">Required Fields <BsDot color="red" /></Alert>

                <Controller id="addressLine1" name="addressLine1" 
                        control={control}
                        rules={{required: true}}
                        render={({field: {ref, ...fieldProps}}) => (
                            <FormGroup row>
                                <Label column="true" sm="2" for="addressLine1">Address Line 1 <BsDot color="red" /></Label>
                                <Col sm={10}>
                                    <Input 
                                        id="addressLine1"
                                        type="text"
                                        innerRef={ref} 
                                        {...fieldProps } 
                                        className={`addressLine form-control ${errors.addressLine1 ? 'is-invalid' : ''}`}/>
                                    {errors.addressLine1 && <div class="invalid-feedback">{errors.addressLine1.type === 'required' && 'Please enter first line of address eg. 10 High St'}</div>}
                                </Col>
                            </FormGroup>
                        )}
                />

                
                <Controller id="addressLine2" name="addressLine2" 
                    control={control}
                    render={({field: {ref, ...fieldProps}}) => (
                        <FormGroup row>
                            <Label column="true" sm="2" for="addressLine2">Address Line 2</Label>
                            <Col sm={10}>
                                <Input 
                                    id="addressLine2"
                                    type="text"
                                    innerRef={ref} 
                                    {...fieldProps } 
                                    className={`addressLine form-control ${errors.addressLine2 ? 'is-invalid' : ''}`}/>
                            </Col>
                        </FormGroup>
                    )}
                />
                
                <Controller id="suburb" name="suburb" 
                        control={control}
                        rules={{required: true}}
                        render={({field: {ref, ...fieldProps}}) => (
                            <FormGroup row>
                                <Label column="true" sm="2" for="suburb">Suburb <BsDot color="red" /></Label>
                                <Col sm={10}>
                                    <Input 
                                        id="suburb"
                                        type="text"
                                        innerRef={ref} 
                                        {...fieldProps } 
                                        className={`addressLine form-control ${errors.suburb ? 'is-invalid' : ''}`}/>
                                    {errors.suburb && <div class="invalid-feedback">{errors.suburb.type === 'required' && 'Please add a suburb'}</div>}
                                </Col>
                            </FormGroup>
                        )}
                />
               
                <Controller id="postCode" name="postCode" 
                        control={control}
                        rules={{required: true}}
                        render={({field: {ref, ...fieldProps}}) => (
                            <FormGroup row>
                                <Label column="true" sm="2" for="postCode">Post Code <BsDot color="red" /></Label>
                                <Col sm={10}>
                                    <Input 
                                        id="postCode"
                                        type="text"
                                        innerRef={ref} 
                                        {...fieldProps } 
                                        className={`form-control ${errors.postCode ? 'is-invalid' : ''}`}/>
                                    {errors.postCode && <div class="invalid-feedback">{errors.postCode.type === 'required' && 'Please enter a post code'}</div>}
                                </Col>
                            </FormGroup>
                        )}
                />
                
                <Controller id="state" name="state" 
                        control={control}
                        rules={{required: true}}
                        render={({field: {ref, ...fieldProps}}) => (
                            <FormGroup row>
                                <Label column="true" sm="2" for="state">State <BsDot color="red" /></Label>
                                <Col sm={10}>
                                    <Input 
                                        id="state"
                                        type="select"
                                        innerRef={ref} 
                                        {...fieldProps } 
                                        className={`form-control ${errors.state ? 'is-invalid' : ''}`}>
                                            <option key="-1" value="">{DEFAULT_SELECT}</option>
                                            {factsState && factsState.states && factsState.states.map((stateItem, index) => {
                                                return (
                                                    <option key={index} value={stateItem.stateId}>{stateItem.name.toUpperCase()}</option>
                                                )
                                            })}
                                    </Input>
                                    {errors.state && <div class="invalid-feedback">{errors.state.type === 'required' && 'Please select a state'}</div>}
                                </Col>
                            </FormGroup>
                        )}
                />
                
                <Controller id="country" name="country" 
                        control={control}
                        rules={{required: true}}
                        render={({field: {ref, ...fieldProps}}) => (
                            <FormGroup row>
                                <Label column="true" sm="2" for="country">Country <BsDot color="red" /></Label>
                                <Col sm={10}>
                                    <Input 
                                        id="country"
                                        type="select"
                                        innerRef={ref} 
                                        {...fieldProps } 
                                        className={`form-control ${errors.country ? 'is-invalid' : ''}`}>
                                            <option key="-1" value="">{DEFAULT_SELECT}</option>
                                            {factsState && factsState.countries && factsState.countries.map((countryItem, index) => {
                                                return (
                                                    <option key={index} value={countryItem.countryId}>{countryItem.name.toUpperCase()}</option>
                                                )
                                            })}
                                    </Input>
                                    {errors.country && <div class="invalid-feedback">{errors.country.type === 'required' && 'Please select a country'}</div>}
                                </Col>
                            </FormGroup>
                        )}
                />

                <div>
                    <Button color="primary" onClick={handleSubmit(update)}>Update Address</Button>
                    <Button color="secondary" onClick={() => cancelEdit()}>Cancel</Button>
                </div>
            </div>
        </div>
    )
}