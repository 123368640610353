import React, { useEffect, useState } from "react";
import ContactView from "./ContactView";
import "./contact.css";
import { FaPlus } from "react-icons/fa";
import { Button } from "reactstrap";
import ContactEdit from "./ContactEdit";

export default function ContactList({contacts, contactType, updatedContacts, onContactBeingEdited, onContactEditingComplete}) {
    let [allContacts, setContacts] = useState(contacts);
    let [addingContact, setAddingContact] = useState(false);
    let [contactBeingEdited, setContactBeingEdited] = useState(null);

    useEffect(() => {
        setContacts(contacts);
        setContactBeingEdited(null);
    }, [contacts])

    useEffect(() => {
        if (addingContact) {
            onContactBeingEdited();
        } else {
            onContactEditingComplete();
        }
    }, [addingContact])

    function addContact() {
        setContactBeingEdited(null);
        setAddingContact(true);
    }

    function removeContact(contactId) {
        var cons = allContacts.filter(x => x.contactId !== contactId);
        setContacts(cons);
        updatedContacts(cons);
    }

    function updateContact(contact) {
        var ind = allContacts.findIndex(x => x.contactId == contact.contactId);
        if (ind >= 0) {
            allContacts[ind] = contact;
            setContacts(allContacts);
            updatedContacts(allContacts);
        }
        else {
            var cons = allContacts.concat(contact);
            setContacts(cons);
            updatedContacts(cons);
        }
        
        setAddingContact(false);
    }

    function editContact(contactId) {
        var con = allContacts.find(x => x.contactId == contactId);
        setContactBeingEdited(con);
        setAddingContact(true);
    }

    function cancelUpdate() {
        setAddingContact(false);
    }

    return (
        <div>
            {addingContact ? 
                <div>
                    <ContactEdit contact={contactBeingEdited} contactTypeArg={contactType} updateContact={updateContact} cancelUpdate={cancelUpdate} />
                </div>
                :
            <div>
                <div>
                    <Button size="sm" color="primary" onClick={addContact}><FaPlus />Add Contact</Button>
                </div>
                <table>
                    <tbody>
                    {allContacts && allContacts.map((contact, index) => {

                        return (
                            <tr key={index}>
                                <td>
                                    <ContactView  contact={contact} editContact={editContact} removeContact={removeContact} />
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>}
        </div>
    )
}