import React, { useEffect, useState } from "react";
import { FaPlus, FaTimes } from "react-icons/fa";
import { Button, Row, Col, Modal, ModalHeader, ModalBody, Input, ModalFooter, Label } from "reactstrap";
import { BASEAPI } from "../../constants";
import { useAuthState } from "../../contexts/AuthContext";
import { useServerErrorState } from "../../contexts/ServerErrorContext";
import { DocumentTypeEnum } from "../../helpers/enums";
import { GetFileWithToken, PostFormWithToken } from "../../helpers/fetch_helpers";
import { uuidv4 } from "../Utility/guid";
import "./product.css";

export default function ProductMandatoryStandardList({supplierProductMandatoryStandards, editing, supplierProductId, supplierId, updatedMandatoryStandards}) {
    let [authState, dispatchAuth] = useAuthState();
    let [serverError, dispatchServerError] = useServerErrorState();

    let [allStandards, setAllStandards] = useState();
    let [showUploadMandatoryStandardModal, setShowUploadMandatoryStandardModal] = useState(false);
    let [file, setFile] = useState(null);
    let [mandatoryStandardCode, setMandatoryStandardCode] = useState();

    useEffect(() => {
        setAllStandards(supplierProductMandatoryStandards)
    }, [supplierProductMandatoryStandards])

    function addMandatoryStandard(documentId) {
        var newStandard = {
            supplierProductMandatoryStandardId: uuidv4(),
            code: mandatoryStandardCode,
            documentId: documentId,
            supplierProductId: supplierProductId
        }

        var standards = allStandards.concat(newStandard);

        updatedMandatoryStandards(standards);
        setAllStandards(standards);
    }

    function removeStandard(supplierProductMandatoryStandardId) {
        var standards = allStandards.filter(x => x.supplierProductMandatoryStandardId !== supplierProductMandatoryStandardId);
        setAllStandards(standards);
        updatedMandatoryStandards(standards);
    }

    async function uploadStandard() {
        var fileInput = document.querySelector('#mandatoryStandardFile');

        var documentId = uuidv4();

        const data = new FormData();
        data.append('uploadedFile', fileInput.files[0]);
        data.append('documentId', documentId);
        data.append('supplierId', supplierId);
        data.append('documentType', DocumentTypeEnum.MandatoryStandard);

        var uri = BASEAPI + "/api/document/ImportDocument";

        var result = await PostFormWithToken(uri, data, authState.token)
        .catch(function(error) {
            console.log(error);
            dispatchServerError({type: "SET_ERROR", errorTitle:"Error Uploading Mandatory Standard", errorMessage: error.message});
        })

        if (result.result) {
            addMandatoryStandard(documentId);
            setShowUploadMandatoryStandardModal(false);
        }
    }

    function showUploadMandatoryStandard() {
        setShowUploadMandatoryStandardModal(true);
    }

    function cancelUpload() {
        setShowUploadMandatoryStandardModal(false);
    }

    function mandatoryStandardFileChanged() {
        var fileInput = document.querySelector('#mandatoryStandardFile');
        setFile(fileInput.files[0]);
    }

    async function openDocument(documentId) {
        let documentPath = BASEAPI + "/api/document/" + documentId + "/?supplierId=" + supplierId;
        await GetFileWithToken(documentPath, authState.token, )
        .catch(function(error){
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error retriving mandatory standard", errorMessage: error.message});
        })
    }

    return (
        <>
        <div>
            {editing && <div><Button color="primary" size="sm" onClick={showUploadMandatoryStandard}><FaPlus />Add Mandatory Standard</Button></div>}
            <div className="mandatoryStandardContainer">
                {allStandards && allStandards.length > 0 && <Row><Col className="mandatoryStandardHeader">Code</Col><Col></Col></Row>}
                {allStandards && allStandards.map((mandatoryStandard, index) => {
                    
                    return <Row key={index}>
                        <Col>{mandatoryStandard.code}</Col>
                        <Col><Button color="link" onClick={() => openDocument(mandatoryStandard.documentId)}>Download Standard</Button>
                            {editing && <Button size="sm" color="danger" onClick={() => removeStandard(mandatoryStandard.supplierProductMandatoryStandardId)}><FaTimes /> Delete</Button>}
                        </Col>
                    </Row>
                })}
            </div>
        </div>
        <Modal isOpen={showUploadMandatoryStandardModal} fade={true}>
            <ModalHeader>Upload Mandatory Standard</ModalHeader>
            <ModalBody>
                <div>
                    <Label for="mandatoryStandardFile">File</Label>
                    <Input type="file" id="mandatoryStandardFile" onChange={mandatoryStandardFileChanged} />
                </div>
                <div>
                    <Label for="mandatoryStandardCode">Standard Code</Label>
                    <Input type="text" id="mandatoryStandardCode" onChange={e => setMandatoryStandardCode(e.target.value)} />
                </div>
            </ModalBody>
            <ModalFooter>
                <Button onClick={uploadStandard}>Upload</Button>
                <Button onClick={cancelUpload}>Cancel</Button>
            </ModalFooter>
        </Modal>
        </>
    )
}