import React, { useState } from "react";
import { BsDot } from "react-icons/bs";
import { Alert, Col, Container, FormGroup, FormText, Input, InputGroup, InputGroupAddon, Label, Row, Button, FormFeedback } from "reactstrap";
import { DEFAULT_SELECT } from "../../constants";
import { SizeMeasurementUnitEnum, SizeMeasurementUnitEnumLabel, WeightMeasurementUnitEnum, WeightMeasurementUnitEnumLabel } from "../../helpers/enums";
import { displayDimension } from "../../helpers/formatting";
import { isEmpty, isImperialSizeMeasurement, isImperialWeightMeasurement } from "../../helpers/utils";
import { isCartonValid } from "../../helpers/validators";
import { uuidv4 } from "../Utility/guid";

export default function CartonEdit({carton, cartonUpdate, cancelUpdate, isInternational})
{
    let [cartonId, setCartonId] = useState(carton ? carton.cartonId : uuidv4());
    let [masterCartonHeight, setMasterCartonHeight] = useState(carton ? carton.masterCartonDimensions.height : null);
    let [masterCartonWidth, setMasterCartonWidth] = useState(carton ? carton.masterCartonDimensions.width : null);
    let [masterCartonLength, setMasterCartonLength] = useState(carton ? carton.masterCartonDimensions.length : null);
    let [masterCartonSizeMeasurementUnitId, setMasterCartonMeasurementSize] = useState(carton ? carton.masterCartonDimensions.sizeUnit : -1);
    let [retailUnitsPerMasterCarton, setRetailUnitsPerMasterCarton] = useState(carton ? carton.retailUnitsPerMasterCarton : 1);
    let [masterCartonWeight, setMasterCartonWeight] = useState(carton ? carton.masterCartonDimensions.weight : null);
    let [masterCartonWeighMeasurementUnitId, setMasterCartonWeightMeasurementUnit] = useState(carton ? carton.masterCartonDimensions.weightUnit : -1);
    let [barcodeGTIN14, setBarcodeGTIN14] = useState(carton ? carton.barcodeDetails.barcodeGTIN14 : '');
    let [barcodeGTIN13, setBarcodeGTIN13] = useState(carton ? carton.barcodeDetails.barcodeGTIN13 : '');
    let [hasNoBarcode14, setHasNoBarcode14] = useState(false);
    let [hasNoBarcode13, setHasNoBarcode13] = useState(false);
    let [retailCartonHeight, setRetailCartonHeight] = useState(carton ? carton.retailUnitDimensions.height : null);
    let [retailCartonWidth, setRetailCartonWidth] = useState(carton ? carton.retailUnitDimensions.width : null);
    let [retailCartonLength, setRetailCartonLength] = useState(carton ? carton.retailUnitDimensions.length : null);
    let [retailCartonSizeMeasurementUnitId, setRetailCartonMeasurementSize] = useState(carton ? carton.retailUnitDimensions.sizeUnit : -1);
    let [retailCartonWeight, setRetailCartonWeight] = useState(carton ? carton.retailUnitDimensions.weight : null);
    let [supplierProductCode, setSupplierProductCode] = useState(carton ? carton.supplierProductCode : '');
    let [retailCartonWeightMeasurementUnitId, setRetailCartonWeightMeasurementUnitId] = useState(carton ? carton.retailUnitDimensions.weighUnit : -1);

    let currentCarton = buildCarton();
    let isCartValid = isCartonValid(currentCarton, isInternational)

    function buildCarton () {
        var carton = {
            cartonId: cartonId,
            masterCartonDimensions: {
                height: masterCartonHeight,
                width: masterCartonWidth,
                length: masterCartonLength,
                sizeUnit: parseInt(masterCartonSizeMeasurementUnitId),
                weight: masterCartonWeight,
                weightUnit: parseInt(masterCartonWeighMeasurementUnitId)
            },
            retailUnitsPerMasterCarton: retailUnitsPerMasterCarton,
            barcodeDetails: {
                barcodeGTIN13: barcodeGTIN13,
                barcodeGTIN14: barcodeGTIN14
            },
            retailUnitDimensions: {
                height: retailCartonHeight,
                width: retailCartonWidth,
                length: retailCartonLength,
                sizeUnit: parseInt(retailCartonSizeMeasurementUnitId),
                weight: retailCartonWeight,
                weighUnit: parseInt(retailCartonWeightMeasurementUnitId)
            },
            supplierProductCode: supplierProductCode
        }
        return carton;
    }

    function update() {
        var updatedCarton = buildCarton();

        cartonUpdate(updatedCarton);
    }

    function isValid() {
        if (isInternational) {
            //check those required if international
            if (!masterCartonHeight || !masterCartonLength || !masterCartonWidth || !masterCartonWeight)
                return false;

            if (!hasNoBarcode14 && !barcodeGTIN14)
                return false;
        }

        //check standard
        if (!retailUnitsPerMasterCarton || retailUnitsPerMasterCarton < 1)
            return false;

        if (!retailCartonHeight || !retailCartonLength || !retailCartonWidth || !retailCartonWeight || retailCartonHeight <= 0 || retailCartonLength <= 0 || retailCartonWidth <= 0 || retailCartonWeight <= 0 || retailCartonWeight > 999.99)
            return false;

        if (!hasNoBarcode13 && !barcodeGTIN13)
            return false;

        return true;
    }

    function buildSizeOptions() {
        var options = [];
        options.push(<option key={-1} value="-1">{DEFAULT_SELECT}</option>)

        var entries = Object.entries(SizeMeasurementUnitEnum);

        for (var i = 0; i < entries.length; i++)
        {
            var key = parseInt(entries[i][1]);

            if (!isImperialSizeMeasurement(key)) {
                var opt = <option key={key} value={key}>{SizeMeasurementUnitEnumLabel(key)}</option>;

                options.push(opt);
            }
        }

        return options;
    }

    function buildWeightOptions() {
        var options = [];
        options.push(<option key={-1} value="-1">{DEFAULT_SELECT}</option>);

        var entries = Object.entries(WeightMeasurementUnitEnum);

        for (var i = 0; i < entries.length; i++)
        {
            var key = parseInt(entries[i][1]);

            if (!isImperialWeightMeasurement(key)) {
                var opt = <option key={key} value={key}>{WeightMeasurementUnitEnumLabel(key)}</option>;

                options.push(opt);
            }
        }

        return options;
    }

    function copyFromMasterCarton() {
        setRetailCartonHeight(masterCartonHeight);
        setRetailCartonLength(masterCartonLength);
        setRetailCartonWidth(masterCartonWidth);
        setRetailCartonMeasurementSize(masterCartonSizeMeasurementUnitId);
        setRetailCartonWeight(masterCartonWeight);
        setRetailCartonWeightMeasurementUnitId(masterCartonWeighMeasurementUnitId);
    }

    return (
        <div>
            <div className="formGroup">
                <div className="groupTitle">Edit Carton</div>
                <Alert color="light">Required Fields <BsDot color="red" /></Alert>

                <Container>
                    <Row>
                        <Col>
                            <legend>Master Carton</legend>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="masterCartonHeight"><BsDot color="red" /> Height</Label>
                                <Input type="number" min="0" max="999999" step="0.01" id="masterCartonHeight" value={masterCartonHeight} onChange={e => setMasterCartonHeight(displayDimension(e.target.value))} />
                                <FormText>Top to Bottom</FormText>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label for="masterCartonWidth"><BsDot color="red" /> Width</Label>
                                <Input type="number" min="0" max="999999" step="0.01" id="masterCartonWidth" value={masterCartonWidth} onChange={e => setMasterCartonWidth(displayDimension(e.target.value))} />
                                <FormText>Left to Right</FormText>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label for="masterCartonLength"><BsDot color="red" /> Length</Label>
                                <Input type="number" min="0" max="999999" step="0.01" id="masterCartonLength" value={masterCartonLength} onChange={e => setMasterCartonLength(displayDimension(e.target.value))} />
                                <FormText>Front to Back</FormText>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label>&nbsp;</Label>
                                <Input type="select" id="masterCartonSizeMeasurementUnitId" value={masterCartonSizeMeasurementUnitId} onChange={e => setMasterCartonMeasurementSize(e.target.value)}>
                                    {buildSizeOptions()}
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <FormGroup>
                                <Label for="masterCartonWeight"><BsDot color="red" /> Gross Weight</Label>
                                <Input type="number" min="0" max="999999" step="0.01" id="masterCartonWeight" value={masterCartonWeight} onChange={e => setMasterCartonWeight(displayDimension(e.target.value))} />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label>&nbsp;</Label>
                                <Input type="select" id="masterCartonWeightUnitMeasurementId" value={masterCartonWeighMeasurementUnitId} onChange={e => setMasterCartonWeightMeasurementUnit(e.target.value)}> 
                                            {buildWeightOptions()}
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="retailUnitsPerMasterCarton"><BsDot color="red" /> Retail Units Per Master Carton</Label>
                                <Input type="number" id="retailUnitsPerMasterCarton" min="1" value={retailUnitsPerMasterCarton} onChange={e => setRetailUnitsPerMasterCarton(e.target.value)} />
                                <FormText>Retail Unit Count within Shipped Carton</FormText>
                            </FormGroup>
                        </Col>
                        <Col>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="barcodeGTIN14">GTIN (EAN)</Label>
                                <Input disabled={hasNoBarcode14} type="text" maxLength="14" id="barcodeGTIN14" value={barcodeGTIN14} onChange={e => setBarcodeGTIN14(e.target.value)} />
                                <FormFeedback valid={!isInternational || (!hasNoBarcode14 && barcodeGTIN14)}>Please enter a GTIN (EAN) 14 character barcode</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup inline check>
                                <Label for="hasNoBarcode14">Has No Barcode?</Label>
                                <Input type="checkbox" id="hasNoBarcode14" checked={hasNoBarcode14} onChange={e => setHasNoBarcode14(!hasNoBarcode14)} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <legend>Retail Carton</legend>
                        </Col>
                    </Row>
                    {isInternational && 
                    <Row>
                        <Col><Button color="primary" onClick={copyFromMasterCarton}>Copy From Master Carton</Button></Col>
                    </Row>}
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="retailCartonHeight"><BsDot color="red" /> Height</Label>
                                <Input type="number" min="0" max="999999" step="0.01" id="retailCartonHeight" value={retailCartonHeight} onChange={e => setRetailCartonHeight(displayDimension(e.target.value))} />
                                <FormText>Top to Bottom</FormText>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label for="retailCartonWidth"><BsDot color="red" /> Width</Label>
                                <Input type="number" min="0" max="999999" step="0.01" id="retailCartonWidth" value={retailCartonWidth} onChange={e => setRetailCartonWidth(displayDimension(e.target.value))} />
                                <FormText>Left to Right</FormText>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label for="retailCartonLength"><BsDot color="red" /> Length</Label>
                                <Input type="number" min="0" max="999999" step="0.01" id="retailCartonLength" value={retailCartonLength} onChange={e => setRetailCartonLength(displayDimension(e.target.value))} />
                                <FormText>Front to Back</FormText>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label>&nbsp;</Label>
                                <Input type="select" id="retailCartonSizeMeasurementUnitId" value={retailCartonSizeMeasurementUnitId} onChange={e => setRetailCartonMeasurementSize(e.target.value)}>
                                    {buildSizeOptions()}
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <FormGroup>
                                <Label for="retailCartonWeight"><BsDot color="red" /> Gross Weight</Label>
                                <Input type="number" min="0" max="999999" step="0.01" id="retailCartonWeight" value={retailCartonWeight} onChange={e => setRetailCartonWeight(displayDimension(e.target.value))} />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label>&nbsp;</Label>
                                <Input type="select" id="retailCartonWeightMeasurementUnitId" value={retailCartonWeightMeasurementUnitId} onChange={e => setRetailCartonWeightMeasurementUnitId(e.target.value)}> 
                                            {buildWeightOptions()}
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="barcodeGTIN13"><BsDot color="red" /> GTIN (TUN)</Label>
                                <Input type="text" maxLength="14" id="barcodeGTIN13" value={barcodeGTIN13} onChange={e => setBarcodeGTIN13(displayDimension(e.target.value))} invalid={!hasNoBarcode13 && isEmpty(barcodeGTIN13)} />
                                <FormFeedback valid={!hasNoBarcode13 && barcodeGTIN13}>Please enter a GTIN (TUN) up to 14 character Barcode</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col>
                            {/* <FormGroup check>
                                <Label for="hasNoBarcode13">Has No Barcode?</Label>
                                <Input type="checkbox" id="hasNoBarcode13" checked={hasNoBarcode13} onChange={e => setHasNoBarcode13(!hasNoBarcode13)} />
                            </FormGroup> */}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="supplierProductCode">Product Code</Label>
                                <Input type="text" id="supplierProductCode" value={supplierProductCode} onChange={e => setSupplierProductCode(e.target.value)} />
                            </FormGroup>
                        </Col>
                    </Row>
                </Container>
                {!isCartValid && 
                <Alert color="danger" className="errorSummary">
                <strong>Errors</strong>
                <ul>
                    {currentCarton.errorMessages.map((error, index) =>
                    {
                        return <li key={index}>{error}</li>
                    })}
                </ul>
            </Alert>}
                <div>
                    <Button color="primary" disabled={!isValid()} onClick={() => update()}>Save Carton</Button>
                    <Button onClick={() => cancelUpdate()}>Cancel</Button>
                </div>
            </div>
        </div>

    )
}