import React, { useState, useEffect } from "react";
import { Alert, Button } from "reactstrap";
import { BASEAPI } from "../../../constants";
import { useAuthState } from "../../../contexts/AuthContext";
import { useServerErrorState } from "../../../contexts/ServerErrorContext";
import { convertAddress } from "../../../helpers/conversions";
import { ContactTypeEnum, ContactTypeEnumLabel } from "../../../helpers/enums";
import { PostJsonWithToken } from "../../../helpers/fetch_helpers";
import ContactList from "../../Contact/ContactList";

export default function ContactInfo({requestId, contactType, contactInfo, nextStep, prevStep, contactInfoUpdated}) {
    let [authState, despatchAuth] = useAuthState();
    let [serverErrorState, dispatchServerError] = useServerErrorState();
    let [contactBeingEdited, setContactBeingEdited] = useState(false);
    let [allContacts, setAllContacts] = useState(contactInfo.contacts);

    useEffect(() => {
        setAllContacts(contactInfo.contacts);
    }, [contactInfo])

    function convertContacts(contacts) {
        var convertedContacts = [];
        contacts.forEach(contact => {
            var convertedContact = contact;
            if (contact.address) {
                convertedContact.address = convertAddress(contact.address);
            }
            convertedContacts.push(convertedContact);
        }); 

        return convertedContacts;
    }

    function convertContactInfo(contactInfo) {
        var convertedContactInfo = {
            contactType: contactInfo.contactType,
            contacts: convertContacts(contactInfo.contacts),
        }

        return convertedContactInfo;
    }

    function contactsUpdated(contacts) {
        contactInfo.contacts = contacts;
        setAllContacts(contactInfo.contacts);
    }

    async function saveAndContinue() {
        var uri = BASEAPI + "/api/submission/SaveContactInfo";
        var data = {
            supplierRequestId: requestId,
            contactType: contactType,
            contactInformation: convertContactInfo(contactInfo)
        }
        var result = await PostJsonWithToken(uri, data, authState.token)
        .catch(function(error){
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error saving contact information", errorMessage: error.message});
        })
        
        if (result) {
            contactInfoUpdated(contactType, convertContactInfo(contactInfo))
            nextStep();
        }
    }

    function onContactBeingEdited() {
        setContactBeingEdited(true);
    }

    function onContactEditingComplete() {
        setContactBeingEdited(false);
    }

    function isValid() {
        if (contactBeingEdited)
            return false;

        if ((contactType == ContactTypeEnum.Purchasing || contactType == ContactTypeEnum.Warehouse) && (allContacts == null || allContacts.length <= 0))
            return false;

        return true;
    }

    return (
        <div className="submissionContainer">
            <h2>{ContactTypeEnumLabel(contactType)} Contacts</h2>

            <ContactList contacts={allContacts} contactType={contactType} updatedContacts={contactsUpdated} onContactBeingEdited={onContactBeingEdited} onContactEditingComplete={onContactEditingComplete} />

            <div className="footer box-shadow">
            <Button color="primary" disabled={!isValid()} onClick={() => saveAndContinue()}>Save and Continue</Button>{' '}
            <Button disabled={contactBeingEdited} onClick={() => prevStep()}>Go Back</Button>
            {((contactType == ContactTypeEnum.Purchasing || contactType == ContactTypeEnum.Warehouse) && (allContacts == null || allContacts.length <= 0)) ? <Alert color="danger">Please enter at least one contact</Alert> : <></>}
            </div>
        </div>
    )
}