import React from "react";
import { FaCheck, FaTimes } from "react-icons/fa";
import { limitToXDecimalPlaces } from "./utils";

export function displayPhoneNo(areaCode, number) {
    if (areaCode)
        return "(" + areaCode + ") " + number;

    return number;
}

export function displayMoney(value) {
    return limitToXDecimalPlaces(value, 4);
}

export function displayDimension(value) {
    return limitToXDecimalPlaces(value, 4);
}

export function displayYesNo(value) {
    if (value !== null) {
        return value ? <FaCheck color="green" /> : <FaTimes color="red" />;
    }

    return '';
}