import { format } from "date-fns";
import React, { useState } from "react";
import { BsDot } from "react-icons/bs";
import { Alert, Button, Col, Container, Input, Label, Row } from "reactstrap";
import { DEFAULT_SELECT } from "../../constants";
import { uuidv4 } from "../Utility/guid";

export default function ProductTestDateEdit({productTestDate, productTestDateUpdated, cancelUpdate}) {
    let [productTestDateId, setProductTestDateId] = useState(productTestDate ? productTestDate.supplierProductTestDateId : uuidv4());
    let [productTestTypeId, setProductTestTypeId] = useState(productTestDate ? productTestDate.productTestType : -1);
    let [dateTested, setDateTested] = useState(productTestDate ? format(new Date(productTestDate.dateTested), 'yyyy-MM-dd')  : null);

    function updateDateTested(e) {
        var date = new Date(e.target.value);
        var formattedDate = format(date, 'yyyy-MM-dd');
        setDateTested(formattedDate);    
    }

    function saveProductTestDate() {
        var productTestDate = {
            supplierProductTestDateId: productTestDateId,
            productTestType: productTestTypeId,
            dateTested: dateTested
        }

        productTestDateUpdated(productTestDate);
    }

    function isValid() {
        return productTestTypeId > 0 && dateTested !== null;
    }

    return (
        <div>
            <div className="formGroup">
                <div className="groupTitle">Edit Product Test Date</div>
                <Alert color="light">Required Fields <BsDot color="red" /></Alert>
                <Container>
                    <Row>
                        <Col>
                            <Label>Product Test Type <BsDot color="red" /></Label>
                        </Col>
                        <Col>
                            <Input type="select" id="productTestType" value={productTestTypeId} onChange={e => setProductTestTypeId(parseInt(e.target.value))}>
                                <option value="-1">{DEFAULT_SELECT}</option>
                                <option value="1">ISO8124</option>
                                <option value="2">EN71</option>
                                <option value="3">EMC</option>
                            </Input>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Label>Test Date <BsDot color="red" /></Label>
                        </Col>
                        <Col>
                            <Input type="date" value={dateTested} onChange={(e) => updateDateTested(e)} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button disabled={!isValid()} onClick={saveProductTestDate}>Save</Button>
                            <Button onClick={() => cancelUpdate()}>Cancel</Button>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}