import React, { useEffect, useState } from "react";
import { MdEdit } from "react-icons/md";
import { useHistory, useParams } from "react-router";
import { Container, Spinner, Row, Col, Button, Modal, ModalHeader, ModalBody, Input, ModalFooter } from "reactstrap";
import { BASEAPI } from "../../constants";
import { useAuthState } from "../../contexts/AuthContext";
import { useServerErrorState } from "../../contexts/ServerErrorContext";
import { GetFileWithToken, GetJsonWithToken, PostFormWithToken, PostJsonWithToken } from "../../helpers/fetch_helpers";
import SkuEdit from "../Sku/SkuEdit";
import SkuList from "../Sku/SkuList";
import SkuView from "../Sku/SkuView";
import "../Sku/sku.css";
import { FaFileExcel } from "react-icons/fa";

export default function ProductDetails() {
    let { requestId } = useParams();
    let [authState, dispatchAuth] = useAuthState();
    let [serverErrorState, dispatchServerError] = useServerErrorState();
    let [loading, setLoading] = useState(false);
    let [currentSku, setCurrentSku] = useState();
    let [editSku, setEditSku] = useState(false);
    let [file, setFile] = useState();
    let history = useHistory();
    
    let [showUploadSkuFile, setShowUploadSkuFile] = useState(false);

    let [skuModel, setSkuModel] = useState();

    async function getSkus() {
        setLoading(true);

        const uri = BASEAPI + "/api/sku/" + requestId;
        var result = await GetJsonWithToken(uri, null, authState.token)
        .catch(function(error){
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error retrieving finance model", errorMessage: error.message});
        })
        .finally(() => setLoading(false));

        setSkuModel(result);   
    }

    useEffect(() => {
        getSkus();
    },[requestId])

    function selectedSkuChange(skuId) {
        //set selected sku for form
        var sku = skuModel.pendingSkus.find(x => x.skuId == skuId);
        if (sku) {
            setCurrentSku(sku);
        }
    }

    function confirmedSelectedSkuChange(skuId) {
        var sku = skuModel.confirmedSkus.find(x => x.skuId == skuId);
        if (sku) {
            setCurrentSku(sku);
        }
    }
    
    function sortItems(a,b) {
        if (a.itemDescription.toLowerCase() < b.itemDescription.toLowerCase()) {
            return -1;
        }
        if (a.itemDescription.toLowerCase() > b.itemDescription.toLowerCase()) {
            return 1;
        }
        return 0;        
    }

   function cancelSkuUpdate() {
        setEditSku(false);
    }

    function skuUpdated(sku) {
        var pendingSkuList = skuModel.pendingSkus;
        var confirmedSkuList = skuModel.confirmedSkus;
        var ind = pendingSkuList.findIndex(x => x.skuId === sku.skuId);
        if (ind >= 0) {
            pendingSkuList.splice(ind, 1);
            pendingSkuList.sort(sortItems);
            skuModel.pendingSkus = pendingSkuList;          
        }

        var comInd = confirmedSkuList.findIndex(x => x.skuId === sku.skuId);
        if (comInd >= 0) {
            confirmedSkuList[comInd] = sku;
            confirmedSkuList.sort(sortItems);
            skuModel.confirmedSkus = confirmedSkuList;    
        }
        else {
            var skus = confirmedSkuList.concat(sku);
            skus.sort(sortItems);
            skuModel.confirmedSkus = skus;
        }

        setSkuModel(skuModel);

        setEditSku(false);
        setCurrentSku(sku);

        recordProgress();
    }

    function pendingSkusUpdated(skus) {
        skuModel.pendingSkus = skus;
        setSkuModel(skuModel);

        recordProgress();
    }

    async function recordProgress() {
        var skuProgress = {
            supplierRequestId: requestId,
            pendingSkus: skuModel.pendingSkus,
            confirmedSkus: skuModel.confirmedSkus
        }

        var uri = BASEAPI + "/api/sku/SaveSkus";

        var result = await PostJsonWithToken(uri, skuProgress, authState.token)
        .catch(function(error){
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error saving sku progress", errorMessage: error.message});
        })
    }

    async function submitAll() {
        if (window.confirm('You are going to submit all sku data. Please make sure they are accurate. Please also make sure all skus are confirmed before proceeding.\n\nTo continue, click OK.\nTo abort, click Cancel.')) {
            var uri = BASEAPI + "/api/SupplierRequest/SubmitSkus/" + requestId;
            var result = await PostJsonWithToken(uri, null, authState.token)
            .catch(function(error){
                console.log(error);
                dispatchServerError({type: "SET_ERROR",errorTitle:"Error submitting sku information", errorMessage: error.message});
            })
    
            if (result) {
                setTimeout(() => { history.push("/") }, 10000);
            }
        }
    }

    async function exportPending() {
        var uri = BASEAPI + "/api/Sku/ExportProducts/" + requestId;
        await GetFileWithToken(uri, authState.token, "productExport.xlsx")
        .catch(function(error){
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error retriving product export", errorMessage: error.message});
        })
    }

    function showImportModal() {
        setShowUploadSkuFile(true);
    }

    function cancelUpload() {
        setShowUploadSkuFile(false);
    }

    function skuFileChanged() {
        var fileInput = document.querySelector('#skuFile');
        setFile(fileInput.files[0]);
    }

    async function importProducts() {
        var fileInput = document.querySelector('#skuFile');
        var uri = BASEAPI + "/api/Sku/ImportProducts";

        var data = new FormData();
        data.append('uploadedFile', fileInput.files[0]);
        data.append('supplierRequestId', requestId);

        var result = await PostFormWithToken(uri, data, authState.token)
        .catch(function(error) {
            console.log(error);
            dispatchServerError({type: "SET_ERROR", errorTitle:"Error Uploading Sku File", errorMessage: error.message});
        })

        if (result) {
            setSkuModel(result);
            setShowUploadSkuFile(false);
        }
    }

    return (
        <>
        <h3>Product Details</h3>

        {loading && <Spinner />}

        {skuModel && 
        <div>
            <div className="formGroup">
                {skuModel && (skuModel.pendingSkus.length > 0 || skuModel.confirmedSkus.length > 0) ?
                <Container>
                    <Row>
                        <Col xs="4">
                            <div>
                            <Button color="primary" onClick={submitAll}>Submit All Confirmed</Button>
                            </div>
                            <div>
                                <Button color="warning" onClick={exportPending}><FaFileExcel />Export Products</Button>
                                <Button color="success" onClick={showImportModal}><FaFileExcel />Import Products</Button>
                            </div>
                            {skuModel.pendingSkus.length > 0 && 
                                <>
                                <h5>Pending Skus</h5>
                                <SkuList skus={skuModel.pendingSkus} isDisabled={editSku} selectedSkuChange={selectedSkuChange} setSkuId={currentSku ? currentSku.skuId : null} updatedSkus={pendingSkusUpdated} allowDelete={true} />
                                </>
                            }
                            {skuModel.confirmedSkus.length > 0 &&
                                <>
                                <h5>Confirmed Skus</h5>
                                <SkuList skus={skuModel.confirmedSkus} isDisabled={editSku} selectedSkuChange={confirmedSelectedSkuChange} setSkuId={currentSku ? currentSku.skuId : null} allowDelete={false} />
                                </>
                            }
                            <Modal isOpen={showUploadSkuFile} fade={true}>
                                <ModalHeader>Import Skus</ModalHeader>
                                <ModalBody>
                                    <Input type="file" id="skuFile" onChange={skuFileChanged} />
                                </ModalBody>
                                <ModalFooter>
                                    <Button onClick={importProducts}>Upload</Button>
                                    <Button onClick={cancelUpload}>Cancel</Button>
                                </ModalFooter>
                            </Modal>
                        </Col>
                        <Col xs="8">
                            {currentSku ? 
                                (editSku ? <SkuEdit sku={currentSku} skuUpdated={skuUpdated} cancelUpdate={cancelSkuUpdate} confirmedSkuList={skuModel.confirmedSkus} /> :
                                    <div>
                                        {!currentSku.isConfirmed &&
                                        <div className="topButtonsSku">
                                            <Button color="secondary" size="sm" onClick={() => setEditSku(true)}><MdEdit /> Edit</Button>
                                        </div>
                                        }
                                        <SkuView sku={currentSku} />
                                    </div>
                                ) : "Please Select Item from the List"
                            }
                            
                        </Col>
                    </Row>
                </Container>
                : "No Pending Products"}
            </div>
        </div>}
        </>
    )
}