import React, { useState } from "react";
import "./Login.css";
import { Alert, Button, FormFeedback, FormGroup, Input, Label, Spinner } from "reactstrap";
import logo from '../../images/Chrisco_logo_underline_reg.png';
import { BASEAPI } from "../../constants";
import { PostJson } from "../../helpers/fetch_helpers";
import { useServerErrorState } from "../../contexts/ServerErrorContext";

export default function ForgotPassword() {
    let [email, setEmail] = useState();
    let [serverError, dispatchServerError] = useServerErrorState();
    let [success, setSuccess] = useState(false);
    let [submitting, setSubmitting] = useState(false);

    function validEmail(email) {
        const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        return emailRegex.test(email);
    }

    async function submitForgotPwd() {
        setSubmitting(true);

        var uri = BASEAPI + "/api/account/ForgotPassword";
        var data = {
            emailAddress: email
        }
        var result = await PostJson(uri, data)
        .catch(function(error){
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error submitting forgot password", errorMessage: error.message});
        })
        .finally(() => setSubmitting(false))

        if (result) {
            setSuccess(true);
        }
    }

    function isValid() {
        return email && validEmail(email);
    }

    return (
        <>
         <div className="logo-wrapper">
                <img src={logo} className="logo" /> Supplier Portal
            </div>
            <div className="login-wrapper">
                <h3>Forgotten Password</h3>

                <div className="forgot-pwd-blurb">
                If you have forgotten your password, please enter your email address below in order to recieve a link to reset your password for this system.
                </div>

                <div className="forgot-pwd-form">
                    <FormGroup>
                        <Label for="email">Email</Label>
                        <Input className="forgot-pwd-field" type="email" name="email" id="email" value={email} onChange={e => setEmail(e.target.value)} invalid={!isValid()} valid={isValid()} />
                        <FormFeedback>
                            Please enter a valid e-mail address.
                        </FormFeedback>
                    </FormGroup>

                    <Button disabled={!isValid() || submitting} onClick={submitForgotPwd}>Submit</Button>
                </div>

                {submitting && <Spinner color="dark" />}

                {success &&
                <Alert color="success">
                    Submitted Successfully.  Please check your e-mail soon.
                </Alert>}
            </div>
        </>
    )
}