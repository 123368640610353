import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { Button } from "reactstrap";
import CartonEdit from "./CartonEdit";
import CartonView from "./CartonView";

export default function CartonList({cartons, updatedCartons, allowEditing, isInternational}) {
    let [editing, setEditing] = useState(false);
    let [allCartons, setAllCartons] = useState([]);
    let [cartonBeingEdited, setCartonBeingEdited] = useState(null);
 
    var cartonsTotal = allCartons ? allCartons.length : 0;

    useEffect(() => {
        if (cartons)
            setAllCartons(cartons)
    }, [cartons]);

    function addCarton() {
        setEditing(true);
        setCartonBeingEdited(null);
    }

    function editCarton(cartonId) {
        var carton = allCartons.find(x => x.cartonId == cartonId);
        setCartonBeingEdited(carton);
        setEditing(true);
    }

    function removeCarton(cartonId) {
        var index = allCartons.findIndex(x => x.cartonId == cartonId);
        if (index > -1)
        {
            allCartons.splice(index, 1);
            var cartons = allCartons.map((carton) => carton);
            setAllCartons(cartons);
            updatedCartons(cartons);
        }
    }

    function cartonAddedOrUpdated(carton) {
        var ind = allCartons.findIndex(x => x.cartonId == carton.cartonId);
        if (ind >= 0) {
            allCartons[ind] = carton;
            setAllCartons(allCartons);
            updatedCartons(allCartons);
        }
        else {
            var carts = allCartons.concat(carton);
            setAllCartons(carts);
            updatedCartons(carts);
        }

        setCartonBeingEdited(null);
        setEditing(false);
    }

    function cancelEdit() {
        //setCartonBeingEdited(null);
        setEditing(false);
    }

    return (
        <div>
            <div className="sectionHeader">Cartons</div>

            {editing ? 
                <div>
                    <CartonEdit carton={cartonBeingEdited} cartonUpdate={cartonAddedOrUpdated} cancelUpdate={cancelEdit} isInternational={isInternational} />
                </div>
                :
                <div>

                    <div>
                            <Button size="sm" color="primary" onClick={addCarton}><FaPlus />Add Carton</Button>
                    </div>
                    <table>
                        <tbody>

                            {allCartons && allCartons.map((carton, index) => {
                                return <tr key={index}>
                                <td>
                                    <CartonView carton={carton} editing={allowEditing} editCarton={editCarton} removeCarton={removeCarton} cartonIndex={++index} cartonTotal={cartonsTotal} />
                                </td>
                            </tr>
                            })}
                        </tbody>
                    </table>
                </div>}
            </div>
    )
}