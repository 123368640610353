import React, { useEffect, useState } from "react";
import { BASEAPI } from "../../constants";
import { useAuthState } from "../../contexts/AuthContext";
import { useServerErrorState } from "../../contexts/ServerErrorContext";
import { useSupplierRequestState } from "../../contexts/SupplierRequestContext";
import { GetJsonWithToken } from "../../helpers/fetch_helpers";
import './Actions.css';
import ActionsList from "./ActionsList";
import { format, formatDistanceToNow } from "date-fns";
import { SupplierRequestTypeLabel } from "../../helpers/enums";

export default function Actions() {
    let [supplierRequestState, dispatchSupplierRequest] = useSupplierRequestState();
    let [authState, dispatchAuth] = useAuthState();
    let [serverErrorState, dispatchServerError] = useServerErrorState();

    var activeRequests = supplierRequestState ? supplierRequestState.requests.filter(x => x.submittedByContactId == null && x.dateSubmitted === null) : null;
    var submittedRequests = supplierRequestState ? supplierRequestState.requests.filter(x => x.dateSubmitted !== null && x.dateCompleted === null) : null;
    var completedRequests = supplierRequestState ? supplierRequestState.requests.filter(x => x.dateSubmitted && x.dateCompleted) : null;

    async function getRequests() {
        if (authState.user && (authState.user.supplierId || (authState.user.isStaff && authState.supplierId))) {
            const uri = BASEAPI + "/api/supplierrequest/" + (authState.user.isStaff ? authState.supplierId : authState.user.supplierId);
            var result = await GetJsonWithToken(uri, null, authState.token)
            .catch(function(error){
                console.log(error);
                dispatchServerError({type: "SET_ERROR",errorTitle:"Error retrieving actions", errorMessage: error.message});
            })

            dispatchSupplierRequest({type: "INIT_REQUESTS", requests: result});
        }
    }

    useEffect(() => {
        getRequests();
    }, [authState.user])

    return (
        <div className="action-wrapper">
            <h3>Active Actions</h3>
            {activeRequests && activeRequests.length > 0 ? <ActionsList requests={activeRequests}/> : <div>No Active Actions</div>}            
            {submittedRequests && 
            <div>
                <h3>Requests Processing</h3>
                <ul className="actionList">
                {submittedRequests.map((req, index) => {
                    return (<li key={index}>{SupplierRequestTypeLabel(req.supplierRequestType)} - Submitted {formatDistanceToNow(new Date(req.dateSubmitted))} ago</li>)
                })}
                </ul>
            </div>}
            {completedRequests && completedRequests.length > 0 && 
            <div>
                <h3>Completed Requests</h3>
                <ul className="actionList">
                {completedRequests.map((req, index) => {
                    return (<li key={index}>{SupplierRequestTypeLabel(req.supplierRequestType)} - Completed on {format(new Date(req.dateCompleted), 'dd/MM/yyyy hh:mm:ss')} </li>)
                })}
                </ul>
            </div>}
        </div>
    )
}