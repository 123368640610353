import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Toast, ToastBody, ToastHeader } from "reactstrap";
import { BASEAPI } from "../../../constants";
import { useAuthState } from "../../../contexts/AuthContext";
import { useServerErrorState } from "../../../contexts/ServerErrorContext";
import { PostJsonWithToken } from "../../../helpers/fetch_helpers";

export default function SubmitProducts({requestId, prevStep}) {
    let history = useHistory();
    let [serverError, dispatchServerError] = useServerErrorState();
    let [authState, dispatchAuth] = useAuthState();
    let [showSuccessToast, setShowSuccessToast] = useState(false);

    async function submit() {
        if (window.confirm('All quoted pricing must be fixed until Dec 31st of the relevant delivery year as indicated in the submission request.\n\nYou are going to submit all product submission data. Please make sure they are accurate.\n\nPlease note the access to the product submission request will be disabled after the submission.\n\nBy proceeding with this submission, you agree to the above.\n\nTo continue, click OK.\nTo abort, click Cancel.')) {
            var uri = BASEAPI + "/api/SupplierRequest/SubmitProducts/" + requestId;
            // var data = {
            //     supplierRequestId: requestId,
            // }
            var result = await PostJsonWithToken(uri, null, authState.token)
            .catch(function(error){
                console.log(error);
                dispatchServerError({type: "SET_ERROR",errorTitle:"Error submitting product information", errorMessage: error.message});
            })
    
            if (result) {
                setShowSuccessToast(true);
            }
        }
    }

    function toggle() {
        setShowSuccessToast(false);
        setTimeout(() => { history.push("/") }, 200);
    }
    
    return (
        <div className="submissionContainer">
            <div>
                Products ready for submission to server.
            </div>
            <div>
                <Button onClick={submit}>Submit Products</Button>
                <Button onClick={() => prevStep()}>Go Back</Button>
            </div>
            {showSuccessToast &&
            <div className="p-3 my-2 rounded bg-success standardToast">
                <Toast isOpen={showSuccessToast} >
                    <ToastHeader icon="success" toggle={toggle}>Product Information Submitted</ToastHeader>
                    <ToastBody>
                        Product Submission Information Successfully Submitted to server.
                    </ToastBody>
                </Toast>
            </div>
            }
        </div>
    )
}