import React from "react";
import  { Table } from "reactstrap";
import { TradingTermLabel, TradingTermTypeEnum } from "../../../helpers/enums";

export default function TradingTermsList({tradingTerms}) {
    
    return (
        <>
        {tradingTerms &&
            <Table>
                <thead>
                    <tr>
                        <th>Trading Term</th>
                        <th>Amount</th>
                        <th>Notes</th>
                    </tr>
                </thead>
                <tbody>
                    {tradingTerms.map((tradingTerm, index) => {
                        var amount = tradingTerm.tradingTermType == TradingTermTypeEnum.Variable ? (tradingTerm.amount * 100) + "%" : "$" + tradingTerm.amount;
                        return (
                            <tr key={index}>
                                <td>{TradingTermLabel(tradingTerm.tradingTerm)}</td>
                                <td>{amount}</td>
                                <td>{tradingTerm.notes}</td>
                            </tr>
                         )
                    })
                }
                </tbody>
            </Table>
        }
        </>
    )
}