import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import queryString from "query-string";
import logo from '../../images/Chrisco_logo_underline_reg.png';
import { BASEAPI } from "../../constants";
import { PostJson } from "../../helpers/fetch_helpers";
import { useServerErrorState } from "../../contexts/ServerErrorContext";
import { Alert, Button, FormFeedback, FormGroup, Input, Label, Spinner } from "reactstrap";

export default function ResetPassword() {
    let location = useLocation();
    let params = queryString.parse(location.search);

    var userId = params["UserId"];
    var code = params["code"];

    let [password, setPassword] = useState();
    let [confirmPwd, setConfirmPwd] = useState();
    let [success, setSuccess] = useState(false);
    let [submitting, setSubmitting] = useState(false);

    let [serverError, dispatchServerError] = useServerErrorState();

    console.log(params)

    function passwordsMatch() {
        return password && password === confirmPwd;
    }

    function isValid() {
        return password && password.length >= 5 && passwordsMatch();
    }

    async function submitPwdReset() {
        setSubmitting(true);

        var uri = BASEAPI + "/api/account/ResetPassword";
        var data = {
            userId: userId,
            code: code,
            password: password
        }
        var result = await PostJson(uri, data)
        .catch(function(error){
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error resetting password", errorMessage: error.message});
        })
        .finally(() => setSubmitting(false))

        if (result) {
            setSuccess(true);
        }
    }

    return (
        <>
            <div className="logo-wrapper">
                <img src={logo} className="logo" /> Supplier Portal
            </div>
            <div className="login-wrapper">
                <h3>Reset Password</h3>

                <div className="forgot-pwd-form">
                    <FormGroup>
                        <Label for="password">Password</Label>
                        <Input className="forgot-pwd-field" type="password" name="password" id="password" value={password} onChange={e => setPassword(e.target.value)} invalid={!isValid()} valid={isValid()} />
                        <FormFeedback>
                            Please enter a password, it must be at least 5 characters long.
                        </FormFeedback>
                    </FormGroup>
                    <FormGroup>
                        <Label for="confirmPwd">Confirm Password</Label>
                        <Input className="forgot-pwd-field" type="password" name="confirmPwd" id="confirmPwd" value={confirmPwd} onChange={e => setConfirmPwd(e.target.value)} invalid={!passwordsMatch()} valid={passwordsMatch()} />
                        <FormFeedback>
                            Password and confirmation must match
                        </FormFeedback>
                    </FormGroup>
                    <Button disabled={!isValid() || submitting} onClick={submitPwdReset}>Submit</Button>
                    {submitting && <Spinner color="dark" />}

                    {success &&
                <Alert color="success">
                    Password Successfully changed.  Please <a href="/">Login</a>.
                </Alert>}
                </div>
            </div>
        </>
    )
}