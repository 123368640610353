import React, { useState } from "react"
import { BsDot } from "react-icons/bs";
import { Alert, Button, Col, Container, Input, Label, Row } from "reactstrap"
import { DEFAULT_SELECT } from "../../constants";
import { uuidv4 } from "../Utility/guid";

export default function BatteryRequirementEdit({batteryRequirement, batteryRequirementUpdated, cancelUpdate}) {
    let [batteryRequirementId, setBatteryRequirementId] = useState(batteryRequirement ? batteryRequirement.batteryRequirementId : uuidv4());
    let [batteryType, setBatteryType] = useState(batteryRequirement ? batteryRequirement.batteryType : '');
    let [quantity, setQuantity] = useState(batteryRequirement ? batteryRequirement.quantity : null);
    let [isIncluded, setIsIncluded] = useState(batteryRequirement ? batteryRequirement.isIncluded : false);
    
    function saveBatteryRequirement() {
        var batteryRequirement = {
            batteryRequirementId: batteryRequirementId,
            batteryType: batteryType,
            quantity: quantity,
            isIncluded: isIncluded
        }

        batteryRequirementUpdated(batteryRequirement);
    }
    
    function isValid() {
        return batteryType && quantity && isIncluded !== null;
    }

    return (
        <div className="formGroup">
            <div className="groupTitle">Edit Battery Requirement</div>
            <Alert color="light">Required Fields <BsDot color="red" /></Alert>
            <Container>
                <Row>
                    <Col>
                        <Label>Battery Type <BsDot color="red" /></Label>
                    </Col>
                    <Col>
                        <Input type="select" id="batteryType" value={batteryType} onChange={e => setBatteryType(e.target.value)}>
                            <option value="">{DEFAULT_SELECT}</option>
                            <option value="AA">AA</option>
                            <option value="AAA">AAA</option>
                            <option value="C">C</option>
                            <option value="D">D</option>
                            <option value="9V">9V</option>
                            <option value="Other">Other</option>
                        </Input>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Label>Quantity <BsDot color="red" /></Label>
                    </Col>
                    <Col>
                        <Input type="number" id="quantity" value={quantity} min="0" onChange={e => setQuantity(e.target.value)} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Label>Is Included? <BsDot color="red" /></Label>
                    </Col>
                    <Col>
                        <input type="checkbox" id="isIncluded" value={isIncluded} onChange={e => setIsIncluded(!isIncluded)} />
                    </Col>
                </Row>
                <Row>
                        <Col>
                            <Button disabled={!isValid()} onClick={saveBatteryRequirement}>Save</Button>
                            <Button onClick={() => cancelUpdate()}>Cancel</Button>
                        </Col>
                    </Row>
            </Container>
        </div>
    )
}