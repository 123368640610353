import React, { useEffect, useState } from "react";
import { FaCross, FaPlus, FaTrashAlt } from "react-icons/fa";
import { Alert, Button, Carousel, CarouselControl, CarouselIndicators, CarouselItem, FormText, Input, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { BASEAPI } from "../../constants";
import { useAuthState } from "../../contexts/AuthContext";
import { useServerErrorState } from "../../contexts/ServerErrorContext";
import { PostFormWithToken } from "../../helpers/fetch_helpers";
import { uuidv4 } from "../Utility/guid";
import "./product.css";

export default function ProductImageList({supplierProductImages, supplierId, supplierProductId, editing, imagesUpdated}) {
    let [activeIndex, setActiveIndex] = useState(0);
    let [animating, setAnimating] = useState(false);
    let [showUploadProductImage, setShowUploadProductImage] = useState(false);
    let [file, setFile] = useState();
    let [allImages, setAllImages] = useState();
    let [uploadError, setUploadError] = useState(null);

    let [authState, dispatchAuth] = useAuthState();
    let [serverError, dispatchServerError] = useServerErrorState();

    useEffect(() => {
        setAllImages(supplierProductImages);
    }, [supplierProductImages])

    function next() {
        if (animating) return;
        var nextIndex = activeIndex === supplierProductImages.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    }

    function prev() {
        if (animating) return;
        var nextIndex = activeIndex === 0 ? supplierProductImages.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    }

    function gotoIndex(newIndex) {
        if (animating) return;
        setActiveIndex(newIndex);
    }

    function addImage() {
        setShowUploadProductImage(true);
        setFile(null);
    }

    async function uploadImage() {
        var fileInput = document.querySelector('#supplierProductImageFile');

        var imageId = uuidv4();

        const data = new FormData();
        data.append('uploadedFile', fileInput.files[0]);
        data.append('supplierProductImageId', imageId);
        data.append('supplierProductId', supplierProductId);
        data.append('supplierId', supplierId);

        var uri = BASEAPI + "/api/image/ImportImage";
    
        var result = await PostFormWithToken(uri, data, authState.token)
        .catch(function(error) {
            console.log(error);
            dispatchServerError({type: "SET_ERROR", errorTitle:"Error Uploading Supplier Product Image", errorMessage: error.message});
        })

        if (result && result.result) {

            if (result.hasPoorDimensions) {
                window.alert(result.warningMessage + '. Please contact your Chrisco Buyer to discuss.');
            }

            var newImg = {
                supplierProductImageId: imageId,
                supplierProductId: supplierProductId,
                imageFileName: imageId
            }
            var images = allImages.concat(newImg);
            setAllImages(images);
            imagesUpdated(images);
            setShowUploadProductImage(false);
            setUploadError(null);
        }
        else if (result) {
            setUploadError(result.errorMessage);
            //dispatchServerError({type: "SET_ERROR", errorTitle: "Error Uploading Product Image", errorMessage: });
        }
        
    }

    function removeImage(imageId) {
        if (window.confirm('Are you sure you want to remove current image?')) {
            var index = allImages.findIndex(x => x.supplierProductImageId == imageId);
            if (index > -1) {
                allImages.splice(index, 1);
                setAllImages(allImages);
                imagesUpdated(allImages);
            }
        }
    }

    function cancelUpload() {
        setUploadError(null);
        setShowUploadProductImage(false);
    }

    function supplierProductImageChanged() {
        var fileInput = document.querySelector('#supplierProductImageFile');
        setFile(fileInput.files[0]);
    }

    const images = allImages ? allImages.map((productImage) => {
        var imageUri = BASEAPI + "/api/image/" + productImage.supplierProductImageId + "/?supplierId=" + supplierId

        return (
            <CarouselItem className="carouselItem" key={productImage.supplierProductImageId} onExiting={() => setAnimating(true)} onExited={() => setAnimating(false)}>
                {editing && <Button className="carItemRemoveBtn" size="sm" color="danger" onClick={() => removeImage(productImage.supplierProductImageId)}><FaTrashAlt />Remove</Button>}
                <img className="carouselImg" src={imageUri} />
            </CarouselItem>
        )
    }) : null;

    return (
        <div>
            {editing && <div>
                <Button size="sm" color="primary" onClick={addImage}><FaPlus />Add Product Image</Button>
            </div>}
            {allImages &&
            <div className="productImageContainer">
                <Carousel activeIndex={activeIndex} next={next} previous={prev} interval="10000">
                    <CarouselIndicators items={allImages} activeIndex={activeIndex} onClickHandler={gotoIndex} />
                    {images}
                    <CarouselControl direction="prev" directionText="Previous" onClickHandler={prev} />
                    <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
                </Carousel>
            </div>
            }
           <Modal isOpen={showUploadProductImage} fade={true}>
                <ModalHeader>Upload Product Image</ModalHeader>
                <ModalBody>
                    <Input type="file" id="supplierProductImageFile" onChange={supplierProductImageChanged} />
                    <FormText color="warning">Recommended - Image to be at least 30cm by 30cm and 300dpi.  Image must also be less than 10Mb</FormText>
                    {uploadError && 
                    <Alert color="danger"><h4>Upload Issue</h4>{uploadError}</Alert>}
                </ModalBody>
                <ModalFooter>
                    <Button onClick={uploadImage}>Upload</Button>
                    <Button onClick={cancelUpload}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}