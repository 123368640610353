import {isUndefined} from "lodash";

export function isProductValid(product, isInternational) {
    var isValid = true;
    var errorMessages = [];

    if (isInternational) {
        //validate those required if international fields
        if (!product.deliveryPort) {
            isValid = false;
            errorMessages.push('Missing Delivery Port');
        }

        if (!product.leadTime) {
            isValid = false;
            errorMessages.push('Missing Lead Time');
        }
        
        if (!product.itemCountryId) {
            isValid = false;
            errorMessages.push('Missing Item Country of Origin');
        }

        if (!product.orderingLimits.minOrderQuantity) {
            isValid = false;
            errorMessages.push('Missing Minimum Order Quantity');
        }
    }

    if (!product.productProperties.itemDescription) {
        isValid = false;
        errorMessages.push('Missing Item Name')
    } else if (product.productProperties.itemDescription.length > 37) {
        isValid = false;
        errorMessages.push('Item Name is too long. Please make at most 37 characters')
    }
    
    if (!product.forecastOverride) {
        isValid = false;
        errorMessages.push('Unit Cost is missing')
    }

    if (product.productProperties.itemContainsWood === null || isUndefined(product.productProperties.itemContainsWood)) {
        isValid = false;
        errorMessages.push('Please specify if item contains wood');
    }

    if (product.productProperties.isWeightRated === null || isUndefined(product.productProperties.isWeightRated)) {
        isValid = false;
        errorMessages.push('Please specify if product is weight rated');
    }

    if (product.productProperties.isTippingHazard === null || isUndefined(product.productProperties.isTippingHazard)) {
        isValid = false;
        errorMessages.push('Please specify if product is a tipping hazard');
    }

    if (product.productProperties.hasButtonBatteries === null || isUndefined(product.productProperties.hasButtonBatteries)) {
        isValid = false;
        errorMessages.push('Please specify if product uses button batteries');
    }

    //validate collections
    if (product.cartons && product.cartons.length > 0) {
        product.cartons.forEach(carton => {
            isCartonValid(carton, isInternational);
        });

        var validCartons = product.cartons.every(x => x.isValid === true);
        if (!validCartons) {
            isValid = false;
            errorMessages.push('Cartons contain errors, view cartons to fix issues');
        }
    }

    product.supplierProductTestDates.forEach(testDate => {
        isProductTestValid(testDate);
    });

    var validTestDates = product.supplierProductTestDates.every( x=> x.isValid === true);
    if (!validTestDates) {
        isValid = false;
        errorMessages.push('Product Test Dates contain errors, view product test dates to fix issues');
    }

    product.batteryRequirements.forEach(batteryRequirement => {
        isBatteryRequirementValid(batteryRequirement);
    });

    var validBatteryRequirements = product.batteryRequirements.every(x => x.isValid === true);
    if (!validBatteryRequirements) {
        isValid = false;
        errorMessages.push('Battery Requirements contain errors, view battery requirements to fix issues');
    }

    product.isValid = isValid;
    product.errorMessages = errorMessages;
    
    return isValid;
}

export function isCartonValid(carton, isInternational) {
    var isValid = true;
    var errorMessages = [];

    if (isInternational) {
        //check those required if international

        if (!carton.masterCartonDimensions.height) {
            isValid = false;
            errorMessages.push('Master Carton Height missing');
        }

        if (!carton.masterCartonDimensions.length) {
            isValid = false;
            errorMessages.push('Master Carton Length missing');
        }

        if (!carton.masterCartonDimensions.width) {
            isValid = false;
            errorMessages.push('Master Carton Width missing');
        }

        if (!carton.masterCartonDimensions.weight) {
            isValid = false;
            errorMessages.push('Master Carton Weight missing');
        }

        if (!carton.barcodeDetails.barcodeGTIN14) {
            isValid = false;
            errorMessages.push('GTIN (EAN) Missing')
        }
    }

    //check standard
    if (!carton.retailUnitsPerMasterCarton) {
        isValid = false;
        errorMessages.push('Retail Units Per Carton Missing Value');
    }
    if (!carton.retailUnitDimensions.height) {
        isValid = false;
        errorMessages.push('Retail Unit Height missing');
    }

    if (!carton.retailUnitDimensions.length) {
        isValid = false;
        errorMessages.push('Retail Unit Length missing');
    }

    if (!carton.retailUnitDimensions.width) {
        isValid = false;
        errorMessages.push('Retail Unit Width missing');
    }

    if (!carton.retailUnitDimensions.weight) {
        isValid = false;
        errorMessages.push('Retail Unit Weight missing');
    }
    if (!carton.barcodeDetails.barcodeGTIN13) {
        isValid = false;
        errorMessages.push('GTIN (TUN) Missing')
    }

    carton.isValid = isValid;
    carton.errorMessages = errorMessages;

    return isValid;
}

export function isBatteryRequirementValid(batteryRequirement) {
    var isValid = true;
    var errorMessages = [];


    if (!batteryRequirement.batteryType) {
        isValid = false;
        errorMessages.push('Missing Battery Type');
    }

    if (!batteryRequirement.quantity) {
        isValid = false;
        errorMessages.push('Missing Quantity');
    }

    if (batteryRequirement.isIncluded === null) {
        isValid = false;
        errorMessages.push('Missing if battery is Included');
    }

    batteryRequirement.isValid = isValid;
    batteryRequirement.errorMessages = errorMessages;

    return isValid;
}

export function isProductTestValid(productTest) {
    var isValid = true;
    var errorMessages = [];

    if (!productTest.productTestType) {
        isValid = false;
        errorMessages.push('Missing Product Test Type');
    }

    if (!productTest.dateTested) {
        isValid = false;
        errorMessages.push('Missing Test Date');
    }

    productTest.isValid = isValid;
    productTest.errorMessages = errorMessages;

    return isValid;
}

export function isDomesticFinanceValid(domesticFinance) {
    var isValid = true;
    var errorMessages = [];

    if (!domesticFinance.businessName) {
        isValid = false;
        errorMessages.push('Missing Business Name');
    }

    if (!domesticFinance.abn) {
        isValid = false;
        errorMessages.push('Missing ABN');
    }
    else if (domesticFinance.abn.length < 10) {
        isValid = false;
        errorMessages.push('ABN not long enough.  Should be 10 characters long.')
    }
    
    if (!domesticFinance.phone1) {
        isValid = false;
        errorMessages.push('Missing Phone1 Number');
    }

    if (!domesticFinance.fax) {
        isValid = false;
        errorMessages.push('Missing Fax Number')
    }

    if (!domesticFinance.bankName) {
        isValid = false;
        errorMessages.push('Missing Bank Name');
    }

    if (!domesticFinance.bankAccountName) {
        isValid= false;
        errorMessages.push('Missing Bank Account Name');
    }

    if (!domesticFinance.bankAccountNumber) {
        isValid = false;
        errorMessages.push('Missing Bank Account Numbder');
    }

    if (!domesticFinance.bsbNumber) {
        isValid = false;
        errorMessages.push('Missing BSB Number');
    }
    
    if (domesticFinance.earlyPaymentDiscount == null) {
        isValid = false;
        errorMessages.push('You must specify if there is an early payment discount');
    }

    if (domesticFinance.isPrepaymentRequired == null) {
        isValid = false;
        errorMessages.push('You must specify if prepayment is required');
    }

    if (domesticFinance.earlyPaymentDiscount && !domesticFinance.discountRate) {
        isValid = false;
        errorMessages.push('As early payment discount has been specified, please set a rate for the discount');
    }

    if (!domesticFinance.paymentTermWithoutDiscount) {
        isValid = false;
        errorMessages.push('Please specify the payment term without a discount');
    }

    if (domesticFinance.isPrepaymentRequired && !domesticFinance.paymentLeadTimePriorToDelivery) {
        isValid = false;
        errorMessages.push('As prepayment is required, please specify the payment lead time ahead of delivery');
    }

    if (!domesticFinance.creditLimit || domesticFinance.creditLimit < 0) {
        isValid = false;
        errorMessages.push('Please specify a valid credit limit > 0');
    }

    domesticFinance.errorMessages = errorMessages;

    return isValid;
}