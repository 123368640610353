import React, {createContext, useContext, useReducer } from "react";

let FactsContext = createContext();

export function useFactsState() {
    return useContext(FactsContext);
}

const factsStateReducer = (state, action) => {
    switch (action.type) {
        case "INIT_STATES":
            return {...state, states: action.states};
        case "INIT_COUNTRIES":
            return {...state, countries: action.countries}
        default:
            return state;
    }
}

const initialState = {
    states: [],
    countries: []
};

export function FactsStateProvider({children}) {
    let factsState = useReducer(factsStateReducer, initialState);

    return <FactsContext.Provider value={factsState}>{children}</FactsContext.Provider>
}