import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Spinner } from "reactstrap";
import { BASEAPI } from "../../constants";
import { useAuthState } from "../../contexts/AuthContext";
import { useServerErrorState } from "../../contexts/ServerErrorContext";
import { GetJsonWithToken, PostJsonWithToken } from "../../helpers/fetch_helpers";
import DomesticFinanceDetails from "./Finance/DomesticFinanceDetails";
import InternationalFinanceDetails from "./Finance/InternationFinanceDetails";

export default function FinancialDetails() {
    let { requestId } = useParams();
    let [financeModel, setFinanceModel] = useState();
    let [loading, setLoading] = useState(false);
    let history = useHistory();

    let [authState, dispatchAuth] = useAuthState();
    let [serverErrorState, dispatchServerError] = useServerErrorState();

    let [isInternational, setIsInternational] = useState(null);

    async function getFinanceModel() {
        const uri = BASEAPI + "/api/financeDetails/" + (isInternational ? "GetInternationalFinanceDetails/" : "GetDomesticFinanceDetails/") + requestId;
        var result = await GetJsonWithToken(uri, null, authState.token)
        .catch(function(error){
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error retrieving finance model", errorMessage: error.message});
        })
        .finally(() => setLoading(false));

        setFinanceModel(result);        
    }

    async function getIsInternational() {
        const uri = BASEAPI + "/api/financeDetails/isInternationalSupplier/" + requestId;
        var result = await GetJsonWithToken(uri, null, authState.token)
        .catch(function(error){
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error retrieving is international", errorMessage: error.message});
        })

        setIsInternational(result);
    }

    useEffect(() => {
        if (isInternational !== null)  {
            setLoading(true);
            getFinanceModel();
        }
    }, [authState.user, requestId, isInternational])

    useEffect(() => {
        getIsInternational();
    }, [authState.user, requestId])

    async function financeUpdated(financeModel) {
        var requestData = {
            financeDetailsModel: financeModel,
            supplierRequestId: requestId
        }

        const uri = BASEAPI + "/api/supplierrequest/" + (financeModel.financeDetails.isInternational ? "SubmitInternationalFinanceDetail" : "SubmitFinanceDetail");
        var result = await PostJsonWithToken(uri, requestData, authState.token)
        .catch(function(error) {
            console.log(error);
            dispatchServerError({type: "SET_ERROR", errorTitle: "Error Submitting Finance Details to server", errorMessage: error.message});
        })

        if (result && result.result) {
            history.push("/");
        } else {
            if (result && !result.result) {
                console.log(result.errorMessage);
                dispatchServerError({type: "SET_ERROR", errorTitle: "Error Submitting Finance Details to server", errorMessage: result.errorMessage})
            }
        }
    }

    function getFinanceView() {
        if (financeModel) {
            return financeModel.financeDetails.isInternational ? <InternationalFinanceDetails financeModel={financeModel} financeUpdated={financeUpdated} /> : <DomesticFinanceDetails financeModel={financeModel} financeUpdated={financeUpdated} />
        }

        return <></>
    }

    return (
        <div>
            {loading ? <Spinner /> : getFinanceView()}
        </div>
    )
}