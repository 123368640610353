import React, { useEffect, useState } from "react"
import { FaPray } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import SelectSearch from "react-select-search";
import { Button, FormGroup, Input, Label, Spinner } from "reactstrap";
import { BASEAPI, DEFAULT_SELECT } from "../../constants";
import { useAuthState } from "../../contexts/AuthContext";
import { useServerErrorState } from "../../contexts/ServerErrorContext";
import { BusinessUnitEnum, BusinessUnitEnumLabel } from "../../helpers/enums";
import { GetJsonWithToken, PostJsonWithToken } from "../../helpers/fetch_helpers";
import "./SelectSupplier.css";

export default function SelectSupplier() {
    let history = useHistory();
    let [suppliers, setSuppliers] = useState();
    let [terms, setTerms] = useState();
    let [loading, setLoading] = useState(false);
    let [termId, setTermId] = useState();
    let [businessUnitId, setBusinessUnitId] = useState();
    let [supplier, setSupplier] = useState();

    let [authState, dispatchAuthState] = useAuthState();
    let [serverErrorState, dispatchServerError] = useServerErrorState();    

    function buildSupplierOptions() {
        var options = suppliers && suppliers.length > 0 ? suppliers.map((supplier, index) => {
            return { name: supplier.supplierName, value: supplier.supplierId}
        }) : [];

        return options;
    }

    function buildTermOptions() {
        var options = [];
        options.push(<option key={-1} value="-1">{DEFAULT_SELECT}</option>)

        terms && terms.filter(x => !x.legacyTermId || x.legacyTermId > 633933).map((term, index) => {
            var opt = <option key={index} value={term.termId}>{term.termName}</option>
            options.push(opt);
        })

        return options;
    }

    function buildBusinessUnitOptions() {
        var options = [];
        options.push(<option key={-1} value="-1">{DEFAULT_SELECT}</option>)

        var entries = Object.entries(BusinessUnitEnum);

        for (var i = 0; i < entries.length; i++)
        {
            var key = parseInt(entries[i][1]);

            var opt = <option key={key} value={key}>{BusinessUnitEnumLabel(key)}</option>;

            options.push(opt);
        }

        return options;
    }

    async function getSuppliers() {
        setLoading(true);

        var uri = BASEAPI + "/api/supplier/";
        var data = {
            termId: termId,
            businessUnitId: businessUnitId
        }

        var result = await PostJsonWithToken(uri, data, authState.token)
        .catch(function(error){
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error getting suppliers", errorMessage: error.message});
        })
        .finally(() => setLoading(false));

        setSuppliers(result);
    }

    async function getTerms() {
        setLoading(true);

        var uri = BASEAPI + "/api/term/";
        var result = await GetJsonWithToken(uri, null, authState.token)
        .catch(function(error){
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error getting terms", errorMessage: error.message});
        })
        .finally(() => setLoading(false));

        setTerms(result);
    }

    async function getSupplier() {
        var uri = BASEAPI + "/api/supplier/" + authState.supplierId;

        var result = await GetJsonWithToken(uri, null, authState.token)
        .catch(function(error) {
            console.log(error);
            dispatchServerError({type: "SET_ERROR", errorTitle: "Error getting supplier", errorMessage: error.message});
        })
        
        setSupplier(result);
    }

    function setSupplierId(e) {
        dispatchAuthState({type: "SET_SUPPLIER", supplierId: e});
        if (e !== authState.supplierId)
            history.push("/");
    }

    useEffect(() => {
        getTerms();
    }, [])

    useEffect(() => {
        if (termId && businessUnitId) {
            getSuppliers();
        }
    }, [termId, businessUnitId])

    useEffect(() => {
        if (authState.supplierId) {
            getSupplier();
        }
    }, [authState.supplierId])

    return (
        <div className="supplierSelectWrapper">
            <h3>Select Supplier</h3>
            As you are staff, please select term and then which supplier you wish to act on behalf of.

            {supplier && <div className="currentSupplier">
                Current Supplier: {supplier.supplierName}
            </div>}

            {loading ? <Spinner /> : 
            <>
                {terms && 
                <>
                    <FormGroup>
                        <Label for="term">Term:</Label>
                        <Input type="select" name="term" id="term" placeholder="Choose a Term" value={termId} onChange={e => setTermId(e.target.value)}>
                        {buildTermOptions()}
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label for="businessUnit">Business Unit:</Label>
                        <Input type="select" name="businessUnit" id="businessUnit" placeholder="Choose Business Unit" value={businessUnitId} onChange={e => setBusinessUnitId(e.target.value)}>
                        {buildBusinessUnitOptions()}
                    </Input>
                    </FormGroup>
                </>
                }
                {suppliers && 
                    <FormGroup>
                        <Label for="supplier">Supplier:</Label>
                        <SelectSearch options={buildSupplierOptions()} name="supplier" placeholder="Choose a Supplier" debounce="100" search={true} onChange={e => setSupplierId(e)} />
                    </FormGroup>
                }
            </>
            }
        </div>
    )
}